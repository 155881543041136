import './style_offers_dashboard.css';
import SidebarDashboard from '../Side Bar Dashboard/SidebarDashboard';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { addDoc, collection, deleteDoc, doc, getDocs, serverTimestamp, updateDoc } from 'firebase/firestore';
import { db, storage } from '../../../Firebase/firebase';
import { deleteObject, getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import baly_img from '../../images/baly_island.png';
import Loading from '../../Loading/Loading';


function OffersDashboard({onSignOutForAdmin}) {
    const navigate = useNavigate();

     // To Loading Page
     const [isLoading, setIsLoading] = useState(true);

     // To Add Data
     const [myData, setMyData] = useState([]);
    //console.log(myData);

     const [title, setTitle] = useState('');
     const [content, setContent] = useState('');
     const [rate, setRate] = useState('5');
     const [myLink, setMyLink] = useState('');
     const [discountCode, setDiscountCode] = useState('');

     const [isImgUpload, setIsImgUpload] = useState(false);
     const [isDataLoading, setIsDataLoading] = useState(false);

     // To Upload Img
    const [imageFile, setImageFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    //console.log(imageFile);
  


// Function to handle both image and text upload together

const handelAddData = async () => {
    if (!title || !content || !rate || !myLink || !imageFile) {
      alert('Please fill all fields and select an image');
      return;
    }
  
    setUploading(true); // Set uploading state to true
    try {
      // رفع الصورة
      const storageRef = ref(storage, `offersImage/${imageFile.name}_${Date.now()}`);
      await uploadBytes(storageRef, imageFile);
      const imageUrl = await getDownloadURL(storageRef);
  
      // بعد رفع الصورة، رفع البيانات النصية مع رابط الصورة إلى Firestore
      const addData = await addDoc(collection(db, 'myOffers'), {
        title,
        content,
        rate,
        myLink,
        discountCode,
        imageUrl,  // إضافة رابط الصورة مع البيانات النصية
        TimeStamp: serverTimestamp(),
      });
  
      // تحديث الـ state المحلي للعرض
      setMyData([...myData, { id: addData.id, title: title, content: content, rate: rate, myLink: myLink, discountCode: discountCode, imageUrl: imageUrl }]);
  
      // تنظيف الحقول بعد الرفع
      setTitle('');
      setContent('');
      setRate('');
      setMyLink('');
      setDiscountCode('');
      setImageFile(null);
      
      alert('Data Submitted Successfully');
      window.location.reload(); // إذا كنت بحاجة لإعادة تحميل الصفحة
  
    } catch (error) {
      console.log(error);
      alert('Error uploading data: ' + error.message);
    } finally {
      setUploading(false); // Set uploading state to false
    }
  };

  // Handle image file selection
    const handleImageChange = (e) => {
        setImageFile(e.target.files[0]);  // Store the selected image file in state
        setIsImgUpload(true);
    };
  






    // To get data
    useEffect(()=> {

        const fetchData = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'myOffers'));
                const offers = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                // ترتيب البيانات من الأقدم إلى الأحدث بناءً على Timestamp
                offers.sort((a, b) => {
                    return b.TimeStamp.toDate() - a.TimeStamp.toDate();
                });

                setMyData(offers); // تخزين البيانات المستدعاة في state
                setIsLoading(false);
        
            } catch (error) {
                console.error('Error fetching data: ', error);
            }
        };

        fetchData();

    }, []);

    






    // To Edit Data
    const [editData, setEditData] = useState(null);
    console.log(editData);

    const handelEdit = async()=> {
        try {

            if(isImgUpload) {
                // 1. Delete Old image from storage 
                const storageRefToDelete = ref(storage, editData.imageUrl);
                await deleteObject(storageRefToDelete);

                // 2. Upload New Image
                const storageRef = ref(storage, `offersImage/${imageFile.name}_${Date.now()}`);
                await uploadBytes(storageRef, imageFile);
                const newImageUrl = await getDownloadURL(storageRef);

                await updateDoc(doc(db, 'myOffers', editData.id), {  // id from ==> setEditData
                    title,
                    content,
                    rate,
                    myLink,
                    discountCode,
                    imageUrl: newImageUrl,
                });

            } else {

                await updateDoc(doc(db, 'myOffers', editData.id), {  // id from ==> setEditData
                    title,
                    content,
                    rate,
                    myLink,
                    discountCode,
                    imageUrl: imageFile,
                });
            };
            

            // Reset All State
            setEditData(null);
            setTitle('');
            setContent('');
            setRate('');
            setMyLink('');
            setDiscountCode('');
            setImageFile(null);

            alert('Content Is Updated Successfuley');
            window.location.reload();

        } catch(error) {
            alert(error);
        }
    };






    // To Delete Data from Firestore and Storage
    const handelDelete = async (dataId, imageUrl) => {
        try {
        const confirmMessage = window.confirm('You Will Delete This Data, Are You Sure?');
    
        if (confirmMessage) {
            // 1. حذف الصورة من Storage
            const storageRef = ref(storage, imageUrl);
            await deleteObject(storageRef);
    
            // 2. حذف البيانات من Firestore
            await deleteDoc(doc(db, 'myOffers', dataId));
    
            // 3. تحديث الحالة المحلية بعد الحذف
            setMyData(myData.filter((e) => e.id !== dataId)); // Return all data except the deleted one
    
            //alert('Data deleted successfully');
        }
        } catch (error) {
        console.log(error);
        alert('Error deleting data: ' + error.message);
        }
    };
  



    // To Scroll To Top:-
    const handelScrollToTop = ()=> {
        
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };



    // To LogOut
    const handelLogOut = ()=> {
        onSignOutForAdmin();
        navigate('/My-Admin-Dashboard');
    };



    // To Rate In Data (Show Stars)
    const dataRate = (rate)=> {
        if(rate === '5') {
            return(
                <>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                </>
            );

        } else if(rate === '4') {
            return(
                <>
                    <i className="fa-solid fa-star no-rate"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                </>
            );

        } else if(rate === '3') {
            return(
                <>
                    <i className="fa-solid fa-star no-rate"></i>
                    <i className="fa-solid fa-star no-rate"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                </>
            );

        } else if(rate === '2') {
            return(
                <>
                    <i className="fa-solid fa-star no-rate"></i>
                    <i className="fa-solid fa-star no-rate"></i>
                    <i className="fa-solid fa-star no-rate"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                </>
            );

        } else if(rate === '1') {
            return(
                <>
                    <i className="fa-solid fa-star no-rate"></i>
                    <i className="fa-solid fa-star no-rate"></i>
                    <i className="fa-solid fa-star no-rate"></i>
                    <i className="fa-solid fa-star no-rate"></i>
                    <i className="fa-solid fa-star"></i>
                </>
            );

        }
    };


    // To Sure That All Data Is Fill Seccessfuly:-
    const handelFillAllData = ()=> {
        if (!title || !content || !rate || !myLink || !imageFile) {
            //alert('Please fill all fields and select an image');
            console.log('Please fill all fields and select an image');
            return;
          }

        setIsDataLoading(true);
    };


    // Content
    const offersDashboardContent = ()=> {
        return(
            <>
                <div className="add">
                    <div className="add-title">
                        <label htmlFor="">العنوان</label>
                        <input
                            type="text"
                            value={title}
                            placeholder="اكتب العنوان المناسب"
                            onChange={(e) => {
                            setTitle(e.target.value);
                            }}
                        />
                        </div>

                        <div className="add-title">
                        <label htmlFor="">المحتوي</label>
                        <textarea
                            value={content}
                            placeholder="اكتب المحتوي المناسب"
                            onChange={(e) => {
                            setContent(e.target.value);
                            }}
                        ></textarea>
                        </div>

                        <div className="rate-and-image">
                            <div className="my-left">

                                {isImgUpload ? <i className="fa-solid fa-circle-check" style={{color: '#33A850'}}></i> : <i className="fa-regular fa-image"></i>}

                                <input
                                id="myFile"
                                type="file"
                                onChange={handleImageChange}
                                required
                                />
                            </div>

                            <div className="my-right">
                                <label htmlFor="">التقيم</label>
                                <select value={rate} onChange={(e) => setRate(e.target.value)}>
                                <option value="5">5</option>
                                <option value="4">4</option>
                                <option value="3">3</option>
                                <option value="2">2</option>
                                <option value="1">1</option>
                                </select>

                                <label htmlFor="">إضافة رابط</label>
                                <input
                                type="text"
                                value={myLink}
                                placeholder="قم بإضافة الرابط الخاص بالعرض"
                                onChange={(e) => setMyLink(e.target.value)}
                                required
                                />

                                <label htmlFor="">إضافة كود الخصم</label>
                                <input type="text" placeholder='قم بإضافة كود الخصم' value={discountCode ? discountCode : ''} onChange={(e)=> {setDiscountCode(e.target.value)}}/>
                            </div>
                        </div>

                    <button onClick={()=> {
                        handelFillAllData();
                        editData ? handelEdit() : handelAddData();
                    }}>
                        {editData ? 'تعديل العرض' : 'إضافة العرض'}
                    </button>
                    {isDataLoading && <p>... جاري التحميل</p>}

                    </div>

                <div className='head-content'>
                    <span></span>
                    <h2>محتوي الصفحة</h2>
                    <span></span>
                </div>

                <div className='content'>
                    <div className='my-cards'>

                            {
                                myData.map((e)=> {
                                    return(
                                        <div className='card' key={e.id}>
                                            <img src={e.imageUrl} />

                                            <h2>{e.title}</h2>
                                            <p>{e.content}</p>

                                            <div className='rate'>
                                                {dataRate(e.rate)}

                                                <span>التقيم</span>
                                            </div>

                                            <button className='edit' onClick={()=> {
                                                handelScrollToTop();

                                                setEditData(e); // to get id and all content
                                                setTitle(e.title);
                                                setContent(e.content);
                                                setRate(e.rate);
                                                setMyLink(e.myLink);
                                                setDiscountCode(e.discountCode);
                                                setImageFile(e.imageUrl);
                                            }}>تعديل</button>

                                            <button className='delete' onClick={()=> {handelDelete(e.id, e.imageUrl)}}>حذف</button>
                                        </div>
                                    )
                                })
                            }
                            
                        </div>
                </div>
            </>
        );
    };

    if(isLoading) {
        return(
            <Loading />
        );

    } else {

        return(
            <>
                <div className='my-offers-dashboard'>
                    <div className='container'>
                        <SidebarDashboard titleName='العروض' content={offersDashboardContent()} toLogOut={handelLogOut}/>
                    </div>
                </div>
            </>
        );
    }
};


export default OffersDashboard;