import './style_loading.css';

function Loading() {
    return(
        <>
            <div className="my-loading">
                <div className="content">
                    <h1>Loading...</h1>
                    <span className="loader"></span>
                </div>
            </div>
        </>
    );
};


export default Loading;