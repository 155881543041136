import { Link } from 'react-router-dom';
import Navbar from '../../Navbar/Navbar';
import './style_personal_information.css';
import { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../../Firebase/firebase';
import Footer from '../../Footer/Footer';

function PersonalInformation() {
    const [isLogin, setIsLogin] = useState(false);

    const [anotherBooking, setAnotherBooking] = useState(false);

    useEffect(()=> {

        const unseubscribe = onAuthStateChanged(auth, (user)=> {
            if(user) {
                setIsLogin(true);

            } else {
                setIsLogin(false);
            }
        });

        return ()=> unseubscribe();

    }, []);

    const handelcheckbox = (e)=> {
        if(e.target.checked === true) {
            setAnotherBooking(true);

        } else {
            setAnotherBooking(false);
        }
    };

    return(
        <>
            <div className='my-personal-information'>
                <div className='container'>
                    <Navbar />

                    <div className='head'>
                        <p>الخطوة الاخيرة</p>
                        <p className='circle'>3</p>
                        <hr />

                        <p>بياناتك</p>
                        <p className='circle'>2</p>
                        <hr />

                        <p>اختيار حجزك</p>
                        {/* <p className='circle'>1</p> */}
                        <i className="fa-solid fa-circle-check"></i>
                    </div>

                    <div className='content'>
                        <div className='left'>
                            

                                {
                                    isLogin ? (
                                        <div className='my-head' style={{backgroundColor: '#33A850'}}>
                                            <h2>
                                            خطوات بسيطة وتبدأ رحلتك
                                            <i className="fa-solid fa-circle-exclamation"></i>
                                            </h2>
                                        </div>
                                    ) : (
                                        <div className='my-head'>
                                            <h2>
                                            <Link to='/login'>بتسجيل الدخول</Link>
                                            لإستعراض حجوزاتك بشكل اسرع 
                                            قم 
                                            <i className="fa-solid fa-circle-exclamation"></i>
                                            </h2>
                                        </div>
                                    )
                                }

                                
                            
                            
                            <form className='form-for-hotel'>
                                <div className='my-body'>
                                        {/* hide-in-small Screen */}
                                        <div className='my-left'>
                                            <label htmlFor="">الاسم الاخير</label>
                                            <input type="text" placeholder='الاسم الاخير' />
                                        </div>

                                        <div className='my-right'>
                                            <div className='part'>
                                                <label htmlFor="">الاسم الاول</label>
                                                <input type="text" placeholder='الاسم الاول' />
                                            </div>

                                            {/* hide-in-large Screen */}
                                            <div className='part hide-in-large'>
                                                <label htmlFor="">الاسم الاخير</label>
                                                <input type="text" placeholder='الاسم الاخير' />
                                            </div>

                                            <div className='part'>
                                                <label htmlFor="">البريد الإلكتروني</label>
                                                <input type="text" placeholder='البريد الإلكتروني' />
                                                <p>يتم إرسال رسالة تأكيد الحجز الإلكترونية إلى هذا العنوان</p>
                                            </div>

                                            <div className='part'>
                                                <label htmlFor="">رقم الجوال</label>
                                                <input type="text" placeholder='رقم الجوال' />
                                                <p>ليتمكن مكان الإقامة من التواصل معك</p>
                                            </div>

                                            <hr />

                                            <div className='check'>
                                                <div className='main'>
                                                    <label htmlFor="">الحجز لشخص اخر</label>
                                                    <input type='checkbox' onClick={handelcheckbox}/>
                                                </div>

                                                {
                                                    anotherBooking ? (
                                                        <div className='more-info'>
                                                            <input type="text" placeholder='الاسم الاول للضيف'/>
                                                            <input type="text" placeholder='الاسم الاخير للضيف'/>
                                                        </div>
                                                    ) : (null)
                                                }
                                            </div>

                                            <hr />

                                            
                                        </div>
                                </div>

                                

                                <div className='special-order'>
                                    <h3>طلبات خاصه</h3>
                                    <p>
                                    لا يمكن ضمان تلبية الطلبات الخاصة، ولكن سيبذل مكان الإقامة قصارى جهده لتلبية احتياجاتك. يمكنك دائماً تقديم طلب خاص بعد إكمال الحجز الخاص بك
                                    </p>
                                    <textarea placeholder='الرجاء كتابة طلباتك باللغة الانجليزية أو العربية. (اختياري)'></textarea>
                                </div>

                                <div className='perfer'>
                                    <label htmlFor="">أُفضل أن تكون الغرف قريبة من بعضها البعض (إن أمكن)</label>
                                    <input type='checkbox' />
                                </div>

                                <div className='policy'>
                                    <div className='this-left'>
                                        <p>
                                            تعتمد الطلبات على التوافر  
                                            <i className="fa-solid fa-circle"></i> 
                                        </p>

                                        <p>
                                            يجب تأكيد الطلبات من قِبل مكان الإقامة
                                            <i className="fa-solid fa-circle"></i> 
                                        </p>

                                        <p>
                                            قد تحتاج الطلبات التي لا يشار إليها بـ "مجاني" إلى دفع رسوم إضافية  
                                            <i className="fa-solid fa-circle"></i> 
                                        </p>
                                    </div>

                                    <div className='this-right'>
                                        <i className="fa-solid fa-circle-exclamation"></i>
                                    </div>
                                </div>

                                <div className='last-step'>
                                    
                                    <button type='submit'>
                                        <i className="fa-solid fa-angles-left"></i>
                                        التالي : التفاصيل الاخيرة
                                    </button>
                                </div>

                            </form>
                        </div>

                        <div className='right'>
                            <h2>
                                تفاصيل حجزك
                                <i className="fa-solid fa-list-check"></i>
                            </h2>

                            <h5>
                                فندق: هيلتون رمسيس القاهرة
                                <i className="fa-solid fa-circle"></i>
                            </h5>

                            <h5>
                                  تسجيل الوصول: الاحد 1 سبتمبر 2024   
                                <i className="fa-solid fa-circle"></i>
                            </h5>

                            <h5>
                                تسجيل المغادرة: الاربعاء 4 سبتمبر 2024  
                                <i className="fa-solid fa-circle"></i>
                            </h5>

                            <h5>
                                 مدة الإقامة : 3 ليالي
                                <i className="fa-solid fa-circle"></i>
                            </h5>

                            <h5>
                                 عدد الضيوف : 4
                                <i className="fa-solid fa-circle"></i>
                            </h5>

                            <h5>
                                لقد اخترت  
                                <i className="fa-solid fa-circle"></i>
                            </h5>

                            <div className='my-choose'>
                                <p>1 سويت</p> 
                                <p>1 سويت</p> 
                                <p>غرفة مزدوجة 1</p>
                            </div>

                            <hr />

                            <h5>
                                ملخص السعر الخاص بك 
                                <i className="fa-solid fa-circle"></i>
                            </h5>
                            
                            <div className='price'>
                                <h2>السعر: 580 ريال</h2>
                            </div>
                            
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </>
    );
};


export default PersonalInformation;