import './style_home.css';
import Navbar from "../Navbar/Navbar";
import HeroSection from '../Hero Section/HeroSection';
import { useState } from 'react';
import MoreHotel from './Expolore Moer Hotel/MoreHotel';
import ExtraServices from './Extra Services/ExtraServices';
import Destenations from './Destenations/Destenations';
import Help from './Help/Help';
import Footer from '../Footer/Footer';
import ProfilePage from '../ProfilePage/ProfilePage';

function Home() {
    const [isArabicLange, setIsArabicLang] = useState(true);

    return(
        <>
            <div className="my-home">
                <div className="nav">
                    <Navbar isArabicLange={isArabicLange} setIsArabicLang={setIsArabicLang}/>
                </div>

                <div className='hero'>
                    <HeroSection isArabicLange={isArabicLange} />
                </div>

                <div className='more-hotel'>
                    <MoreHotel isArabicLange={isArabicLange} />
                </div>

                {/* <div className='extra-services'>
                    <ExtraServices />
                </div> */}

                <div className='destenations'>
                    <Destenations />
                </div>

                <div className='help'>
                    <Help />
                </div>

                <div className='footer'>
                    <Footer />
                </div>
            </div>
        </>
    );
};


export default Home;