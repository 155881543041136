import './style_information_for_flight.css';
import Navbar from '../../Navbar/Navbar';
import { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../../Firebase/firebase';
import { Link } from 'react-router-dom';
import { getNames } from 'country-list';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Footer from '../../Footer/Footer';
import Select from 'react-select';




function InformationForFlight() {
    const [isLogin, setIsLogin] = useState(false);

    useEffect(()=> {

        const unSubscribe = onAuthStateChanged(auth, (user)=> {
            if(user) {
                setIsLogin(true);

            } else {
                setIsLogin(false);
            };
        });

        return ()=> unSubscribe();

    }, []);

    

    
    // جلب قائمة الجنسيات (الدول)
    // const countries = getNames().map(country => ({
    //     label: country,
    //     value: country
    // }));


    // Country In Arabic:-
    const countriesInArabic = [
        // الدول العربية
        { value: 'saudi-arabia', label: 'السعودية' },
        { value: 'egypt', label: 'مصر' },
        { value: 'uae', label: 'الإمارات' },
        { value: 'qatar', label: 'قطر' },
        { value: 'kuwait', label: 'الكويت' },
        { value: 'bahrain', label: 'البحرين' },
        { value: 'oman', label: 'عمان' },
        { value: 'jordan', label: 'الأردن' },
        { value: 'morocco', label: 'المغرب' },
        { value: 'algeria', label: 'الجزائر' },
        { value: 'tunisia', label: 'تونس' },
        { value: 'lebanon', label: 'لبنان' },
        { value: 'sudan', label: 'السودان' },
        { value: 'libya', label: 'ليبيا' },
        { value: 'iraq', label: 'العراق' },
        { value: 'syria', label: 'سوريا' },
        { value: 'palestine', label: 'فلسطين' },
        { value: 'yemen', label: 'اليمن' },
        { value: 'mauritania', label: 'موريتانيا' },
        { value: 'somalia', label: 'الصومال' },
      
        // الدول الأجنبية
        { value: 'usa', label: 'الولايات المتحدة' },
        { value: 'canada', label: 'كندا' },
        { value: 'united-kingdom', label: 'المملكة المتحدة' },
        { value: 'germany', label: 'ألمانيا' },
        { value: 'france', label: 'فرنسا' },
        { value: 'italy', label: 'إيطاليا' },
        { value: 'spain', label: 'إسبانيا' },
        { value: 'netherlands', label: 'هولندا' },
        { value: 'belgium', label: 'بلجيكا' },
        { value: 'sweden', label: 'السويد' },
        { value: 'norway', label: 'النرويج' },
        { value: 'denmark', label: 'الدنمارك' },
        { value: 'finland', label: 'فنلندا' },
        { value: 'switzerland', label: 'سويسرا' },
        { value: 'austria', label: 'النمسا' },
        { value: 'greece', label: 'اليونان' },
        { value: 'turkey', label: 'تركيا' },
        { value: 'china', label: 'الصين' },
        { value: 'japan', label: 'اليابان' },
        { value: 'south-korea', label: 'كوريا الجنوبية' },
        { value: 'australia', label: 'أستراليا' },
        { value: 'new-zealand', label: 'نيوزيلندا' },
        { value: 'india', label: 'الهند' },
        { value: 'brazil', label: 'البرازيل' },
        { value: 'argentina', label: 'الأرجنتين' },
        { value: 'mexico', label: 'المكسيك' },
        { value: 'south-africa', label: 'جنوب أفريقيا' },
        { value: 'russia', label: 'روسيا' }
      ];
      



    const [selectedCountry, setSelectedCountry] = useState(null);
    const [options, setOptions] = useState([]);

    const handleChange = (selectedOption) => {
        setSelectedCountry(selectedOption);
    };

    // Birthday
    const [selectedDate, setSelectedDate] = useState(null);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    //const [selectedDay, setSelectedDay] = useState(null);

    // For Mobile
    const [phone, setPhone] = useState('');
    //console.log(phone);



    // For Submit My Form
    const handelSubmit = (e)=> {
        e.preventDefault();

        if(!phone) {
            alert('من فضلك ادخل رقم هاتفك');
        };
    };

    return(
        <>
            <div className='information-for-flight'>
                <div className='container'>
                    <Navbar />

                    <div className='first'>
                        <div className='tripe-info'>
                            <h4>23: 05 - 08:25</h4>
                            <span></span>
                            <h4>الجمعة 2024/9/9</h4>
                            <span></span>
                            <h4>القاهرة - دبي</h4>
                            <h4 className='padge'>الذهاب</h4>
                        </div>

                        <div className='tripe-info'>
                            <h4>23: 05 - 08:25</h4>
                            <span></span>
                            <h4>الاحد 2024/9/12</h4>
                            <span></span>
                            <h4>دبي - القاهرة</h4>
                            <h4 className='padge'>العودة</h4>
                        </div>
                    </div>

                    <div className='pakage-in-booking'>
                        <h2>
                            الامتعة المشمولة بالحجز
                            <i className="fa-solid fa-briefcase"></i>
                        </h2>

                        <div className='all-pakage'>
                            <div className='pakage'>
                                <div className='content'>
                                    <div className='head'>
                                        <h4>دبي - القاهرة</h4>
                                        <h4 className='padge'>العودة</h4>
                                    </div>

                                    <div className='your-pakage'>
                                        <div className='left'>
                                            <h4>الغرض الشخصي</h4>
                                            <p className='red'>لا توجد امتعة مجانية مسموح بها</p>
                                        </div>

                                        <div className='right'>
                                            <i className="fa-solid fa-briefcase"></i>
                                        </div>
                                    </div>

                                    <div className='your-pakage'>
                                        <div className='left'>
                                            <h4>الامتعة المحمولة</h4>
                                            
                                            <div className='my-span'>
                                                <p>تتضمن</p>
                                                <p> :</p>
                                                <span> 1 قطعة</span>
                                                <span>  *  </span>
                                                <span> 7 كجم / الشخص</span>
                                            </div>
                                        </div>

                                        <div className='right'>
                                            <i className="fa-solid fa-person-walking-luggage"></i>
                                        </div>
                                    </div>

                                    <div className='your-pakage'>
                                        <div className='left'>
                                            <h4>الامتعة المفحوصة</h4>
                                            
                                            <div className='my-span'>
                                                <p>تتضمن</p>
                                                <p> :</p>
                                                <span> 1 قطعة</span>
                                                <span>  *  </span>
                                                <span> 20 كجم / الشخص</span>
                                            </div>
                                        </div>

                                        <div className='right'>
                                            <i className="fa-solid fa-cart-flatbed-suitcase"></i>
                                        </div>
                                    </div>

                                    <div className='add-pakage'>
                                        <button>
                                            اضف امتعة سفر
                                            <i className="fa-solid fa-circle-plus"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <span className='center'></span>

                            <div className='pakage'>
                                <div className='content'>
                                    <div className='head'>
                                        <h4>القاهرة - دبي</h4>
                                        <h4 className='padge'>الذهاب</h4>
                                    </div>

                                    <div className='your-pakage'>
                                        <div className='left'>
                                            <h4>الغرض الشخصي</h4>
                                            <p className='red'>لا توجد امتعة مجانية مسموح بها</p>
                                        </div>

                                        <div className='right'>
                                            <i className="fa-solid fa-briefcase"></i>
                                        </div>
                                    </div>

                                    <div className='your-pakage'>
                                        <div className='left'>
                                            <h4>الامتعة المحمولة</h4>
                                            
                                            <div className='my-span'>
                                                <p>تتضمن</p>
                                                <p> :</p>
                                                <span> 1 قطعة</span>
                                                <span>  *  </span>
                                                <span> 7 كجم / الشخص</span>
                                            </div>
                                        </div>

                                        <div className='right'>
                                            <i className="fa-solid fa-person-walking-luggage"></i>
                                        </div>
                                    </div>

                                    <div className='your-pakage'>
                                        <div className='left'>
                                            <h4>الامتعة المفحوصة</h4>
                                            
                                            <div className='my-span'>
                                                <p>تتضمن</p>
                                                <p> :</p>
                                                <span> 1 قطعة</span>
                                                <span>  *  </span>
                                                <span> 20 كجم / الشخص</span>
                                            </div>
                                        </div>

                                        <div className='right'>
                                            <i className="fa-solid fa-cart-flatbed-suitcase"></i>
                                        </div>
                                    </div>

                                    <div className='add-pakage'>
                                        <button>
                                            اضف امتعة سفر
                                            <i className="fa-solid fa-circle-plus"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                    </div>

                    <div className='content'>

                        <div className='info-flight'>
                                

                                {
                                    isLogin ? (
                                        <div className='my-head' style={{backgroundColor: '#33A850'}}>
                                            <h2>
                                            خطوات بسيطة وتبدأ رحلتك
                                            <i className="fa-solid fa-circle-exclamation"></i>
                                            </h2>
                                        </div>
                                    ) : (
                                        <div className='my-head'>
                                            <h2>
                                            <Link to='/login'>بتسجيل الدخول</Link>
                                            لإستعراض حجوزاتك بشكل اسرع 
                                            قم 
                                            <i className="fa-solid fa-circle-exclamation"></i>
                                            </h2>
                                        </div>
                                    )
                                }

                                
                            
                            
                            <form className='form-for-flight' onSubmit={handelSubmit}>
                                {/* Passenger Information */}
                                <h2>
                                    بيانات الركاب   
                                    <i className="fa-solid fa-person-walking-luggage"></i>
                                </h2>

                                <div className='scane'>
                                    <button>
                                        مسح جواز السفر
                                        <i className="fa-solid fa-camera"></i>
                                    </button>
                                </div>

                                <div className='my-body'>
                                        {/* hide-in-small Screen */}
                                        <div className='my-left'>
                                            <label htmlFor="">الاسم الاخير</label>
                                            <input type="text" placeholder='الاسم الاخير' required/>
                                        </div>

                                        <div className='my-right'>
                                            <div className='part'>
                                                <label htmlFor="">الاسم الاول</label>
                                                <input type="text" placeholder='الاسم الاول' required/>
                                            </div>

                                            {/* hide-in-large Screen */}
                                            <div className='part hide-in-large'>
                                                <label htmlFor="">الاسم الاخير</label>
                                                <input type="text" placeholder='الاسم الاخير' required/>
                                            </div>

                                            <div className='part'>
                                                <label htmlFor="" id='my-sex'>الجنس</label>
                                                
                                                <div className='my-radio'>
                                                    <p>إنثي</p>
                                                    <input type='radio' id='my-sex' name='sex' required/>

                                                    <p>ذكر</p>
                                                    <input type='radio' id='my-sex' name='sex' required/>
                                                    
                                                </div>
                                            </div>

                                            <div className='part nationality'>
                                                <label>الجنسية</label>
                                                <Select
                                                    className='my-Select'
                                                    value={selectedCountry}
                                                    onChange={handleChange}
                                                    options={countriesInArabic}
                                                    placeholder="اختر جنسيتك"
                                                    required
                                                />

                                                {/* <Select
                                                        id="nationality"
                                                        options={options}
                                                        value={selectedCountry}
                                                        onChange={handleChange}
                                                        placeholder="اختر جنسيتك"
                                                        isClearable
                                                        required
                                                    /> */}
                                            </div>

                                            <div className='part'>
                                                <label>تاريخ الميلاد</label>
                                                <input type='date' required/>

                                                {/* <HijriDatePicker
                                                    className = 'my-HijriDatePicker'
                                                    selected={selectedDate}
                                                    onChange={handleDateChange}
                                                    dateFormat="iYYYY/iMM/iDD" // تنسيق التاريخ الهجري
                                                    placeholderText="اكتب تاريخ ميلادك"
                                                    
                                                /> */}

                                                
                                                {/* <Calendar
                                                        value={selectedDay}
                                                        onChange={(e)=> {setSelectedDay(e.target.value)}}
                                                        shouldHighlightWeekends
                                                        calendarType="hijri" // يتيح عرض التاريخ الهجري
                                                        colorPrimary="#4caf50" // تخصيص اللون الأساسي
                                                        locale="en" // تخصيص اللغة
                                                    /> */}
                                            </div>

                                            <div className='part'>
                                                <label htmlFor="">نوع الهوية او الجواز</label>
                                                <select required>
                                                    <option value="">نوع الهوية او الجواز</option>
                                                    <option value="">هوية</option>
                                                    <option value="">جواز</option>
                                                </select>
                                            </div>

                                            <div className='part'>
                                                <label htmlFor="">رقم الهوية او الجواز</label>
                                                <input type='number' placeholder='رقم الهوية او الجواز' required/>
                                            </div>

                                            <div className='part'>
                                                <label htmlFor="">تاريخ الإنتهاء</label>
                                                <input type="text" placeholder='تاريخ الإنتهاء' required/>
                                            </div>
                                            
                                        </div>
                                </div>

                                <hr />

                                {/* Contact Information */}
                                <h2>
                                    بيانات التواصل   
                                    <i className="fa-solid fa-mobile-screen-button"></i>
                                </h2>

                                <div className='my-body'>
                                    <div className='my-left' style={{visibility: 'hidden'}}>
                                        <label htmlFor="">الاسم الاخير</label>
                                        <input type="text" placeholder='الاسم الاخير' required/>
                                    </div>

                                    <div className='my-right'>
                                        <div className='part'>
                                            <label htmlFor="">الاسم الخاص بالتواصل</label>
                                            <input type="text" placeholder='الاسم الخاص بالتواصل' required/>
                                        </div>

                                        <div className='part'>
                                            <label htmlFor="">البريد الإلكتروني</label>
                                            <input type='email' placeholder='البريد الإلكتروني' required/>
                                            <p>يتم إرسال رسالة تأكيد الحجز الإلكترونية إلى هذا العنوان</p>
                                        </div>
                                        
                                        <div className='part'>
                                            <label>رقم الجوال:</label>
                                            <PhoneInput
                                                country={'sa'} // الدولة الافتراضية (يمكن تغييرها)
                                                value={phone}
                                                onChange={(phone) => setPhone(phone)}
                                                inputStyle={{ width: '250px', padding: '10px' }} // يمكنك تعديل التنسيق حسب الحاجة
                                                dropdownStyle={{ width: '400%' }}
                                                preferredCountries={['us', 'ae', 'eg', 'sa']} // الدول المفضلة في بداية القائمة
                                                inputProps={{
                                                    name: 'phone',
                                                    required: true,
                                                    autoFocus: true,
                                                  }}
                                            />
                                        </div>

                                    </div>
                                </div>


                                {/* Passenger (2) Information */}
                                <div className='passnger'>
                                    <h4>
                                        <i className="fa-solid fa-angle-left"></i>
                                        إدخال المعلومات
                                    </h4>

                                    <div className='passenger-number'>
                                        <h4>الراكب 2</h4>
                                        <i className="fa-solid fa-circle-plus"></i>
                                    </div>
                                </div>


                                {/* Total price */}
                                <div className='total-price'>
                                    <button type='submit'>
                                        <i className="fa-solid fa-angles-left"></i>
                                        تفاصيل الدفع
                                    </button>

                                    <h2>إجمالي المبلغ: 1200 ريال</h2>
                                </div>

                                {/* <button onClick={handelSubmit}>
                                    <i className="fa-solid fa-angles-left"></i>
                                    التالي : التفاصيل الاخيرة
                                </button> */}

                            </form>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </>
    );
};


export default InformationForFlight;