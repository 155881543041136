import './style_pakage_flight.css';
import Navbar from '../../Navbar/Navbar';
import plane_img from '../../images/plane.png';
import line_img from '../../images/line.png';
import Emirates_img from '../../images/Emirates.png';
import sudia_img from '../../images/sudia.png';
import Footer from '../../Footer/Footer';
import { Link } from 'react-router-dom';

function PakageFlight() {
    return(
        <>
            <div className='my-pakage-flight'>
                <div className='container'>
                    <Navbar />

                    <div className='this-search-flight'>
                        <h1>This Search Engine</h1>
                    </div>

                    <div className='head'>
                        <div className='top'>
                            <div className='left'>
                                <img src={plane_img} />
                            </div>

                            <div className='right'>
                                <h2>نتائج البحث : (10) رحلات </h2>
                                <p>تتوفر خيارات سفر مريحة و ملائمة لجميع احتياجاتك</p>
                            </div>
                        </div>

                        <div className='down'>
                            <div className='info'>
                                <p>المقصورة</p>
                                <h3>السياحية</h3>
                            </div>

                            <span></span>

                            <div className='info'>
                                <p>المسافرين</p>
                                <h3>4</h3>
                            </div>

                            <span></span>

                            <div className='info'>
                                <p>العودة</p>
                                <h3>7/9</h3>
                            </div>

                            <i className="fa-solid fa-arrows-left-right"></i>

                            <div className='info'>
                                <p>المغادرة</p>
                                <h3>1/9</h3>
                            </div>

                            <span></span>

                            <div className='info'>
                                <p>إلي</p>
                                <h3>دبي</h3>
                            </div>

                            <i className="fa-solid fa-arrows-left-right"></i>

                            <div className='info'>
                                <p>من</p>
                                <h3>القاهرة</h3>
                            </div>

                        </div>

                        {/* For back tripe */}
                        <div className='title'>
                            <p>رحلة العودة</p>
                            <i className="fa-solid fa-circle-check"></i>
                            <hr />

                            <p>رحله الذهاب</p>
                            <i className="fa-solid fa-circle-check"></i>
                            <hr />

                            <p>اختر وجهتك</p>
                            <i className="fa-solid fa-circle-check"></i>
                        </div>
                    </div>

                    {/* Start Leave From ... */}
                    <div className='content'>
                        <div className='head'>
                            <button>تغير</button>

                            <div className='choose-tripe'>
                                <p>2024/9/9 الجمعة</p>
                                <span></span>
                                <h3>المغادرة من القاهرة</h3>
                            </div>
                        </div>

                        <div className='card-tripe'>
                            <div className='top'>

                                <div className='time'>
                                    <h3>09:55</h3>
                                    <p>السبت 9/10</p>
                                    <h4>دبي</h4>
                                    <span className='hide-avalable-sites'>4 مقاعد متاحة</span>
                                </div>

                                <div className='line-flight'>
                                    <p>1 stop</p>
                                    <img src={line_img} />
                                    <p>9 h 50m</p>
                                </div>

                                <div className='time'>
                                    <h3>09:55</h3>
                                    <p>السبت 9/10</p>
                                    <h4>القاهرة</h4>
                                    <span>4 مقاعد متاحة</span>
                                </div>

                                <span className='v-line'></span>

                                <div className='logo-flight'>
                                    <img src={Emirates_img} />
                                    <p>الخطوط الاماراتية</p>
                                    <span>الدرجة الاقتصادية</span>
                                </div>

                            </div>

                            <div className='details'>
                                <i className="fa-solid fa-chevron-down"></i>
                                <h4>تفاصيل الرحلة</h4>
                            </div>

                            <hr />

                            <div className='down'>
                                

                                <div className='right'>
                                    <h4>إجمالي المبلغ (للذهاب و العودة)</h4>
                                    <h3>970 ريال</h3>
                                </div>
                            </div>
                        </div>

                        <div className='pakage'>
                            <h2>اختر الباقة المناسبة لك</h2>

                            <div className='card-pakage'>
                                <div className='this-pakage'>
                                    <div className='pakage-head'>
                                        <h4>بلس</h4>
                                        <h3>+100 ريال</h3>
                                    </div>

                                    <hr />

                                    <div className='pakage-content'>
                                        <p>
                                            حد الامتعة المسموحة
                                            <i className="fa-solid fa-cart-flatbed-suitcase"></i>
                                        </p>

                                        <div className='bag'>
                                            <div className='kilo'>
                                                <span>1 قطعة</span>
                                                <span> * </span>
                                                <span>7 كيلو</span>
                                            </div>

                                            <div className='text'>
                                                <span>حقيبة اليد</span>
                                            </div>
                                            
                                        </div>

                                        <div className='bag'>
                                            <div className='kilo'>
                                                <span>1 قطعة</span>
                                                <span> * </span>
                                                <span>20 كيلو</span>
                                            </div>

                                            <div className='text'>
                                                <span>امتعة السفر</span>
                                            </div>
                                            
                                        </div>
                                    </div>

                                    <hr />

                                    <div className='pakage-content'>
                                        <p>
                                            التغير و استرداد الحجز  
                                            <i className="fa-solid fa-rotate"></i>
                                        </p>

                                        <div className='bag'>
                                            <div className='kilo'>
                                                <span>350 ريال</span>
                                            </div>

                                            <div className='text'>
                                                <span>تعديل الحجز</span>
                                            </div>
                                            
                                        </div>

                                        <div className='bag'>
                                            <div className='kilo'>
                                                <span>غير متاح</span>
                                            </div>

                                            <div className='text'>
                                                <span>إلغاء الحجز</span>
                                            </div>
                                            
                                        </div>
                                    </div>

                                    <hr />

                                    <div className='pakage-content'>
                                        <p>
                                            وسائل الراحة 
                                            <i className="fa-solid fa-heart"></i>
                                        </p>

                                        <div className='bag'>
                                            <div className='kilo'>
                                                <span>غير مشمول</span>
                                            </div>

                                            <div className='text'>
                                                <span>اختيار المقعد</span>
                                            </div>
                                            
                                        </div>

                                        <div className='bag'>
                                            <div className='kilo'>
                                                <span>وجبة خفيفه</span>
                                            </div>

                                            <div className='text'>
                                                <span>الوجبة</span>
                                            </div>
                                            
                                        </div>
                                    </div>

                                    <div className='choose'>
                                        <button>اختر الباقة</button>
                                    </div>
                                </div>

                                <div className='this-pakage'>
                                    <div className='pakage-head'>
                                        <h4>بلس</h4>
                                        <h3>+100 ريال</h3>
                                    </div>

                                    <hr />

                                    <div className='pakage-content'>
                                        <p>
                                            حد الامتعة المسموحة
                                            <i className="fa-solid fa-cart-flatbed-suitcase"></i>
                                        </p>

                                        <div className='bag'>
                                            <div className='kilo'>
                                                <span>1 قطعة</span>
                                                <span> * </span>
                                                <span>7 كيلو</span>
                                            </div>

                                            <div className='text'>
                                                <span>حقيبة اليد</span>
                                            </div>
                                            
                                        </div>

                                        <div className='bag'>
                                            <div className='kilo'>
                                                <span>1 قطعة</span>
                                                <span> * </span>
                                                <span>20 كيلو</span>
                                            </div>

                                            <div className='text'>
                                                <span>امتعة السفر</span>
                                            </div>
                                            
                                        </div>
                                    </div>

                                    <hr />

                                    <div className='pakage-content'>
                                        <p>
                                            التغير و استرداد الحجز  
                                            <i className="fa-solid fa-rotate"></i>
                                        </p>

                                        <div className='bag'>
                                            <div className='kilo'>
                                                <span>350 ريال</span>
                                            </div>

                                            <div className='text'>
                                                <span>تعديل الحجز</span>
                                            </div>
                                            
                                        </div>

                                        <div className='bag'>
                                            <div className='kilo'>
                                                <span>غير متاح</span>
                                            </div>

                                            <div className='text'>
                                                <span>إلغاء الحجز</span>
                                            </div>
                                            
                                        </div>
                                    </div>

                                    <hr />

                                    <div className='pakage-content'>
                                        <p>
                                            وسائل الراحة 
                                            <i className="fa-solid fa-heart"></i>
                                        </p>

                                        <div className='bag'>
                                            <div className='kilo'>
                                                <span>غير مشمول</span>
                                            </div>

                                            <div className='text'>
                                                <span>اختيار المقعد</span>
                                            </div>
                                            
                                        </div>

                                        <div className='bag'>
                                            <div className='kilo'>
                                                <span>وجبة خفيفه</span>
                                            </div>

                                            <div className='text'>
                                                <span>الوجبة</span>
                                            </div>
                                            
                                        </div>
                                    </div>

                                    <div className='choose'>
                                        <button>اختر الباقة</button>
                                    </div>
                                </div>

                                <div className='this-pakage'>
                                    <div className='pakage-head'>
                                        <h4>بلس</h4>
                                        <h3>+100 ريال</h3>
                                    </div>

                                    <hr />

                                    <div className='pakage-content'>
                                        <p>
                                            حد الامتعة المسموحة
                                            <i className="fa-solid fa-cart-flatbed-suitcase"></i>
                                        </p>

                                        <div className='bag'>
                                            <div className='kilo'>
                                                <span>1 قطعة</span>
                                                <span> * </span>
                                                <span>7 كيلو</span>
                                            </div>

                                            <div className='text'>
                                                <span>حقيبة اليد</span>
                                            </div>
                                            
                                        </div>

                                        <div className='bag'>
                                            <div className='kilo'>
                                                <span>1 قطعة</span>
                                                <span> * </span>
                                                <span>20 كيلو</span>
                                            </div>

                                            <div className='text'>
                                                <span>امتعة السفر</span>
                                            </div>
                                            
                                        </div>
                                    </div>

                                    <hr />

                                    <div className='pakage-content'>
                                        <p>
                                            التغير و استرداد الحجز  
                                            <i className="fa-solid fa-rotate"></i>
                                        </p>

                                        <div className='bag'>
                                            <div className='kilo'>
                                                <span>350 ريال</span>
                                            </div>

                                            <div className='text'>
                                                <span>تعديل الحجز</span>
                                            </div>
                                            
                                        </div>

                                        <div className='bag'>
                                            <div className='kilo'>
                                                <span>غير متاح</span>
                                            </div>

                                            <div className='text'>
                                                <span>إلغاء الحجز</span>
                                            </div>
                                            
                                        </div>
                                    </div>

                                    <hr />

                                    <div className='pakage-content'>
                                        <p>
                                            وسائل الراحة 
                                            <i className="fa-solid fa-heart"></i>
                                        </p>

                                        <div className='bag'>
                                            <div className='kilo'>
                                                <span>غير مشمول</span>
                                            </div>

                                            <div className='text'>
                                                <span>اختيار المقعد</span>
                                            </div>
                                            
                                        </div>

                                        <div className='bag'>
                                            <div className='kilo'>
                                                <span>وجبة خفيفه</span>
                                            </div>

                                            <div className='text'>
                                                <span>الوجبة</span>
                                            </div>
                                            
                                        </div>
                                    </div>

                                    <div className='choose'>
                                        <button>اختر الباقة</button>
                                    </div>
                                </div>
                            </div>

                            
                        </div>
                    </div>

                    {/* Start Back From ... */}
                    <div className='content'>
                        <div className='head'>
                            <button>تغير</button>

                            <div className='choose-tripe'>
                                <p>2024/9/12 الجمعة</p>
                                <span></span>
                                <h3>العودة من دبي</h3>
                            </div>
                        </div>

                        <div className='card-tripe'>
                            <div className='top'>

                                <div className='time'>
                                    <h3>09:55</h3>
                                    <p>السبت 9/10</p>
                                    <h4>دبي</h4>
                                    <span className='hide-avalable-sites'>4 مقاعد متاحة</span>
                                </div>

                                <div className='line-flight'>
                                    <p>1 stop</p>
                                    <img src={line_img} />
                                    <p>9 h 50m</p>
                                </div>

                                <div className='time'>
                                    <h3>09:55</h3>
                                    <p>السبت 9/10</p>
                                    <h4>القاهرة</h4>
                                    <span>4 مقاعد متاحة</span>
                                </div>

                                <span className='v-line'></span>

                                <div className='logo-flight'>
                                    <img src={sudia_img} />
                                    <p>الخطوط السعودية</p>
                                    <span>الدرجة الاقتصادية</span>
                                </div>

                            </div>

                            <div className='details'>
                                <i className="fa-solid fa-chevron-down"></i>
                                <h4>تفاصيل الرحلة</h4>
                            </div>

                            <hr />

                            <div className='down'>
                                

                                <div className='right'>
                                    <h4>إجمالي المبلغ (للذهاب و العودة)</h4>
                                    <h3>970 ريال</h3>
                                </div>
                            </div>
                        </div>

                        <div className='pakage'>
                            <h2>اختر الباقة المناسبة لك</h2>

                            <div className='card-pakage'>
                                <div className='this-pakage'>
                                    <div className='pakage-head'>
                                        <h4>بلس</h4>
                                        <h3>+100 ريال</h3>
                                    </div>

                                    <hr />

                                    <div className='pakage-content'>
                                        <p>
                                            حد الامتعة المسموحة
                                            <i className="fa-solid fa-cart-flatbed-suitcase"></i>
                                        </p>

                                        <div className='bag'>
                                            <div className='kilo'>
                                                <span>1 قطعة</span>
                                                <span> * </span>
                                                <span>7 كيلو</span>
                                            </div>

                                            <div className='text'>
                                                <span>حقيبة اليد</span>
                                            </div>
                                            
                                        </div>

                                        <div className='bag'>
                                            <div className='kilo'>
                                                <span>1 قطعة</span>
                                                <span> * </span>
                                                <span>20 كيلو</span>
                                            </div>

                                            <div className='text'>
                                                <span>امتعة السفر</span>
                                            </div>
                                            
                                        </div>
                                    </div>

                                    <hr />

                                    <div className='pakage-content'>
                                        <p>
                                            التغير و استرداد الحجز  
                                            <i className="fa-solid fa-rotate"></i>
                                        </p>

                                        <div className='bag'>
                                            <div className='kilo'>
                                                <span>350 ريال</span>
                                            </div>

                                            <div className='text'>
                                                <span>تعديل الحجز</span>
                                            </div>
                                            
                                        </div>

                                        <div className='bag'>
                                            <div className='kilo'>
                                                <span>غير متاح</span>
                                            </div>

                                            <div className='text'>
                                                <span>إلغاء الحجز</span>
                                            </div>
                                            
                                        </div>
                                    </div>

                                    <hr />

                                    <div className='pakage-content'>
                                        <p>
                                            وسائل الراحة 
                                            <i className="fa-solid fa-heart"></i>
                                        </p>

                                        <div className='bag'>
                                            <div className='kilo'>
                                                <span>غير مشمول</span>
                                            </div>

                                            <div className='text'>
                                                <span>اختيار المقعد</span>
                                            </div>
                                            
                                        </div>

                                        <div className='bag'>
                                            <div className='kilo'>
                                                <span>وجبة خفيفه</span>
                                            </div>

                                            <div className='text'>
                                                <span>الوجبة</span>
                                            </div>
                                            
                                        </div>
                                    </div>

                                    <div className='choose'>
                                        <button>اختر الباقة</button>
                                    </div>
                                </div>

                                <div className='this-pakage'>
                                    <div className='pakage-head'>
                                        <h4>بلس</h4>
                                        <h3>+100 ريال</h3>
                                    </div>

                                    <hr />

                                    <div className='pakage-content'>
                                        <p>
                                            حد الامتعة المسموحة
                                            <i className="fa-solid fa-cart-flatbed-suitcase"></i>
                                        </p>

                                        <div className='bag'>
                                            <div className='kilo'>
                                                <span>1 قطعة</span>
                                                <span> * </span>
                                                <span>7 كيلو</span>
                                            </div>

                                            <div className='text'>
                                                <span>حقيبة اليد</span>
                                            </div>
                                            
                                        </div>

                                        <div className='bag'>
                                            <div className='kilo'>
                                                <span>1 قطعة</span>
                                                <span> * </span>
                                                <span>20 كيلو</span>
                                            </div>

                                            <div className='text'>
                                                <span>امتعة السفر</span>
                                            </div>
                                            
                                        </div>
                                    </div>

                                    <hr />

                                    <div className='pakage-content'>
                                        <p>
                                            التغير و استرداد الحجز  
                                            <i className="fa-solid fa-rotate"></i>
                                        </p>

                                        <div className='bag'>
                                            <div className='kilo'>
                                                <span>350 ريال</span>
                                            </div>

                                            <div className='text'>
                                                <span>تعديل الحجز</span>
                                            </div>
                                            
                                        </div>

                                        <div className='bag'>
                                            <div className='kilo'>
                                                <span>غير متاح</span>
                                            </div>

                                            <div className='text'>
                                                <span>إلغاء الحجز</span>
                                            </div>
                                            
                                        </div>
                                    </div>

                                    <hr />

                                    <div className='pakage-content'>
                                        <p>
                                            وسائل الراحة 
                                            <i className="fa-solid fa-heart"></i>
                                        </p>

                                        <div className='bag'>
                                            <div className='kilo'>
                                                <span>غير مشمول</span>
                                            </div>

                                            <div className='text'>
                                                <span>اختيار المقعد</span>
                                            </div>
                                            
                                        </div>

                                        <div className='bag'>
                                            <div className='kilo'>
                                                <span>وجبة خفيفه</span>
                                            </div>

                                            <div className='text'>
                                                <span>الوجبة</span>
                                            </div>
                                            
                                        </div>
                                    </div>

                                    <div className='choose'>
                                        <button>اختر الباقة</button>
                                    </div>
                                </div>

                                <div className='this-pakage'>
                                    <div className='pakage-head'>
                                        <h4>بلس</h4>
                                        <h3>+100 ريال</h3>
                                    </div>

                                    <hr />

                                    <div className='pakage-content'>
                                        <p>
                                            حد الامتعة المسموحة
                                            <i className="fa-solid fa-cart-flatbed-suitcase"></i>
                                        </p>

                                        <div className='bag'>
                                            <div className='kilo'>
                                                <span>1 قطعة</span>
                                                <span> * </span>
                                                <span>7 كيلو</span>
                                            </div>

                                            <div className='text'>
                                                <span>حقيبة اليد</span>
                                            </div>
                                            
                                        </div>

                                        <div className='bag'>
                                            <div className='kilo'>
                                                <span>1 قطعة</span>
                                                <span> * </span>
                                                <span>20 كيلو</span>
                                            </div>

                                            <div className='text'>
                                                <span>امتعة السفر</span>
                                            </div>
                                            
                                        </div>
                                    </div>

                                    <hr />

                                    <div className='pakage-content'>
                                        <p>
                                            التغير و استرداد الحجز  
                                            <i className="fa-solid fa-rotate"></i>
                                        </p>

                                        <div className='bag'>
                                            <div className='kilo'>
                                                <span>350 ريال</span>
                                            </div>

                                            <div className='text'>
                                                <span>تعديل الحجز</span>
                                            </div>
                                            
                                        </div>

                                        <div className='bag'>
                                            <div className='kilo'>
                                                <span>غير متاح</span>
                                            </div>

                                            <div className='text'>
                                                <span>إلغاء الحجز</span>
                                            </div>
                                            
                                        </div>
                                    </div>

                                    <hr />

                                    <div className='pakage-content'>
                                        <p>
                                            وسائل الراحة 
                                            <i className="fa-solid fa-heart"></i>
                                        </p>

                                        <div className='bag'>
                                            <div className='kilo'>
                                                <span>غير مشمول</span>
                                            </div>

                                            <div className='text'>
                                                <span>اختيار المقعد</span>
                                            </div>
                                            
                                        </div>

                                        <div className='bag'>
                                            <div className='kilo'>
                                                <span>وجبة خفيفه</span>
                                            </div>

                                            <div className='text'>
                                                <span>الوجبة</span>
                                            </div>
                                            
                                        </div>
                                    </div>

                                    <div className='choose'>
                                        <button>اختر الباقة</button>
                                    </div>
                                </div>
                            </div>

                            
                        </div>
                    </div>

                    {/* Total price */}
                    <div className='total-price'>
                        <Link to='/Information-For-Flight'>
                            <button>
                                <i className="fa-solid fa-angles-left"></i>
                                المتابعة للحجز
                            </button>
                        </Link>

                        <h2>إجمالي المبلغ: 1200 ريال</h2>
                    </div>

                    <Footer />
                </div>
            </div>
        </>
    );
};


export default PakageFlight;