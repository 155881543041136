import './style_who_we_are.css';
import Navbar from '../../Navbar/Navbar';
import passport_img from '../../images/passport.png';
import Footer from '../Footer';
import { useEffect, useState } from 'react';
import { collection, doc, getDoc, getDocs, query } from 'firebase/firestore';
import { db } from '../../../Firebase/firebase';
import Loading from '../../Loading/Loading';

function WhoWeAre() {
    const [myData, setMyData] = useState([]);
    console.log(myData);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(()=> {

        const fetchData = async()=> {

            const getMyData = await getDocs(collection(db, 'who'));
            const dataList = getMyData.docs.map((e)=> ({
                ...e.data(),
                id: e.id,
            }));

            console.log(getMyData);
            

            // ترتيب البيانات من الأقدم إلى الأحدث بناءً على Timestamp
            dataList.sort((a, b) => {
                return a.TimeStamp.toDate() - b.TimeStamp.toDate();
            });

            setMyData(dataList);

            setIsLoading(false);
        };

        fetchData();
    }, []);



    const bigerThan_3 = myData.filter((e, index)=> {
        return index > 2
    });

    //console.log(bigerThan_3);



    if(isLoading) {
        return(
            <Loading />
        )

    } else {
        return(
            <>
                <div className='my-who-we-are'>
                    <div className='container'>
                        <Navbar />
    
                        <div className='hero'>
                            <h1>من نحن</h1>
                            <hr />
                        </div>
    
                        <div className='content'>
                            <div className='vision'>
                                {/* <h2>الرؤية</h2> */}

                                {
                                    myData.length > 0 && (
                                        <h2 key={myData[0].id}>{myData[0].title}</h2>
                                    )
                                }

    
                                <div className='text'>

                                {
                                    myData.length > 0 && (
                                        <p key={myData[0].id}>{myData[0].content}</p>
                                    )
                                }
    
                                    <i className="fa-solid fa-layer-group"></i>
                                </div>
                            </div>
    
                            <div className='vision'>
                                {/* <h2>الرسالة</h2> */}

                                {
                                    myData.length > 0 && (
                                        <h2 key={myData[1].id}>{myData[1].title}</h2>
                                    )
                                }
    
                                <div className='text by-img'>
                                    <img src={passport_img} />

                                    {
                                        myData.length > 0 && (
                                            <p className='behinde-img' key={myData[1].id}>{myData[1].content}</p>
                                        )
                                    }
                                    
                                    <i className="fa-solid fa-envelope"></i>
                                </div>
    
                                <img src={passport_img} className='hide-large-screen'/>
                            </div>
    
                            {/* Obj */}
                            <div className='vision'>
                                {/* <h2>الأهداف</h2> */}

                                {
                                    myData.length > 0 && (
                                        <h2 key={myData[0].id}>{myData[2].title}</h2>
                                    )
                                }
    
                                <div className='text'>
                                    <div className='obj'>

                                    {
                                        myData.length > 0 && (
                                            <div key={myData[2].id}>
                                                {
                                                    myData[2].content.split('\n').map((line, index) => (
                                                        <p key={index} style={{ fontWeight: index % 2 === 0 ? 'bold' : 'normal' }}>
                                                            {line}
                                                        </p>
                                                    ))
                                                }
                                            </div>
                                        )
                                    }

                                        {/* {
                                            myData.length > 0 && (
                                                <p key={myData[2].id} dangerouslySetInnerHTML={{ __html: myData[2].content.replace(/\n/g, '<br />') }}></p>
                                            )
                                        } */}
    
                                            {/* {myData.map((e) => {
                                                return (
                                                    <div key={e.id}>
                                                        {Object.keys(e).map((key, index) => {
                                                            // التأكد من أن المفتاح يبدأ بـ "obj_"
                                                            if (key.startsWith("obj_")) {
                                                                return (
                                                                    <p key={index}>
                                                                        <strong>{e[key][0]}</strong> 
                                                                        {e[key][1]}
                                                                    </p>
                                                                );
                                                            }
                                                            return null; // إذا كان المفتاح لا يبدأ بـ "obj_"، لا تعرض شيئًا
                                                        })}
                                                    </div>
                                                );
                                            })} */}
                                        
                                    </div>
                                    
                                    <i className="fa-solid fa-circle-check"></i>
                                    
                                </div>
                            </div>

                            <div className='vision'>
                                {
                                    bigerThan_3.map((e)=> {
                                        return(
                                            <div key={e.id}>
                                                <h2>{e.title}</h2>
                                                <p>{e.content}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
    
                        <Footer />
                        
                    </div>
                </div>
            </>
        );
    }

    
};


export default WhoWeAre;