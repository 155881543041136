import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      "welcome": "Welcome to our website",
      "description": "This is a description in English.",

      "تسجيل الدخول": "Login",
      "اكتشف افضل عروض السفر مع" : "Discover the best travel deals with",
      "ريال سعودي" : "Saudi Riyal",
      "جنية مصري" : "Egyptian Pound",
      "درهم إماراتي" : "UAE Dirham",
      "دولار امريكي" : "US Dollar",

      "البحث عن فنادق او وجهات" : "Find hotels or destinations",
      "إلي": "To",
      "من": "From",
      "فنادق": "Hotels",
      "طيران": "Flight",

      // ======================================= Start More Hotel ===========================================
      "فنادق مختارة من اجل راحتك": "Selected Hotels For Your Comfort",
      "مصر": "Egypt",
      "هيلتون القاهرة": "Hilton Cairo",

      "دبي": "Dubai",
      "منتجع اتلانس ذا رويل": "Atlantis The Royal Resort",

      "مكة المكرمة": "Mecca",
      "فندق فيرمونت برج الساعه": "Fairmont Clock Tower Hotel",

      "إسطنبول": "Istanbul",
      "دبل تري باي هيلتون إسطنبول": "DoubleTree by Hilton Istanbul",

      "اليونان": "Greece",
      "منتجع أثينا ريفييرا": "Athens Riviera Resort",


      "اكتشف المزيد من الفنادق": "Discover More Hotels",

      // ======================================= my-extra-services ===========================================
      "المزيد من الخدمات لاجلك": "More Services For You",
      "رخصة قيادة دولية": "International Driving License",
      "تأشيرات شنقن": "Schengen Visas",

      '"تواصل معنا عبر الواتساب"': '"Contact us via WhatsApp"',
      "اضغط هنا للمراسله": "Click here to contact us",

      "تاشيرة بريطانيا": "UK visa",
      "تاشيرة المانيا": "Germany visa",
      "تاشيرة فرنسا": "France visa",
      "تاشيرة ايطاليا": "Italy visa",
      "تاشيرة النمسا": "Austria visa",
      "تاشيرة اسبانيا": "Spain visa",
      "تاشيرة اذربيجان": "Azerbaijan visa",
      "تاشيرة روسيا": "Russia visa",
      "تاشيرة مصر": "Egypt visa",
      "تاشيرة اثيوبيا": "Ethiopia visa",
      "تاشيرة كينيا": "Kenya visa",
      "تاشيرة تنزانيا": "Tanzania visa",
      "تاشيرة فيتنام": "Vietnam visa",
      "تاشيرة سريلانكا": "Sri Lanka Visa",
      "تاشيرة الهند": "India visa",
      "تاشيرة ارمينيا": "Armenia visa",
      "تاشيرة اوغندا": "Uganda visa",



      // ======================================= my-destenations ===========================================
      "افضل الوجهات السياحية علي الاطلاق": "The Best Tourist Destinations Ever",

      "باريس": "Paris",
      "برشلونة": "Barcelona",
      "لندن": "London",
      "فوكيت": "Phuket",

      "المزيد من الوجهات في انتظارك": "More Destinations Await You",



      // ======================================= my-help ===========================================
      "هل تحتاج مساعدة ؟": "? Do you need help",
      "فريقنا متواجد لخدمتكم على مدار الساعة 24/7": "Our team is here to serve you 24/7.",



      // ======================================= my-footer ===========================================
      "من نحن": "Who we are",
      "الخصوصية": "Privacy",
      "الشروط و الأحكام": "Terms and Conditions",
      "العروض": "Offers",
    }
  },
  ar: {
    translation: {
      "welcome": "مرحبًا بك في موقعنا",
      "description": "هذا وصف باللغة العربية.",

      "تسجيل الدخول": "تسجيل الدخول",
      "اكتشف افضل عروض السفر مع" : "اكتشف افضل عروض السفر مع",
      "ريال سعودي" : "ريال سعودي",
      "جنية مصري" : "جنية مصري",
      "درهم إماراتي" : "درهم إماراتي",
      "دولار امريكي" : "دولار امريكي",

      "البحث عن فنادق او وجهات": "البحث عن فنادق او وجهات",
      "إلي" : "إلي",
      "من": "من",
      "فنادق": "فنادق",
      "طيران": "طيران",

      // ======================================= Start More Hotel ===========================================
      "فنادق مختارة من اجل راحتك": "فنادق مختارة من اجل راحتك",
      "مصر": "مصر",
      "هيلتون القاهرة": "هيلتون القاهرة",

      "دبي": "دبي",
      "منتجع اتلانس ذا رويل": "منتجع اتلانس ذا رويل",

      "مكة المكرمة": "مكة المكرمة",
      "فندق فيرمونت برج الساعه": "فندق فيرمونت برج الساعه",

      "إسطنبول": "إسطانبول",
      "دبل تري باي هيلتون إسطنبول": "دبل تري باي هيلتون إسطنبول",

      "اليونان": "اليونان",
      "منتجع أثينا ريفييرا": "منتجع أثينا ريفييرا",


      "اكتشف المزيد من الفنادق": "اكتشف المزيد من الفنادق",

      // ======================================= my-extra-services ===========================================
      "المزيد من الخدمات لاجلك": "المزيد من الخدمات لاجلك",
      "رخصة قيادة دولية": "رخصة قيادة دولية",
      "تأشيرات شنقن": "تأشيرات شنقن",

      '"تواصل معنا عبر الواتساب"': '"تواصل معنا عبر الواتساب"',
      "اضغط هنا للمراسله": "اضغط هنا للمراسله",

      "تاشيرة بريطانيا": "تاشيرة بريطانيا",
      "تاشيرة المانيا": "تاشيرة المانيا",
      "تاشيرة فرنسا": "تاشيرة فرنسا",
      "تاشيرة ايطاليا": "تاشيرة ايطاليا",
      "تاشيرة النمسا": "تاشيرة النمسا",
      "تاشيرة اسبانيا": "تاشيرة اسبانيا",
      "تاشيرة اذربيجان": "تاشيرة اذربيجان",
      "تاشيرة روسيا": "تاشيرة روسيا",
      "تاشيرة مصر": "تاشيرة مصر",
      "تاشيرة اثيوبيا": "تاشيرة اثيوبيا",
      "تاشيرة كينيا": "تاشيرة كينيا",
      "تاشيرة تنزانيا": "تاشيرة تنزانيا",
      "تاشيرة فيتنام": "تاشيرة فيتنام",
      "تاشيرة سريلانكا": "تاشيرة سريلانكا",
      "تاشيرة الهند": "تاشيرة الهند",
      "تاشيرة ارمينيا": "تاشيرة ارمينيا",
      "تاشيرة اوغندا": "تاشيرة اوغندا",



      // ======================================= my-destenations ===========================================
      "افضل الوجهات السياحية علي الاطلاق": "افضل الوجهات السياحية علي الاطلاق",

      "باريس": "باريس",
      "برشلونة": "برشلونة",
      "لندن": "لندن",
      "فوكيت": "فوكيت",

      "المزيد من الوجهات في انتظارك": "المزيد من الوجهات في انتظارك",



      // ======================================= my-help ===========================================
      "هل تحتاج مساعدة ؟": "هل تحتاج مساعدة ؟",
      "فريقنا متواجد لخدمتكم على مدار الساعة 24/7": "فريقنا متواجد لخدمتكم على مدار الساعة 24/7",



      // ======================================= my-footer ===========================================
      "من نحن": "من نحن",
      "الخصوصية": "الخصوصية",
      "الشروط و الأحكام": "الشروط و الأحكام",
      "العروض": "العروض",
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'ar', // اللغة الافتراضية
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: false
    }
  });

export default i18n;
