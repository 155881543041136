import './style_sidebar_dashboard.css';
import { useEffect, useRef, useState } from 'react';
import small_logo from '../../images/small_logo.png';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../../Footer/Footer';


function SidebarDashboard({titleName, content, toLogOut}) {
    const navigate = useNavigate();

    // To Update Date
    const [myDate, setMyDate] = useState('');

    useEffect(()=> {
        function updateDate() {
            let now = new Date();
    
            let days = ['الأحد', 'الإثنين', 'الثلاثاء', 'الأربعاء', 'الخميس', 'الجمعة', 'السبت'];
            let months = ['يناير', 'فبراير', 'مارس', 'أبريل', 'مايو', 'يونيو', 'يوليو', 'أغسطس', 'سبتمبر', 'أكتوبر', 'نوفمبر', 'ديسمبر'];
    
            // Extract details
            let dayName = days[now.getDay()];
            let dayNumber = now.getDate();
            let monthName = months[now.getMonth()];
            let myYear = now.getFullYear();
    
            if (dayNumber < 10) dayNumber = '0' + dayNumber;
    
            setMyDate(`${dayName} ${dayNumber}, ${monthName} ${myYear}`);
    
            //console.log(dayName, dayNumber, monthName, myYear);
        };
    
        updateDate();
    }, []);

    // To Open And Close Sidebar
    const [openSidebar, setOpenSidebar] = useState(false);
    
    // To Active Link
    const [counter, setCounter] = useState(localStorage.getItem('countNumber') ? Number(localStorage.getItem('countNumber')) : 1);

    return(
        <>
            <div className='my-sidebar-dashboard'>
                <div className='container'>
                    <div className='content'>
                        <div className='left'>
                            <div className='main'>
                                <h2>{myDate}</h2>
                                
                                <div className='to-bar'>
                                    <h2>{titleName}</h2>
                                    <i className="fa-solid fa-bars" onClick={()=> {setOpenSidebar(true)}}></i>
                                </div>
                            </div>

                            {content}
                            
                        </div>

                        <div className='right' style={openSidebar === true ? {right: '0%', transition: '0.3s'} : {right: '-40%', transition: '0.3s'}}>
                            <div className='head'>
                                <i className="fa-regular fa-circle-xmark" onClick={()=> {setOpenSidebar(false)}}></i>
                                
                                <Link to='/'>
                                    <img src={small_logo} />
                                </Link>
                                
                            </div>

                            <div className='my-link'>
                                <div className={counter === 1 ? 'this-link' : 'this-link'} onClick={()=> {
                                    //setCounter(1);
                                    //localStorage.setItem('countNumber', 1);
                                }}>
                                    <Link to='/Master-Dashboard'>
                                        <h4>
                                            الرئيسية
                                            <i className="fa-solid fa-gear"></i>
                                        </h4>
                                    </Link>
                                </div>

                                <div className={counter === 2 ? 'this-link active-link-for-sidebar-dashboard' : 'this-link'} onClick={()=> {
                                    //setCounter(2);
                                    //localStorage.setItem('countNumber', 2);
                                }}>
                                    <Link to='/Who-We-Are-Dashboard'>
                                        <h4>
                                            من نحن
                                            <i className="fa-solid fa-person"></i>
                                        </h4>
                                    </Link>
                                </div>

                                <div className={counter === 3 ? 'this-link active-link-for-sidebar-dashboard' : 'this-link'} onClick={()=> {
                                    // setCounter(3);
                                    // localStorage.setItem('countNumber', 3);
                                }}>
                                    <Link to='/Roules-Dashboard'>
                                        <h4>
                                            الشروط
                                            <i className="fa-solid fa-list-check"></i>
                                        </h4>
                                    </Link>
                                </div>

                                <div className={counter === 4 ? 'this-link active-link-for-sidebar-dashboard' : 'this-link'} onClick={()=> {
                                    // setCounter(4);
                                    // localStorage.setItem('countNumber', 4);
                                }}>
                                    <Link to='/Privicy-Dashboard'>
                                        <h4>
                                            الخصوصية
                                            <i className="fa-solid fa-address-card"></i>
                                        </h4>
                                    </Link>
                                </div>

                                <div className={counter === 5 ? 'this-link active-link-for-sidebar-dashboard' : 'this-link'} onClick={()=> {
                                    // setCounter(5);
                                    // localStorage.setItem('countNumber', 5);
                                }}>
                                    <Link to='/Offers-Dashboard'>
                                        <h4>
                                            العروض
                                            <i className="fa-solid fa-bullhorn"></i>
                                        </h4>
                                    </Link>
                                </div>

                                <div className={counter === 6 ? 'this-link active-link-for-sidebar-dashboard' : 'this-link'} onClick={()=> {
                                    // setCounter(6);
                                    // localStorage.setItem('countNumber', 6);
                                }}>
                                    <Link to='/Discound-Dashboard'>
                                        <h4>
                                            اكواد الخصم
                                            <i className="fa-solid fa-sack-dollar"></i>
                                        </h4>
                                    </Link>
                                </div>

                                <div className={counter === 7 ? 'this-link active-link-for-sidebar-dashboard' : 'this-link'} onClick={()=> {
                                    // setCounter(7);
                                    // localStorage.setItem('countNumber', 7);
                                }}>
                                    <Link to='/Subscribe-Dashboard'>
                                        <h4>
                                            المشتركين
                                            <i className="fa-solid fa-people-group"></i>
                                        </h4>
                                    </Link>
                                </div>

                                <div className='this-link' onClick={()=> {
                                    toLogOut();
                                    
                                    // setCounter(1);
                                    // localStorage.setItem('countNumber', 1);
                                }}>
                                    <Link>
                                        <h4>
                                            خروج
                                            <i className="fa-solid fa-arrow-right-from-bracket"></i>
                                        </h4>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Footer myMargin={0} />
                </div>
            </div>
        </>
    );
};


export default SidebarDashboard;