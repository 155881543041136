import './style_last_favorite.css';
import no_data_img from '../../images/NoData.png';

function LastFavorite() {
    return(
        <>
            <div className="my-last-favorite">
                <div className="container">
                    <div className='no-data'>
                        <img src={no_data_img} />
                        <h1>المفضلة فارغة</h1>
                    </div>
                </div>
            </div>
        </>
    );
};


export default LastFavorite;