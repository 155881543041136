import './style_hero_section.css';
import hero_img from '../images/hero_image.png';
import hero_mobile_img from '../images/Hero_mobile.png';
import logo_img from '../images/logo.png';
import logo_english_img from '../images/small_en_logo.png';

import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import SearchBarHotel from '../Search Bar For Hotel/SearchBarHotel';

function HeroSection({isArabicLange, hideElement = true, topButton, hideTop}) {
    //console.log(isArabicLange);

    const { t, i18n } = useTranslation();
    
    // to change the hero image in small media (480px)
    const [isScreenSmall, setIsScreenSmall] = useState(window.innerWidth < 480);
    //console.log(isScreenSmall);



    useEffect(()=> {

        const handleResize = ()=> {
            setIsScreenSmall(window.innerWidth < 480);
        };

        window.addEventListener('resize', handleResize);

        return ()=> window.removeEventListener('resize', handleResize);

    }, []);


    // Page Direction:
    // تخزين حالة اتجاه الصفحة
    // const [pageDirection, setPageDirection] = useState(document.body.dir);
    // //console.log(pageDirection);

    // // استخدام useEffect لمراقبة تغيير اتجاه الصفحة
    // useEffect(() => {
    //     const handleDirectionChange = () => {
    //         const newDirection = document.body.dir;
    //         setPageDirection(newDirection); // تحديث حالة اتجاه الصفحة
    //         console.log(`تم تغيير اتجاه الصفحة إلى: ${newDirection}`);
    //     };

    //     // مراقبة أي تغيير في اتجاه الصفحة
    //     window.addEventListener('DOMContentLoaded', handleDirectionChange);
    //     window.addEventListener('dirchange', handleDirectionChange); // استخدام حدث افتراضي لمتابعة التغيير

    //     // تنظيف الحدث عند إزالة المكون
    //     return () => {
    //         window.removeEventListener('DOMContentLoaded', handleDirectionChange);
    //         window.removeEventListener('dirchange', handleDirectionChange);
    //     };
    // }, []);
    

    return(
        <>
            <div className='my-hero-section'>
                <div className='container'>

                    {
                        hideElement && (
                            <div className='content' style={isArabicLange === true ? {right: '0', left: 'auto'} : {right: 'auto', left: '0'}}>
                                <h1>{t('اكتشف افضل عروض السفر مع')}</h1>
                                <img src={isArabicLange ? logo_img : logo_english_img} alt="logo" />
                            </div>
                        )
                    }

                    <div className='search-hotel-bar'>
                        <SearchBarHotel isArabicLange={isArabicLange} topButton={topButton} hideTop={hideTop} />
                        
                    </div>

                    {
                        hideElement && (
                            <img src={isScreenSmall === true ? hero_mobile_img : hero_img} className={isScreenSmall === true ? '' : 'big-image'} alt="hero image" />
                        )
                    }                    

                </div>
            </div>
        </>
    );
};


export default HeroSection;