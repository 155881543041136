import './style_last_wallet.css';
import wallet_img from '../../images/wallet.jpg';

function LastWallet() {
    return(
        <>
            <div className="my-last-wallet">
                <div className="container">
                    <div className='no-data'>
                        {/* <img src={no_data_img} />
                        <h1>المفضلة فارغة</h1> */}

                        <img src={wallet_img} />
                        <p><strong>رصيد النقاط: </strong>0</p>
                        <br />
                        <p><strong>يساوي: </strong>0 ريال</p>
                    </div>
                </div>
            </div>
        </>
    );
};


export default LastWallet;