import './style_navbar.css';
import { useTranslation } from 'react-i18next';
import logo_img from '../images/logo.png';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../../Firebase/firebase';
import avatar_img from '../images/avatar_man.jpg';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import logo_english_img from '../images/small_en_logo.png';

function Navbar({isArabicLange, setIsArabicLang}) {
    //console.log(isArabicLange);



    // To Translate By Usin Weglot
    // الحصول على اللغة المخزنة أو تعيين العربية كلغة افتراضية
    // const savedLang = localStorage.getItem('language') || 'ar';
    // const [currentLang, setCurrentLang] = useState(savedLang); // الحالة لتخزين اللغة الحالية

    // // دالة لتبديل اللغة
    // const toggleLanguageWeglot = () => {
    //     const newLang = currentLang === 'ar' ? 'en' : 'ar';
    //     window.Weglot.switchTo(newLang); // تغيير اللغة باستخدام Weglot
    //     setCurrentLang(newLang); // تحديث الحالة

    //     const direction = newLang === 'ar' ? 'ltr' : 'rtl'; // تحديد اتجاه النص
    //     document.body.dir = direction; // تغيير اتجاه النص

    //     const dirLang = newLang === 'ar' ? setIsArabicLang(true) : setIsArabicLang(false);

    //     localStorage.setItem('language', newLang); // تخزين اللغة في localStorage
    //     localStorage.setItem('direction', direction); // تخزين اتجاه النص في localStorage
    // };

    // // استخدم useEffect لتحديث لغة Weglot واتجاه النص عند تحميل الصفحة
    // useEffect(() => {
    //     // استرجاع اللغة واتجاه النص من localStorage
    //     const langFromStorage = localStorage.getItem('language') || 'ar';
    //     const dirFromStorage = localStorage.getItem('direction') || 'rtl';

    //     window.Weglot.switchTo(langFromStorage); // التأكد من أن Weglot في اللغة الصحيحة
    //     document.body.dir = dirFromStorage; // تعيين اتجاه النص
    //     setCurrentLang(langFromStorage); // تعيين الحالة الحالية
    // }, []); 






    const [isLogin, setIsLogin] = useState(false);
    //console.log(isLogin, 'from Navbar');

    const [profile, setProfile] = useState(null);
    //console.log(profile);

    useEffect(()=> {

        const unsubscribe = onAuthStateChanged(auth, (user)=> {
            if(user) {
                setIsLogin(true);

            } else {
                setIsLogin(false);
            }
        });

        return ()=> unsubscribe();

    }, []);


    useEffect(() => {
        const fetchProfile = async () => {
          try {
            const user = auth.currentUser;
            //setGetName(user.displayName);
            console.log(user);
    
            if (!user) {
              //navigate('/login');
              alert('Error For Navbar');
              return;
            }
    
            const docRef = doc(db, 'users', user.uid);
            const docSnap = await getDoc(docRef);
            //console.log(docSnap.data());

    
            if (docSnap.exists()) {
              setProfile(docSnap.data());
            } else {
                console.log('No such document!');
            }
            
          } catch (err) {
            console.log(err);
          }
        };
    
        const unsubscribe = auth.onAuthStateChanged((user) => {
          if (user) {
            fetchProfile();
    
          } 
          
        });
    
        return () => unsubscribe();
      }, []);


    const { t, i18n } = useTranslation();


    const toggleLanguage = () => {
      const newLang = i18n.language === 'ar' ? 'en' : 'ar';
      i18n.changeLanguage(newLang);
      document.body.dir = newLang === 'ar' ? 'ltr' : 'rtl'; // تغيير اتجاه النص
    };

    // Page Direction:
    const pageDirection = document.body.dir;   // ltr OR rtl

    // if(pageDirection === 'rtl') {
    //     console.log('This Arabic Page');

    // } else {
    //     console.log('This English Page');
    // };

    // console.log(document.body.dir);


    const handelIsArabicLang = ()=> {
        try {

            toggleLanguage();
            setIsArabicLang(!isArabicLange);

        } catch(err) {
            console.log(err);
        }
    };


    const toggleLanguageGoogleTranslate = () => {
        const currentLang = document.documentElement.lang || 'ar'; // لغة الموقع الافتراضية 'ar'
        const newLang = currentLang === 'ar' ? 'en' : 'ar';
        
        // الضغط على زر الترجمة في واجهة Google Translate
        const googleTranslateElement = document.querySelector('.goog-te-combo');
        if (googleTranslateElement) {
          googleTranslateElement.value = newLang === 'ar' ? 'ar' : 'en'; // تغيير اللغة بناءً على الاختيار
          googleTranslateElement.dispatchEvent(new Event('change')); // محاكاة تغيير اللغة
        
          document.body.dir = newLang === 'ar' ? 'ltr' : 'rtl'; // تغيير اتجاه النص
        }
      };



      


    return(
        <>
        <div className='my-navbar'>
            <div className='container'>
                <div className='left'>
                    <div className='header'>
                            {
                                isLogin ? (
                                    <div className='my-account'>
                                        <Link to='/profile'>
                                            {/* <h5>حسابي</h5> */}

                                            {
                                                profile ? (
                                                    profile.imageUrl ? (
                                                    <img src={profile.imageUrl} alt="Profile" />
                                                    ) : (
                                                    <img src={avatar_img} alt="Default Avatar" />
                                                    )
                                                ) : (
                                                    null
                                                    //<p>Loading...</p> // يمكنك عرض رسالة تحميل أو عنصر Placeholder حتى يتم جلب البيانات
                                                )
                                            }
                                        </Link>

                                    </div>

                                    
                                ) : (
                                    <Link to='/login'>
                                        <button>{t('تسجيل الدخول')}</button>
                                    </Link>
                                )
                            }

                            <div className="select-container">

                                <select className='curency'>
                                    <option value="">{t('ريال سعودي')}</option>
                                    <option value="">{t('جنية مصري')}</option>
                                    <option value="">{t('درهم إماراتي')}</option>
                                    <option value="">{t('دولار امريكي')}</option>
                                </select>

                                
                            </div>

                            <button className='lang' onClick={handelIsArabicLang}>
                                {i18n.language === 'ar' ? 'En' : 'Ar'}
                            </button>

                            {/* <button className='lang' onClick={toggleLanguageGoogleTranslate}>
                            {document.documentElement.lang === 'ar' ? 'En' : 'Ar'}
                            </button> */}

                            {/* 
                                <button className='lang' onClick={toggleLanguageWeglot}>
                                    {currentLang === 'ar' ? 'En' : 'Ar'}
                                </button>
                            */}
                    </div>
                    
                    
                    
                    <div className='icons'>
                        <i className="fa-solid fa-plane"></i>
                        <i className="fa-solid fa-hotel"></i>
                        <i className="fa-solid fa-house-chimney"></i>
                    </div>
                </div>

                <div className='right'>
                    <Link to='/'>
                        <img src={pageDirection === 'rtl' ? logo_english_img : logo_img} alt="logo" />
                    </Link>
                </div>
            </div>
        </div>
        </>
    ); 
};


export default Navbar;