import './style_reset_password.css';
import React, { useState } from 'react';
import { auth, db, provider } from '../../Firebase/firebase';
import { sendPasswordResetEmail } from 'firebase/auth';
import Navbar from '../Navbar/Navbar';
import { useNavigate } from 'react-router-dom';


function ResetPassword() {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');

  const handlePasswordReset = async (email) => {
    try {
        await sendPasswordResetEmail(auth, email);
        alert("تم إرسال رابط إعادة تعيين كلمة المرور إلى بريدك الإلكتروني. إذا لم تتلق البريد، تأكد من أن البريد الإلكتروني صحيح أو قم بالتسجيل.");
        navigate('/login');

      } catch (error) {
        if (error.code === 'auth/user-not-found') {
            alert("البريد الإلكتروني غير مسجل لدينا. الرجاء التحقق من البريد الإلكتروني.");
        } else {
            console.error("خطأ أثناء إرسال البريد الإلكتروني:", error);
            alert("حدث خطأ أثناء إرسال البريد الإلكتروني. الرجاء المحاولة مرة أخرى.");
        }
    }
};


  const handleSubmit = (e) => {
    e.preventDefault();
    handlePasswordReset(email);
  };

  return (
    <div className='my-reset-password'>
        <Navbar />
        
      <h1>إسترجاع كلمة المرور</h1>
      <h3>الرجاء ادخال البريد الإلكتروني المسجل لاسترجاع كلمة المرور</h3>

      <form onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="أدخل بريدك الإلكتروني"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button type="submit">إعادة تعيين كلمة المرور</button>
      </form>
    </div>
  );
}

export default ResetPassword;
