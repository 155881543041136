import { Link, useParams } from 'react-router-dom';
import './style_room_avaliable.css';
import Navbar from '../../Navbar/Navbar';
import location_img from '../../images/ts-map-pin.png';
import rate_img from '../../images/rate.png';
import avatar_img from '../../images/avatar_man.jpg';
import img_1 from '../../images/galary_1.png';
import img_2 from '../../images/galary_6.png';
import img_3 from '../../images/galary_3.png';
import img_4 from '../../images/galary_4.png';
import img_5 from '../../images/galary_5.png';
import img_6 from '../../images/galary_2.png';
import google_img from '../../images/map-google.png';
import room_img from '../../images/room.png';
import success_img from '../../images/success.png';

import { useState } from 'react';
import Footer from '../../Footer/Footer';
import { useTranslation } from 'react-i18next';


function RoomAvaliable() {
    const { t, i18n } = useTranslation();

    const [myArabicLang, setMyArabicLang] = useState();
    //console.log('From Room Avaliable - ');

    const [count, setCount] = useState(1);

    const [closeMassage, setCloseMassage] = useState(true);

    const handelCounterMinus = ()=> {
        setCount(count - 1);

        if(count === 1) {
            setCount(1);
        }
    };

    const params = useParams();

    return(
        <>
            {/* <h1>Hello Room Avaliable - {params.Id}</h1> */}

            <div className='my-room-avaliable'>

                {/* Start Massages */}
                <div className='success-message' style={closeMassage === true ? {display: 'none'} : {display: 'flex'}}>
                            
                            <div className='my-massage'>
                                
                                <h1>تفاصيل حجزك</h1>
                                <div className='book-details'>
                                    <div className='left'>
                                        <h3>عدد الافراد :4</h3>
                                        <p>الغرف المحجوزة:</p>
                                        <p>1 غرفة مزدوجة</p>
                                        <p>1 سويت</p>
                                        <p>1 سويت</p>
                                    </div>

                                    <div className='right'>
                                        <h3>فندق: هيلتون رمسيس القاهرة</h3>
                                        <h3>إجمالي مده الإقامة: 3 ليالي</h3>
                                        <h2>تسجيل الوصول: الاحد 1 سبتمبر 2024</h2>
                                        <h2>تسجيل المغادرة: الاربعاء 4 سبتمبر 2024</h2>
                                    </div>
                                </div>

                                <hr />

                                <h2 className='final'>إجمالي المبلغ : 580 ريال</h2>
                                
                                <Link to='/Personal-Information'>
                                    <button>
                                        <i className="fa-solid fa-angles-left"></i>
                                        {t('التالي')}
                                    </button>
                                </Link>

                                <i className="fa-solid fa-circle-xmark" onClick={()=> {setCloseMassage(true)}}></i>
                            </div>
                        
                </div>
                {/* End Massages */}

                <Navbar />

                <div className='container'>

                    {/* Top Content */}
                    <div className='top'>
                        <div className='head'>
                            <h1>هيلتون رمسيس القاهرة</h1>

                            <div className='my-img'>
                                <img src={location_img} />
                                <h4>القاهرة - مصر</h4>
                            </div>

                            <hr />
                            <img src={rate_img} />
                        </div>

                        <div className='content'>
                            <div className='left'>
                                <div className='top'>
                                    <div className='comment'>
                                        <h4>تعرّف على أكثر ما أعجب الضيوف</h4>
                                        
                                        <div className='my-avatar'>
                                            <img src={avatar_img} />
                                            <h5>احمد</h5>
                                        </div>
                                        <p>
                                        "كان كلّ شيء جميل ومنظم الجميع بدون استثناء كان لطفاء وكل العاملين والموظفين هناك يعرفون عملهم جيدا ومحترفين ومحترمين.الموقع ممتاز يوجد سنتر كبير ايضاً بجانب الفندق وهذه ميز جعلتنا مرتاحين ايضاً..."
                                        </p>
                                    </div>

                                    <hr />

                                    <div className='comment'>
                                        
                                        <div className='my-avatar'>
                                            <img src={avatar_img} />
                                            <h5>كريم</h5>
                                        </div>
                                        <p>
                                        فندق ممتاز و الخدمة جيدأ جداً
                                        </p>
                                    </div>
                                </div>       

                                <div className='my-map'>
                                    <img src={google_img} />
                                </div>
                            </div>

                            <div className='right'>
                                <div className='big-img'>
                                    <div className='img-main'>
                                        <img src={img_1} />
                                    </div>

                                    <div className='img-secound'>
                                        <img src={img_2} />
                                        <img className='last-img' src={img_3} />
                                    </div>
                                </div>

                                <div className='small-img'>
                                    <div className='more-img'>
                                        <h2>+50</h2>
                                        <h4>المزيد من الصور</h4>
                                    </div>

                                    <img src={img_4} />
                                    <img src={img_5} />
                                    <img src={img_6} />
                                    <img src={img_4} />
                                    <img className='last-img' src={img_5} />
                                </div>
                            </div>
                        </div>

                        <div className='more-img-small-screen'>
                            <button>المزيد من الصور</button>
                        </div>

                        <div className='room-filter'>
                            <h2>تصفية</h2>
                            
                            <div className='filter'>
                                <h4>شامل وجبتين</h4>
                                <h4>يطل علي البحر</h4>
                                <h4>ادفع لاحقاً</h4>
                                <h4>لغير المدخنين</h4>
                                <h4>غرفة اكبر</h4>
                                <h4>إفطار مجاني</h4>
                            </div>
                        </div>
                    </div>

                    {/* Down Content */}
                    <div className='down'>
                        <div className='my-main'>
                            <div className='card'>
                                <div className='mian-img'>
                                    <img src={room_img} />
                                    
                                    <div className='my-arrow'>
                                        <i className="fa-solid fa-circle-left" onClick={handelCounterMinus}></i>
                                        <i className="fa-solid fa-circle-right" onClick={()=> {setCount(count + 1)}}></i>
                                    </div>

                                    <div className='my-number'>
                                        <p>7</p>
                                        <p>من</p>
                                        <p>{count}</p>
                                    </div>
                                </div>

                                <h3>غرفة مزدوجة</h3>

                                <div className='details'>
                                    <p>
                                        مساحة الغرفة : 42م2
                                        <i className="fa-solid fa-expand"></i>
                                    </p>

                                    <p>
                                        KING 2 DOUBLE Bed
                                        <i className="fa-solid fa-bed"></i>
                                    </p>

                                    <p>
                                        إقامة وإفطار    
                                        <i className="fa-solid fa-hotel"></i>
                                    </p>

                                    <p>
                                        قائمة الوسائد   
                                        <i className="fa-solid fa-bell-concierge"></i>
                                    </p>

                                    <p>
                                        خدمة ترتيب الغرفة    
                                        <i className="fa-solid fa-bell-concierge"></i>
                                    </p>
                                </div>

                                <div className='my-select'>
                                    <select>
                                        <option value="">اختر عدد الغرف</option>
                                        <option value="">1</option>
                                        <option value="">2</option>
                                        <option value="">3</option>
                                    </select>

                                    <select>
                                        <option value="">اختر عدد الضيوف</option>
                                        <option value="">1</option>
                                        <option value="">2</option>
                                        <option value="">3</option>
                                    </select>
                                </div>

                                <h4>السعر: 60 ريال/ الليلة</h4>
                            </div>

                            <div className='card'>
                                <div className='mian-img'>
                                    <img src={room_img} />
                                    
                                    <div className='my-arrow'>
                                        <i className="fa-solid fa-circle-left" onClick={handelCounterMinus}></i>
                                        <i className="fa-solid fa-circle-right" onClick={()=> {setCount(count + 1)}}></i>
                                    </div>

                                    <div className='my-number'>
                                        <p>7</p>
                                        <p>من</p>
                                        <p>{count}</p>
                                    </div>
                                </div>

                                <h3>غرفة مزدوجة</h3>

                                <div className='details'>
                                    <p>
                                        مساحة الغرفة : 42م2
                                        <i className="fa-solid fa-expand"></i>
                                    </p>

                                    <p>
                                        KING 2 DOUBLE Bed
                                        <i className="fa-solid fa-bed"></i>
                                    </p>

                                    <p>
                                        إقامة وإفطار    
                                        <i className="fa-solid fa-hotel"></i>
                                    </p>

                                    <p>
                                        قائمة الوسائد   
                                        <i className="fa-solid fa-bell-concierge"></i>
                                    </p>

                                    <p>
                                        خدمة ترتيب الغرفة    
                                        <i className="fa-solid fa-bell-concierge"></i>
                                    </p>
                                </div>

                                <div className='my-select'>
                                    <select>
                                        <option value="">اختر عدد الغرف</option>
                                        <option value="">1</option>
                                        <option value="">2</option>
                                        <option value="">3</option>
                                    </select>

                                    <select>
                                        <option value="">اختر عدد الضيوف</option>
                                        <option value="">1</option>
                                        <option value="">2</option>
                                        <option value="">3</option>
                                    </select>
                                </div>

                                <h4>السعر: 60 ريال/ الليلة</h4>
                            </div>

                            <div className='card'>
                                <div className='mian-img'>
                                    <img src={room_img} />
                                    
                                    <div className='my-arrow'>
                                        <i className="fa-solid fa-circle-left" onClick={handelCounterMinus}></i>
                                        <i className="fa-solid fa-circle-right" onClick={()=> {setCount(count + 1)}}></i>
                                    </div>

                                    <div className='my-number'>
                                        <p>7</p>
                                        <p>من</p>
                                        <p>{count}</p>
                                    </div>
                                </div>

                                <h3>غرفة مزدوجة</h3>

                                <div className='details'>
                                    <p>
                                        مساحة الغرفة : 42م2
                                        <i className="fa-solid fa-expand"></i>
                                    </p>

                                    <p>
                                        KING 2 DOUBLE Bed
                                        <i className="fa-solid fa-bed"></i>
                                    </p>

                                    <p>
                                        إقامة وإفطار    
                                        <i className="fa-solid fa-hotel"></i>
                                    </p>

                                    <p>
                                        قائمة الوسائد   
                                        <i className="fa-solid fa-bell-concierge"></i>
                                    </p>

                                    <p>
                                        خدمة ترتيب الغرفة    
                                        <i className="fa-solid fa-bell-concierge"></i>
                                    </p>
                                </div>

                                <div className='my-select'>
                                    <select>
                                        <option value="">اختر عدد الغرف</option>
                                        <option value="">1</option>
                                        <option value="">2</option>
                                        <option value="">3</option>
                                    </select>

                                    <select>
                                        <option value="">اختر عدد الضيوف</option>
                                        <option value="">1</option>
                                        <option value="">2</option>
                                        <option value="">3</option>
                                    </select>
                                </div>

                                <h4>السعر: 60 ريال/ الليلة</h4>
                            </div>

                        </div>

                        <div className='more-option'>
                            <div className='my-option'>
                                <p>مطاعم 24 ساعة</p>
                                <i className="fa-solid fa-utensils"></i>
                            </div>

                            <div className='my-option'>
                                <p>مركز للياقة البدنية</p>
                                <i className="fa-solid fa-dumbbell"></i>
                            </div>

                            <div className='my-option'>
                                <p>واي فاي مجاني</p>
                                <i className="fa-solid fa-wifi"></i>
                            </div>

                            <div className='my-option'>
                                <p>خدمة الغرف</p>
                                <i className="fa-solid fa-bell-concierge"></i>
                            </div>

                            <div className='my-option'>
                                <p>موقف سيارات مجاني في الموقع</p>
                                <i className="fa-solid fa-square-parking"></i>
                            </div>
                        </div>                        
                        
                    </div>

                    <div className='book-now'>
                        <button onClick={()=> {setCloseMassage(false)}}>احجز الان</button>
                    </div>
                    
                </div>

                <Footer />
            </div>
        </>
    );
};


export default RoomAvaliable;