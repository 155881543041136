import './style_destenations.css';
import { useTranslation } from 'react-i18next';
import travel_img from '../../images/travel.png';
import istanbol_img from '../../images/istanbol-circle.png';
import egypt_img from '../../images/Egypy-circle.png';
import dubi_img from '../../images/Dubi-circle.png';
import maka_img from '../../images/Maka-circle.png';

import paris_img from '../../images/paris.png';
import barca_img from '../../images/barca.png';
import london_img from '../../images/london.png';
import vocit_img from '../../images/vocit.png';

function Destenations() {
    const { t, i18n } = useTranslation();

    return(
        <>
            <div className='my-destenations'>
                <div className='container'>
                    <div className='head'>
                        <span></span>
                        <h1>{t('افضل الوجهات السياحية على الاطلاق')}</h1>
                        <img src={travel_img} />
                        <span></span>
                    </div>

                    <div className='content'>
                        <div className='card'>
                            <img src={istanbol_img} />
                            <h3>{t('إسطنبول')}</h3>
                        </div>

                        <div className='card'>
                            <img src={egypt_img} />
                            <h3>{t('مصر')}</h3>
                        </div>

                        <div className='card'>
                            <img src={dubi_img} />
                            <h3>{t('دبي')}</h3>
                        </div>

                        <div className='card'>
                            <img src={maka_img} />
                            <h3>{t('مكة المكرمة')}</h3>
                        </div>

            

                        <div className='card'>
                            <img src={paris_img} />
                            <h3>{t('باريس')}</h3>
                        </div>

                        <div className='card'>
                            <img src={barca_img} />
                            <h3>{t('برشلونة')}</h3>
                        </div>

                        <div className='card'>
                            <img src={london_img} />
                            <h3>{t('لندن')}</h3>
                        </div>

                        <div className='card'>
                            <img src={vocit_img} />
                            <h3>{t('فوكيت')}</h3>
                        </div>
                    </div>

                    <div className='explore-more'>
                        <button>
                            {t('المزيد من الوجهات في انتظارك')}
                            <i className="fa-solid fa-arrow-right-long"></i>
                            
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};


export default Destenations;