import './style_discound_dashboard.css';
import { useNavigate } from "react-router-dom";
import SidebarDashboard from "../Side Bar Dashboard/SidebarDashboard";
import { useEffect, useState } from 'react';
import { db } from '../../../Firebase/firebase';
import { addDoc, collection, deleteDoc, doc, getDocs, serverTimestamp, Timestamp, updateDoc } from 'firebase/firestore';
import Loading from '../../Loading/Loading';
import no_data_img from '../../images/NoData.png';

function DiscoundDashboard({onSignOutForAdmin}) {
    const navigate = useNavigate();

    // To Loading Page
    const [isLoading, setIsLoading] = useState(true);

    // To Fetch Data
    const [fetchData, setFetchData] = useState([]);
    console.log(fetchData);

    useEffect(()=> {

        const getData = async()=> {

            try {

                const getMyData = await getDocs(collection(db, 'discountCode'));
                const dataList = getMyData.docs.map((e)=> ({id: e.id, ...e.data()}));

                // Sort Data From New To Old
                dataList.sort((a, b) => {
                    return b.TimeStamp.toDate() - a.TimeStamp.toDate();
                });

                setFetchData(dataList);
                setIsLoading(false);

            } catch(error) {
                console.log(error);
            }

        };

        getData();

    }, []);

    // To Read Date From Timestamp
    function formatTimestamp(timestamp) {
        // Ensure timestamp is an instance of Firebase Timestamp
        if (!(timestamp instanceof Timestamp)) {
          throw new Error("Expected a Timestamp object");
        }
      
        // Convert Timestamp to Date object
        const date = timestamp.toDate();
      
        // Format the Date object to a readable string
        return date.toLocaleString(); // Adjust as needed for your locale and format
      };



    // To Add Data
    const [myData, setMyData] = useState([]);
    const [code, setCode] = useState('');
    //console.log(code);

    // Data Of Form To Add
    const [numberOfCopunForSameUser, setNumberOfCopunForSameUser] = useState('');

    const [minPriceForUseCode, setMinPriceForUseCode] = useState('');
    const [maxPriceOfCode, setMaxPriceOfCode] = useState('');
    const [startDateForCopun, setStartDateForCopun] = useState('');
    const [endtDateForCopun, setEndDateForCopun] = useState('');

    const [discountType, setDiscountType] = useState('مبلغ ثابت');
    const [discoundAmount, setDiscountAmount] = useState('');
    const [usesCopun, setUsesCopun] = useState('');
    const [statuesOfCopun, setStatuesOfCopun] = useState('فعال');
    

    const handelAddData = async(generateCode)=> {
        //console.log('add data');
        if(!numberOfCopunForSameUser 
            || !minPriceForUseCode 
            || !maxPriceOfCode 
            || !startDateForCopun 
            || !endtDateForCopun 
            || !discoundAmount 
            || !usesCopun) {
            
            alert('من فضلك اكمل ادخال جميع البيانات');

        } else {

            try {

                const addData = await addDoc(collection(db, 'discountCode'), {
                    code: generateCode,
                    numberOfCopunForSameUser,
                    minPriceForUseCode,
                    maxPriceOfCode,
                    startDateForCopun,
                    endtDateForCopun,
                    discountType,
                    discoundAmount,
                    usesCopun,
                    statuesOfCopun,
                  
                    TimeStamp: serverTimestamp(),
                });
    
                setMyData([...myData, {
                    id: addData.id, 
                    code: generateCode,
                    numberOfCopunForSameUser,
                    minPriceForUseCode,
                    maxPriceOfCode,
                    startDateForCopun,
                    endtDateForCopun,
                    discountType,
                    discoundAmount,
                    usesCopun,
                    statuesOfCopun,
                }])
                setCode('');
                setNumberOfCopunForSameUser(0);
                setMinPriceForUseCode(0);
                setMaxPriceOfCode(0);
                setStartDateForCopun('');
                setEndDateForCopun('');
    
                setDiscountType('مبلغ ثابت');
                setDiscountAmount(0);
                setUsesCopun(0);
                setStatuesOfCopun('فعال');
    
                alert('تم إنشاء كود خصم جديد');
                window.location.reload();
    
            } catch(error) {
                console.log(error);
            }
        }

    };  

    // To LogOut
    const handelLogOut = ()=> {
        onSignOutForAdmin();
        navigate('/My-Admin-Dashboard');
    };

    // To Generate Random Code
    const generateRandomCode = async(e)=> {
        try {

            e.preventDefault();

            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let result = '';
            for (let i = 0; i < 10; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
            }
            setCode(result); // تخزين الكود في state

            handelAddData(result);

        } catch(error) {
            console.log(error);
        };
    };



    // To Delete Data
    const handelDelete = async(dataId)=> {
        try {
            const confirmMassage = window.confirm('هل انت متأكد من مسح الكود ؟');

            if(confirmMassage) {
                await deleteDoc(doc(db, 'discountCode', dataId));
                setFetchData(fetchData.filter(e => e.id !== dataId)); // To return all data without data i delete it  
            } 

           
        } catch(error) {
            console.log(error);
        }
    };



    // To Scroll To Top:-
    const handelScrollToTop = ()=> {
        
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };


    // To Edit Data
    const [editData, setEditData] = useState(null);
    //console.log(editData);

    const handelEdit = async(e)=> {
        
        e.preventDefault();

        try {
            // editData.id  (id)  from ==> setEditData
            await updateDoc(doc(db, 'discountCode', editData.id), {
                numberOfCopunForSameUser,
                minPriceForUseCode,
                maxPriceOfCode,
                startDateForCopun,
                endtDateForCopun,
                discountType,
                discoundAmount,
                usesCopun,
                statuesOfCopun,
            });

            // To Reset State
            setEditData(null);

            setNumberOfCopunForSameUser('');
            setMinPriceForUseCode('');
            setMaxPriceOfCode('');
            setStartDateForCopun('');
            setEndDateForCopun('');

            setDiscountType('');
            setDiscountAmount('');
            setUsesCopun('');
            setStatuesOfCopun('');

            alert('تم تعديل البيانات بنجاح');
            window.location.reload();

        } catch(error) {
            alert(error);
        };
    };



    // To get Id
    const [myId, setMyId] = useState('');


    // My Content
    const discoundDashboardContent = ()=> {
        return(
            <>
                {/* My Form */}
                <form className='generate-code'>
                    {/* Head Of Form */}
                    <div className='my-first'>
                        <label htmlFor="">كم كوبون لكل مستخدم</label>
                        <input 
                        type='number' 
                        min={0} 
                        placeholder='ادخل  عدد مرات استخدام نفس الكوبون لكل مستخدم' 
                        onChange={(e)=> {setNumberOfCopunForSameUser(e.target.value)}} 
                        value={numberOfCopunForSameUser}
                        required />
                    </div>

                    <div className='discount-ditails'>

                        {/* Left */}
                        <div className='discount-left'>
                            <div className='paket'>
                                <label htmlFor="">الحد الادني لمبلغ الشراء</label>
                                <input 
                                type='number' 
                                min={0} 
                                placeholder='الحد الادني لمبلغ الشراء ليتم تفعيل الخصم'
                                onChange={(e)=> {setMinPriceForUseCode(e.target.value)}}
                                value={minPriceForUseCode}
                                required />
                            </div>
                            

                            <div className='paket'>
                                <label htmlFor="">الحد الاقصي لمبلغ الخصم</label>
                                <input type='number'
                                 min={0} 
                                 placeholder='الحد الاقصي لمبلغ الخصم'
                                 onChange={(e)=> {setMaxPriceOfCode(e.target.value)}}
                                 value={maxPriceOfCode}
                                 required />
                            </div>


                            <div className='paket'>
                                <label htmlFor="">تاريخ بداية تفعيل الكوبون</label>
                                <input 
                                type='date'
                                onChange={(e)=> {setStartDateForCopun(e.target.value)}}
                                value={startDateForCopun}
                                required />
                            </div>


                            <div className='paket'>
                                <label htmlFor="">تاريخ نهاية تفعيل الكوبون</label>
                                <input 
                                type='date' 
                                onChange={(e)=> {setEndDateForCopun(e.target.value)}}
                                value={endtDateForCopun}
                                required />
                            </div>
                        </div>

                        {/* Right */}
                        <div className='discount-right'>
                            <div className='paket-right'>
                                <label htmlFor="">نوع الخصم</label>

                                <select 
                                onChange={(e)=> {setDiscountType(e.target.value)}} 
                                value={discountType}
                                required>
                                    <option value="مبلغ ثابت">مبلغ ثابت</option>
                                    <option value="نسبة مئوية">نسبة مئوية</option>
                                </select>
                            </div>


                            <div className='paket-right'>
                                <label htmlFor="">مقدار الخصم</label>
                                <input type='number'
                                 min={0} 
                                 placeholder='ادخل مقدار الخصم' 
                                 onChange={(e)=> {setDiscountAmount(e.target.value)}}
                                 value={discoundAmount}
                                 required />
                            </div>


                            <div className='paket-right'>
                                <label htmlFor="">عدد مرات استخدام الكوبون</label>
                                <input 
                                type='number' 
                                min={0} 
                                placeholder='ادخل عدد مرات استخدام الكوبون الإجمالية' 
                                onChange={(e)=> {setUsesCopun(e.target.value)}}
                                value={usesCopun}
                                required />
                            </div>


                            <div className='paket-right'>
                                <label htmlFor="">حالة الكوبون</label>

                                <select 
                                onChange={(e)=> {setStatuesOfCopun(e.target.value)}} 
                                value={statuesOfCopun}
                                required>
                                    <option value="فعال">فعال</option>
                                    <option value="غير فعال">غير فعال</option>
                                </select>
                            </div>                            
                        </div>
                    </div>


                    <div className='btn-sub'>
                        <button onClick={editData ? handelEdit : generateRandomCode}>
                            {editData ? 'حفظ التعديلات' : 'إنشاء كود خصم جديد'}
                        </button>
                    </div>
                    
                </form>



                <div className='head-content'>
                    <span></span>
                    <h2>اكواد الخصم الحالية</h2>
                    <span></span>
                </div>

                {/* From Database */}
                
                {
                    fetchData.length > 0 ? (
                        <div>
                            {
                            fetchData.map((e)=> {
                                return(
                                    <div className='curent-code' key={e.id}>
                                        <div className='this-code'>
                                            <div className='btn'>
                                                {
                                                    myId === e.id && (
                                                        <i className="fa-solid fa-circle-check"></i>
                                                    )
                                                }
                                                
        
                                                <button className='delete' onClick={()=> handelDelete(e.id)}>حذف</button>
                                                
                                                <button className='copy' onClick={()=> {
                                                    
                                                    navigator.clipboard.writeText(e.code);
                                                    setMyId(e.id);
                                                }}>نسخ</button>
        
                                                <button className='edit' onClick={()=> {
                                                    handelScrollToTop();
        
                                                    setEditData(e); // to get id and all content
                                                    //setTitle(e.title);
                                                    setNumberOfCopunForSameUser(e.numberOfCopunForSameUser);
                                                    setMinPriceForUseCode(e.minPriceForUseCode);
                                                    setMaxPriceOfCode(e.maxPriceOfCode);
                                                    setStartDateForCopun(e.startDateForCopun);
                                                    setEndDateForCopun(e.endtDateForCopun);
        
                                                    setDiscountType(e.discountType);
                                                    setDiscountAmount(e.discoundAmount);
                                                    setUsesCopun(e.usesCopun);
                                                    setStatuesOfCopun(e.statuesOfCopun);
                                                    
                                                }}>تعديل</button>
        
                                                
                                            </div>
        
                                            <input type="text" defaultValue={e.code} />
                                        </div>
                                        <p>{`تم الإنشاء بتاريخ ${formatTimestamp(e.TimeStamp)} - ${e.statuesOfCopun} - ${e.discountType}`}</p>
                                    </div>
                                )
                            })
                        }
                        </div>
                    ) : (
                        
                        <div style={{textAlign: 'center'}}>
                            <img src={no_data_img} style={{mixBlendMode: 'multiply'}} />
                            <h2>لا يوجد اكواد حالية</h2>
                        </div>
                    )
                }

            </>
        );
    };

    if(isLoading) {
        return(
            <Loading />
        )

    } else {
        return(
            <>
                <div className="my-discound-dashboard">
                    <div className="container">
                        <SidebarDashboard titleName='اكواد الخصم' content={discoundDashboardContent()} toLogOut={handelLogOut} />
                    </div>
                </div>
            </>
        );
    }

    
};


export default DiscoundDashboard;