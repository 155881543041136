import HeroSection from '../Hero Section/HeroSection';
import Navbar from '../Navbar/Navbar';
import SearchBarHotel from '../Search Bar For Hotel/SearchBarHotel';
import './style_search_result_hotel.css';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';

import location_img from '../images/ts-map-pin.png';
import pyramids_img from '../images/Cairo-landmarks-2.png';
import italy_img from '../images/italy.png';
import marakish_img from '../images/marakish.png';
import google_map_img from '../images/GoogleMapTA.png';
import helton_img from '../images/Helton.png';
import maka_img from '../images/small-maka.png';
import rate_img from '../images/rate.png';
import Footer from '../Footer/Footer';
import { Link } from 'react-router-dom';

function SearchResultHotel({isArabicLange, topButton = true, hideTop = false}) {
    const [priceRange, setPriceRange] = useState();
    //console.log(priceRange);

    // Start Search
    const [isFilterHotel, setIsFilterHotel] = useState(true);

    const [isOpenDestination, setIsOpenDestination] = useState(false);
    const [typeOfSelect, setTypeOfSelect] = useState('one');
    //console.log(typeOfSelect);

    const [showTypeOfSelect, setShowTypeOfSelect] = useState(false);

    const [counter, setCounter] = useState(1);
    
    const [defaultValueWhenChooseDestination, setDefaultValueWhenChooseDestination] = useState('');

    useEffect(()=> {

        if (typeOfSelect === 'other') {
            setShowTypeOfSelect(true);
    
        } else {
            setShowTypeOfSelect(false);
        }

    }, [typeOfSelect]);


    // End Search



    const { t, i18n } = useTranslation();

    const hotelCardRef = useRef(null);

    const handelScrollRight = ()=> {
        hotelCardRef.current.style.scrollBehavior = "smooth";
        hotelCardRef.current.scrollLeft += 200; 
    };

    const handelScrollLeft = ()=> {
        hotelCardRef.current.style.scrollBehavior = "smooth";
        hotelCardRef.current.scrollLeft -= 200; 
    };

    return(
        <>
            <div className='my-search-result-hotel'>
                <div className='container'>
                    <Navbar />

                    <div className='bar-hotel'>
                         
                        <div className='this-search-bar-hotel'>

                                <div className='this-container'>

                                    {
                                        isFilterHotel ? (
                                            <form className='hotel-form'>
                                                <div className='content' style={isArabicLange === true ? {left: '30%'} : null}>

                                                    <div className='search-icon'>
                                                            
                                                            <button type='submit'> 
                                                                <i className="fa-solid fa-magnifying-glass-plus"></i>
                                                            </button>
                                                        
                                                    </div>
                                                    
                                                    <div className='search'>
                                                        {/* Number Of Guest */}
                                                        <div className='location my-select'>
                                                            <select style={isArabicLange === true ? {direction: 'rtl'} : {direction: 'ltr'}} onChange={(e)=> {setTypeOfSelect(e.target.value)}}>
                                                                <option value="one">{t('1 غرفة ، 1 بالغ ، 0 اطفال')}</option>
                                                                <option value="tow">{t('1 غرفة ، 2 بالغين ، 0 اطفال')}</option>
                                                                <option value="other">{t('خيارات إضافية')}</option>
                                                                
                                                            </select>

                                                            <i className="fa-solid fa-user"></i>
                                                        </div>

                                                        <div className="data-from">
                                                            <input type="date" />
                                                            <span>{t('إلي')}</span>
                                                        </div>
                                                        
                                                        <div className="data-from">
                                                            <input type="date" />
                                                            <span>{t('من')}</span>
                                                        </div>

                                                        <div className='location'>
                                                            <i className="fa-solid fa-caret-down" style={isOpenDestination === true ? {color: 'green'}: null} onClick={()=> {setIsOpenDestination(!isOpenDestination)}}></i>
                                                            <input type="text" defaultValue={defaultValueWhenChooseDestination} placeholder={t('البحث عن فنادق او وجهات')} style={isArabicLange === true ? {direction: 'rtl'} : {direction: 'ltr'}} />
                                                            <i className="fa-solid fa-location-dot"></i>
                                                        </div>
                                                        
                                                    </div>

                                                    
                                                
                                                </div>

                                                <div className='open-destinations' style={isOpenDestination === false ? {display: 'none'} : null}>
                                                    <div className='my-content'>
                                                        <div className='card' onClick={()=> {
                                                            setDefaultValueWhenChooseDestination('القاهرة');
                                                            setIsOpenDestination(false);
                                                        }}>
                                                            <div className='left'>
                                                                <h4>{t('القاهرة')}</h4>
                                                                <h5>{t('القاهرة - عاصمة مصر')}</h5>
                                                                <p>{t('الاماكن المميزة مثل الاهرامات و المتحف المصري')}</p>
                                                            </div>

                                                            <div className='right'>
                                                                <img src={pyramids_img} />
                                                            </div>
                                                        </div>

                                                        <div className='card' onClick={()=> {
                                                            setDefaultValueWhenChooseDestination('روما');
                                                            setIsOpenDestination(false);
                                                        }}>
                                                            <div className='left'>
                                                                <h4>{t('روما')}</h4>
                                                                <h5>{t('روما - عاصمة إيطاليا')}</h5>
                                                                <p>{t('الاماكن المميزة مثل برج بيزا المائل و الكولوسيوم')}</p>
                                                            </div>

                                                            <div className='right'>
                                                                <img src={italy_img} />
                                                            </div>
                                                        </div>

                                                        <div className='card' onClick={()=> {
                                                            setDefaultValueWhenChooseDestination('ماراكش');
                                                            setIsOpenDestination(false);
                                                        }}>
                                                            <div className='left'>
                                                                <h4>{t('ماراكش')}</h4>
                                                                <h5>{t('ماراكش - مدينة مغربية')}</h5>
                                                                <p>{t('الاماكن المميزة مثل قصر الباهية و حدائق ماجوريل')}</p>
                                                            </div>

                                                            <div className='right'>
                                                                <img src={marakish_img} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                                {/* Start Type Of Select (other) */}

                                                <div className='open-other-select' style={showTypeOfSelect === false ? {display: 'none'} : null}>
                                                    <div className='my-content'>
                                                        <div className='card'>
                                                                <div className='first'>
                                                                    <h5 className='test'>text for test</h5>
                                                                    <h5>{t('البالغين (+12 عاماً)')}</h5>
                                                                    <h5>{t('عمر الأطفال من (0- 11)')}</h5>
                                                                </div>

                                                                <div className='secound'>
                                                                    <div className='room'>
                                                                        <h4>{t('غرفة 1')}</h4>
                                                                        <i className="fa-solid fa-bed"></i>
                                                                    </div>

                                                                    <div className='my-form'>
                                                                        <div className='choose-room'>
                                                                            <div className='adult'>
                                                                                
                                                                                <select>
                                                                                    <option value="">{t('عدد البالغين')}</option>
                                                                                    <option value="">{t('1')}</option>
                                                                                    <option value="">{t('2')}</option>
                                                                                    <option value="">{t('3')}</option>
                                                                                    <option value="">{t('4')}</option>
                                                                                    <option value="">{t('5')}</option>
                                                                                    <option value="">{t('6')}</option>
                                                                                    <option value="">{t('7')}</option>
                                                                                    <option value="">{t('8')}</option>
                                                                                </select>
                                                                            </div>

                                                                            <div className='children'>
                                                                                <select>
                                                                                    <option value="">{t('عدد الاطفال')}</option>
                                                                                    <option value="">{t('1')}</option>
                                                                                    <option value="">{t('2')}</option>
                                                                                    <option value="">{t('3')}</option>
                                                                                    <option value="">{t('4')}</option>
                                                                                    <option value="">{t('5')}</option>
                                                                                    <option value="">{t('6')}</option>
                                                                                    <option value="">{t('7')}</option>
                                                                                    <option value="">{t('8')}</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    
                                                                </div>




                                                                <div className='secound' style={counter >= 2 ? {display: 'flex'} : {display: 'none'}}>
                                                                    
                                                                    <div className='room'>
                                                                        <h4>{t('غرفة 2')}</h4>
                                                                        <i className="fa-solid fa-bed"></i>
                                                                    </div>

                                                                    <div className='my-form'>
                                                                        <div className='choose-room'>
                                                                            <div className='adult'>
                                                                                
                                                                                <select>
                                                                                    <option value="">{t('عدد البالغين')}</option>
                                                                                    <option value="">{t('1')}</option>
                                                                                    <option value="">{t('2')}</option>
                                                                                    <option value="">{t('3')}</option>
                                                                                    <option value="">{t('4')}</option>
                                                                                    <option value="">{t('5')}</option>
                                                                                    <option value="">{t('6')}</option>
                                                                                    <option value="">{t('7')}</option>
                                                                                    <option value="">{t('8')}</option>
                                                                                </select>
                                                                            </div>

                                                                            <div className='children'>
                                                                                <select>
                                                                                    <option value="">{t('عدد الاطفال')}</option>
                                                                                    <option value="">{t('1')}</option>
                                                                                    <option value="">{t('2')}</option>
                                                                                    <option value="">{t('3')}</option>
                                                                                    <option value="">{t('4')}</option>
                                                                                    <option value="">{t('5')}</option>
                                                                                    <option value="">{t('6')}</option>
                                                                                    <option value="">{t('7')}</option>
                                                                                    <option value="">{t('8')}</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <i className="fa-solid fa-circle-xmark" onClick={()=> {setCounter(counter - 1)}}></i>
                                                                </div>





                                                                <div className='secound' style={counter >= 3 ? {display: 'flex'} : {display: 'none'}}>
                                                                    
                                                                    <div className='room'>
                                                                        <h4>{t('غرفة 3')}</h4>
                                                                        <i className="fa-solid fa-bed"></i>
                                                                    </div>

                                                                    <div className='my-form'>
                                                                        <div className='choose-room'>
                                                                            <div className='adult'>
                                                                                
                                                                                <select>
                                                                                    <option value="">{t('عدد البالغين')}</option>
                                                                                    <option value="">{t('1')}</option>
                                                                                    <option value="">{t('2')}</option>
                                                                                    <option value="">{t('3')}</option>
                                                                                    <option value="">{t('4')}</option>
                                                                                    <option value="">{t('5')}</option>
                                                                                    <option value="">{t('6')}</option>
                                                                                    <option value="">{t('7')}</option>
                                                                                    <option value="">{t('8')}</option>
                                                                                </select>
                                                                            </div>

                                                                            <div className='children'>
                                                                                <select>
                                                                                    <option value="">{t('عدد الاطفال')}</option>
                                                                                    <option value="">{t('1')}</option>
                                                                                    <option value="">{t('2')}</option>
                                                                                    <option value="">{t('3')}</option>
                                                                                    <option value="">{t('4')}</option>
                                                                                    <option value="">{t('5')}</option>
                                                                                    <option value="">{t('6')}</option>
                                                                                    <option value="">{t('7')}</option>
                                                                                    <option value="">{t('8')}</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <i className="fa-solid fa-circle-xmark" onClick={()=> {setCounter(counter - 1)}}></i>
                                                                </div>





                                                                <div className='secound' style={counter >= 4 ? {display: 'flex'} : {display: 'none'}}>
                                                                    
                                                                    <div className='room'>
                                                                        <h4>{t('غرفة 4')}</h4>
                                                                        <i className="fa-solid fa-bed"></i>
                                                                    </div>

                                                                    <div className='my-form'>
                                                                        <div className='choose-room'>
                                                                            <div className='adult'>
                                                                                
                                                                                <select>
                                                                                    <option value="">{t('عدد البالغين')}</option>
                                                                                    <option value="">{t('1')}</option>
                                                                                    <option value="">{t('2')}</option>
                                                                                    <option value="">{t('3')}</option>
                                                                                    <option value="">{t('4')}</option>
                                                                                    <option value="">{t('5')}</option>
                                                                                    <option value="">{t('6')}</option>
                                                                                    <option value="">{t('7')}</option>
                                                                                    <option value="">{t('8')}</option>
                                                                                </select>
                                                                            </div>

                                                                            <div className='children'>
                                                                                <select>
                                                                                    <option value="">{t('عدد الاطفال')}</option>
                                                                                    <option value="">{t('1')}</option>
                                                                                    <option value="">{t('2')}</option>
                                                                                    <option value="">{t('3')}</option>
                                                                                    <option value="">{t('4')}</option>
                                                                                    <option value="">{t('5')}</option>
                                                                                    <option value="">{t('6')}</option>
                                                                                    <option value="">{t('7')}</option>
                                                                                    <option value="">{t('8')}</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <i className="fa-solid fa-circle-xmark" onClick={()=> {setCounter(counter - 1)}}></i>
                                                                </div>





                                                                <hr />

                                                                <div className='save'>
                                                                    <button>{t('حفظ')}</button>
                                                                </div>

                                                                

                                                                <div className='add-room' style={counter === 4 ? {display: 'none'} : null}>
                                                                    <i className="fa-solid fa-circle-plus" onClick={()=> {setCounter(counter + 1)}}></i>
                                                                    <h5>{t('إضافة غرفة أخرى (4 كحد أقصى)')}</h5>
                                                                </div>
                                                                
                                                        </div>

                                                    </div>
                                                </div>

                                                {/* End Type Of Select (other) */}
                                            </form>

                                            
                                        ) : (
                                            null
                                        )
                                    }

                                    
                                </div>
                        </div>
                        
                    </div>

                    <div className='head'>
                        <div className='top'>
                            <div className='filter'>
                                <img src={location_img} />
                                <select>
                                    <option value="">الاحدث</option>
                                    <option value="">الارخص</option>
                                    <option value="">الاغلي</option>
                                </select>
                                <h3>:رتب حسب</h3>
                            </div>

                            <div className='result'>
                                <h2>نتائج البحث : <strong>(100) فندق</strong></h2>
                                <p>تتوفر خيارات إقامة مريحة وملائمة في هذه الفنادق</p>
                            </div>
                        </div>

                        <div className='down'>
                            <h2> !وجهات مقترحة لك</h2>

                            <div className='open-destinations'>
                                <i className="fa-solid fa-circle-arrow-left" onClick={handelScrollLeft}></i>
                                
                                <div className='my-content' ref={hotelCardRef}>
                                    <div className='card'>
                                        <div className='left'>
                                            <h4>القاهرة</h4>
                                            <h5>القاهرة - عاصمة مصر</h5>
                                            <p>الاماكن المميزة مثل الاهرامات و المتحف المصري</p>
                                            <button>اعرف المزيد</button>
                                        </div>
                                        <div className='right'>
                                            <img src={pyramids_img} alt="Pyramids" />
                                        </div>
                                    </div>
                                    

                                    <div className='card'>
                                        <div className='left'>
                                            <h4>القاهرة</h4>
                                            <h5>القاهرة - عاصمة مصر</h5>
                                            <p>الاماكن المميزة مثل الاهرامات و المتحف المصري</p>
                                            <button>اعرف المزيد</button>
                                        </div>
                                        <div className='right'>
                                            <img src={pyramids_img} alt="Pyramids" />
                                        </div>
                                    </div>

                                    <div className='card'>
                                        <div className='left'>
                                            <h4>القاهرة</h4>
                                            <h5>القاهرة - عاصمة مصر</h5>
                                            <p>الاماكن المميزة مثل الاهرامات و المتحف المصري</p>
                                            <button>اعرف المزيد</button>
                                        </div>
                                        <div className='right'>
                                            <img src={pyramids_img} alt="Pyramids" />
                                        </div>
                                    </div>

                                    <div className='card'>
                                        <div className='left'>
                                            <h4>القاهرة</h4>
                                            <h5>القاهرة - عاصمة مصر</h5>
                                            <p>الاماكن المميزة مثل الاهرامات و المتحف المصري</p>
                                            <button>اعرف المزيد</button>
                                        </div>
                                        <div className='right'>
                                            <img src={pyramids_img} alt="Pyramids" />
                                        </div>
                                    </div>
                                    
                                </div>

                                <i className="fa-solid fa-circle-arrow-right" onClick={handelScrollRight}></i> 
                            </div>

                        </div>
                    </div>

                    <div className='content'>
                        <div className='left'>
                            <div className='my-map'>
                                <img src={google_map_img} />
                            </div>

                            <div className='my-filter'>
                                {/* Hotel Rate */}
                                <div className='rate-hotel'>
                                    <h2>
                                        تصنيف الفندق    
                                        <i className="fa-solid fa-hotel"></i>
                                    </h2>

                                    <div className='my-content'>
                                        <div className='left'>
                                            <p>40</p>
                                        </div>

                                        <div className='right'>
                                            <img src={rate_img} />
                                            <p>5</p>
                                            <input type='checkbox' />
                                        </div>
                                    </div>

                                    <div className='my-content'>
                                        <div className='left'>
                                            <p>40</p>
                                        </div>

                                        <div className='right'>
                                            <img src={rate_img} />
                                            <p>5</p>
                                            <input type='checkbox' />
                                        </div>
                                    </div>

                                    <div className='my-content'>
                                        <div className='left'>
                                            <p>40</p>
                                        </div>

                                        <div className='right'>
                                            <img src={rate_img} />
                                            <p>5</p>
                                            <input type='checkbox' />
                                        </div>
                                    </div>

                                    <div className='my-content'>
                                        <div className='left'>
                                            <p>40</p>
                                        </div>

                                        <div className='right'>
                                            <img src={rate_img} />
                                            <p>5</p>
                                            <input type='checkbox' />
                                        </div>
                                    </div>
                                </div>

                                <hr />

                                {/* Price Range */}
                                <div className='price'>
                                    <h2>
                                        نطاق السعر   
                                        <i className="fa-solid fa-dollar-sign"></i>
                                    </h2>

                                    <div className='my-content'>
                                        <p>
                                            {priceRange}
                                            <br />
                                            ريال    
                                        </p>
                                        <input type='range' min={50} max={5000} onChange={(e)=> {setPriceRange(e.target.value)}}/>
                                        <p>5000 ريال</p>
                                    </div>
                                </div>

                                <hr />

                                {/* Infrastructure */}
                                <div className='rate-hotel'>
                                    <h2>
                                        المرافق   
                                        <i className="fa-solid fa-bell-concierge"></i>
                                    </h2>

                                    <div className='my-content'>
                                        <div className='left'>
                                            <p>420</p>
                                        </div>

                                        <div className='right'>
                                            <p>مواقف سيارات</p>
                                            <input type='checkbox' />
                                        </div>
                                    </div>

                                    <div className='my-content'>
                                        <div className='left'>
                                            <p>420</p>
                                        </div>

                                        <div className='right'>
                                            <p>مواقف سيارات</p>
                                            <input type='checkbox' />
                                        </div>
                                    </div>

                                    <div className='my-content'>
                                        <div className='left'>
                                            <p>420</p>
                                        </div>

                                        <div className='right'>
                                            <p>مواقف سيارات</p>
                                            <input type='checkbox' />
                                        </div>
                                    </div>

                                    <div className='my-content'>
                                        <div className='left'>
                                            <p>420</p>
                                        </div>

                                        <div className='right'>
                                            <p>مواقف سيارات</p>
                                            <input type='checkbox' />
                                        </div>
                                    </div>

                                    
                                </div>

                                <hr />

                                {/* Eat */}
                                <div className='rate-hotel'>
                                    <h2>
                                        الوجبات   
                                        <i className="fa-solid fa-utensils"></i>
                                    </h2>

                                    <div className='my-content'>
                                        <div className='left'>
                                            <p>420</p>
                                        </div>

                                        <div className='right'>
                                            <p>إعداد الوجبات ذاتياً</p>
                                            <input type='checkbox' />
                                        </div>
                                    </div>

                                    <div className='my-content'>
                                        <div className='left'>
                                            <p>420</p>
                                        </div>

                                        <div className='right'>
                                            <p>إعداد الوجبات ذاتياً</p>
                                            <input type='checkbox' />
                                        </div>
                                    </div>

                                    <div className='my-content'>
                                        <div className='left'>
                                            <p>420</p>
                                        </div>

                                        <div className='right'>
                                            <p>إعداد الوجبات ذاتياً</p>
                                            <input type='checkbox' />
                                        </div>
                                    </div>

                                    <div className='my-content'>
                                        <div className='left'>
                                            <p>420</p>
                                        </div>

                                        <div className='right'>
                                            <p>إعداد الوجبات ذاتياً</p>
                                            <input type='checkbox' />
                                        </div>
                                    </div>

                                </div>
                                
                            </div>
                        </div>

                        <div className='right'>
                            <h1>فنادق مختارة لاجلك بعناية</h1>

                            <div className='my-hotels'>
                                
                                
                                <div className='my-content'>
                                    <div className='card'>

                                        <div className='details'>
                                            <i className="fa-solid fa-heart"></i>
                                            <h2>ريال 70</h2>
                                            <p>إجمالي 1 ليلة شامل ضريبة القيمة المضافة</p>
                                            <Link to='/Room-Avaliable'>
                                                <button>الغرف المتاحة</button>
                                            </Link>
                                        </div>

                                        <div className='my-image'>
                                            <div className='left'>
                                                <h2>هيلتون رمسيس القاهرة</h2>
                                                <img src={rate_img} />
                                                <p>القاهرة - مصر - 1 كم من مركز المدينة</p>
                                                <h5>
                                                    مناسب للعائلات  
                                                    <i className="fa-solid fa-people-group"></i>
                                                </h5>
                                                <p className='count'>يشاهد هذا الفندق حالياً 29 شخص</p>
                                            </div>

                                            <div className='right'>
                                                <img src={helton_img} alt="Pyramids" />
                                            </div>
                                        </div>
                                    </div>
                                    

                                    <div className='card'>

                                        <div className='details'>
                                            <i className="fa-solid fa-heart"></i>
                                            <h2>ريال 100</h2>
                                            <p>إجمالي 1 ليلة شامل ضريبة القيمة المضافة</p>
                                            <button>الغرف المتاحة</button>
                                        </div>

                                        <div className='my-image'>
                                            <div className='left'>
                                                <h2>فندق فيرمونت برج الساعه</h2>
                                                <img src={rate_img} />
                                                <p>مكة المكرمة - السعودية - 0.5 كم من مركز المدينة</p>
                                                <h5>
                                                    إقامة فاخرة 
                                                    <i className="fa-solid fa-people-group"></i>
                                                </h5>
                                                <p className='count'>يشاهد هذا الفندق حالياً 26 شخص</p>
                                            </div>

                                            <div className='right'>
                                                <img src={maka_img} alt="Pyramids" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='card'>

                                        <div className='details'>
                                            <i className="fa-solid fa-heart"></i>
                                            <h2>ريال 70</h2>
                                            <p>إجمالي 1 ليلة شامل ضريبة القيمة المضافة</p>
                                            <button>الغرف المتاحة</button>
                                        </div>

                                        <div className='my-image'>
                                            <div className='left'>
                                                <h2>هيلتون رمسيس القاهرة</h2>
                                                <img src={rate_img} />
                                                <p>القاهرة - مصر - 1 كم من مركز المدينة</p>
                                                <h5>
                                                    مناسب للعائلات  
                                                    <i className="fa-solid fa-people-group"></i>
                                                </h5>
                                                <p className='count'>يشاهد هذا الفندق حالياً 29 شخص</p>
                                            </div>

                                            <div className='right'>
                                                <img src={helton_img} alt="Pyramids" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='card'>

                                        <div className='details'>
                                            <i className="fa-solid fa-heart"></i>
                                            <h2>ريال 100</h2>
                                            <p>إجمالي 1 ليلة شامل ضريبة القيمة المضافة</p>
                                            <button>الغرف المتاحة</button>
                                        </div>

                                        <div className='my-image'>
                                            <div className='left'>
                                                <h2>فندق فيرمونت برج الساعه</h2>
                                                <img src={rate_img} />
                                                <p>مكة المكرمة - السعودية - 0.5 كم من مركز المدينة</p>
                                                <h5>
                                                    إقامة فاخرة 
                                                    <i className="fa-solid fa-people-group"></i>
                                                </h5>
                                                <p className='count'>يشاهد هذا الفندق حالياً 26 شخص</p>
                                            </div>

                                            <div className='right'>
                                                <img src={maka_img} alt="Pyramids" />
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>

                                
                            </div>
                        </div>
                    </div>
                    
                    <Footer />
                </div>
            </div>
        </>
    );
};


export default SearchResultHotel;