// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAuth } from "firebase/auth";

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword, GoogleAuthProvider, TwitterAuthProvider } from 'firebase/auth';
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCVMwgESubvBnY4Hh8hhzZFwX7n7cLS294",
  authDomain: "booking-bef87.firebaseapp.com",
  projectId: "booking-bef87",
  storageBucket: "booking-bef87.appspot.com",
  messagingSenderId: "799506966644",
  appId: "1:799506966644:web:f15787af911942fb974921"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);

export const auth = getAuth(app);

export const storage = getStorage(app);

export const provider = new GoogleAuthProvider();

export const twitterProvider = new TwitterAuthProvider();