// SignUp.js
import React, { useEffect, useState } from 'react';
import './style_sign_up.css';
import { 
  createUserWithEmailAndPassword, 
  GoogleAuthProvider, 
  TwitterAuthProvider, 
  signInWithPopup, 
  RecaptchaVerifier, 
  signInWithPhoneNumber,
  confirmOtp,
  fetchSignInMethodsForEmail, 
} from 'firebase/auth';
import { auth, db, provider } from '../../Firebase/firebase';
import { Link, unstable_HistoryRouter, useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import { collection, doc, getDoc, getDocs, query, setDoc, where } from 'firebase/firestore';
import PhoneInput from "react-phone-input-2";
import OtpInput from "otp-input-react";
import Navbar from '../Navbar/Navbar';
import { useTranslation } from 'react-i18next';
import gmail_img from '../images/Gmail.png';
import x_img from '../images/x.png';
import check_animation from '../Animation Icon/lottieflow-success-09-000000-easey.json';
import Error_animation from '../Animation Icon/lottieflow-search-06-000000-easey.json';
import { LottiePlayer } from 'lottie-react';
import { Player } from '@lottiefiles/react-lottie-player';
import AnimationMassage from '../Animation Massage/AnimationMassage';


const SignUp = () => {
  const { t, i18n } = useTranslation();
  const [isButtonColor, setIsButtonColor] = useState(true);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [name, setName] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [verificationId, setVerificationId] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);

  const [verificationCode, setVerificationCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [ph, setPh] = useState("");
  const [checkbox, setCheckbox] = useState('');

  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [user, setUser] = useState(null);

  //console.log(ph);

  const navigate = useNavigate();



  //Animation Massages:-
  const [closeMassage, setCloseMassage] = useState(true);
  const [isMassageError, setIsMassageError] = useState(true);
  const [isNoMobileError, setIsNoMobileError] = useState(true);
  const [isNoVerificationCode, setIsNoVerificationCode] = useState(true);
  const [isNoEmptyFiled, setIsNoEmptyFiled] = useState(true);



  const googleProvider = new GoogleAuthProvider();
  const twitterProvider = new TwitterAuthProvider();


  // Icon Animation:-
  // For Success Massage
  const checkAnimation = () => {
    return (
      <div>
        <Player
          autoplay
          loop
          src={check_animation}
          style={{ height: '300px', width: '300px' }} // يمكنك ضبط الأبعاد حسب الحاجة
        />
      </div>
    );
  };


  // For Error Massage
  const checkAnimationError = () => {
    return (
      <div>
        <Player
          autoplay
          loop
          src={Error_animation}
          style={{ height: '300px', width: '300px' }} // يمكنك ضبط الأبعاد حسب الحاجة
        />
      </div>
    );
  };





  const handleEmailSignUp = async (e) => {
    e.preventDefault();
    try {
        

        // محاولة تسجيل المستخدم باستخدام البريد الإلكتروني وكلمة المرور
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        // For Animation Massage
         setCloseMassage(false);

        // تخزين بيانات المستخدم في Firestore
        await setDoc(doc(collection(db, 'users'), user.uid), {
            email,
            uid: user.uid, // تخزين UID
        });

    } catch (error) {
        // التحقق من نوع الخطأ إذا كان البريد الإلكتروني مستخدم بالفعل
        if (error.code === 'auth/email-already-in-use') {
            //alert('البريد الإلكتروني مستخدم بالفعل. يرجى استخدام بريد إلكتروني آخر.');

            setIsMassageError(false);

        } else {
            console.error('Error signing up with email:', error);
            alert('حدث خطأ أثناء محاولة تسجيل الحساب.');
        }
    }
};



  

const handleGoogleSignUp = async () => {
  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
    //console.log('User Info:', user);

    

    // التحقق مما إذا كان المستخدم موجودًا بالفعل في Firestore
    const userDocRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userDocRef);

    if (!userDoc.exists()) {
      // المستخدم غير موجود في Firestore، لذا نقوم بإنشاء مستند جديد
      await setDoc(userDocRef, {
        email: user.email,
        uid: user.uid, // تخزين UID
      });

      // Show Wellcome Massage
      setCloseMassage(false);

    } else {
      
      navigate('/');
      console.log('User already exists in Firestore');
    }

    

  } catch (error) {
    console.error('Error signing in with Google:', error);
  }
};




  

const handleTwitterSignUp = async () => {
  try {
    const result = await signInWithPopup(auth, twitterProvider);
    const user = result.user;
    //console.log('User Info:', user);

    

    // التحقق مما إذا كان المستخدم موجودًا بالفعل في Firestore
    const userDocRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userDocRef);

    if (!userDoc.exists()) {
      // المستخدم غير موجود في Firestore، لذا نقوم بإنشاء مستند جديد
      await setDoc(userDocRef, {
        email: user.email || null, // في حال عدم وجود بريد إلكتروني من تويتر
        uid: user.uid, // تخزين UID
      });

      setCloseMassage(false);
      //console.log('User added to Firestore');

    } else {
      navigate('/');
      //console.log('User already exists in Firestore');
    }

  } catch (error) {
    console.error('Error signing in with Twitter:', error);
  }
};







// // To Send OTP (Work Very Well)
// function onCaptchaVerify() {
//   if (!window.recaptchaVerifier) {
//       window.recaptchaVerifier = new RecaptchaVerifier(
//           auth,
//           "recaptcha-container",
//           {
//               size: "normal",
//               callback: (response) => {
//                   onSignup();
//               },
//               "expired-callback": () => {},
//           }
//       );
//   }
// }
 
// const onSignup = (e) => {
//   e.preventDefault();
//   setLoading(true);
//   onCaptchaVerify();

//   const appVerifier = window.recaptchaVerifier;
  
//   const formatPh = '+'+ph; 
// //  const formatPh = '+201148063426';  // تنسيق الرقم بشكل صحيح

//   signInWithPhoneNumber(auth, formatPh, appVerifier)
//       .then((confirmationResult) => {
//           window.confirmationResult = confirmationResult;
//           setLoading(false);
//           setShowOTP(true);
//           alert('OTP sent successfully!');
//       }).catch((error) => {
//           console.log(error);
//           setLoading(false);
//       });
// }

// function onOTPVerify() {
//   setLoading(true);
//   window.confirmationResult
//       .confirm(otp)
//       .then(async (res) => {
//           console.log(res);
//           setUser(res.user);
//           setLoading(false);

//           await setDoc(doc(collection(db, 'users'), res.user.uid), {
//               phoneNumber: ph,
//               uid: res.user.uid
//           });

//           navigate('/');
//       })
//       .catch((err) => {
//           console.log(err);
//           setLoading(false);
//       });
// }














// To Send OTP
// function onCaptchaVerify() {
//   if (!window.recaptchaVerifier) {
//     window.recaptchaVerifier = new RecaptchaVerifier(
//       auth,
//       "recaptcha-container",
//       {
//         size: "normal",
//         callback: (response) => {
//           onSignup();
//         },
//         "expired-callback": () => {
//           alert("OTP has expired. Please request a new one.");
//           setShowOTP(false); // إخفاء إدخال OTP
//         },
//       }
//     );
//   }
// }

// const onSignup = (e) => {
//   e.preventDefault();
//   setLoading(true);
//   onCaptchaVerify();

//   const appVerifier = window.recaptchaVerifier;
  
//   const formatPh = '+' + ph; // تأكد من تنسيق الرقم بشكل صحيح

//   signInWithPhoneNumber(auth, formatPh, appVerifier)
//     .then((confirmationResult) => {
//       window.confirmationResult = confirmationResult;
//       setLoading(false);
//       setShowOTP(true);
//       alert('OTP sent successfully!');
//     })
//     .catch((error) => {
//       console.log(error);
//       setLoading(false);
//     });
// };

// // للتحقق من OTP وإضافة المستخدم إلى Firestore
// async function onOTPVerify() {
//   setLoading(true);
//   window.confirmationResult
//     .confirm(otp)
//     .then(async (res) => {
//       console.log(res);
//       setUser(res.user);
//       setLoading(false);

//       // التحقق مما إذا كان المستخدم موجودًا بالفعل في Firestore
//       const userDocRef = doc(db, 'users', res.user.uid);
//       const userDoc = await getDoc(userDocRef);

//       if (!userDoc.exists()) {
//         // المستخدم غير موجود في Firestore، لذا نقوم بإنشاء مستند جديد
//         await setDoc(userDocRef, {
//           phoneNumber: ph,
//           uid: res.user.uid,
//         });
//         console.log('User added to Firestore');
//       } else {
//         console.log('User already exists in Firestore');
//       }

//       navigate('/');
//     })
//     .catch((err) => {
//       console.log(err);
//       setLoading(false);

//       // التحقق من انتهاء صلاحية OTP
//       if (err.code === 'auth/code-expired') {
//         alert('The OTP code has expired. Please request a new one.');
//         setShowOTP(false); // إخفاء إدخال OTP
//       } else {
//         alert('Error verifying OTP. Please try again.');
//       }
//     });
// }

// إعادة إرسال OTP
// const resendOTP = () => {
//   setLoading(true);
//   onCaptchaVerify(); // إعادة إنشاء Recaptcha
//   const appVerifier = window.recaptchaVerifier;

//   signInWithPhoneNumber(auth, formatPh, appVerifier)
//     .then((confirmationResult) => {
//       window.confirmationResult = confirmationResult;
//       setLoading(false);
//       alert('A new OTP has been sent successfully!');
//     })
//     .catch((error) => {
//       console.log(error);
//       setLoading(false);
//       alert('Error resending OTP. Please try again.');
//     });
// };
















// إعداد Recaptcha
// function onCaptchaVerify() {
//   if (!window.recaptchaVerifier) {
//     window.recaptchaVerifier = new RecaptchaVerifier(
//       'recaptcha-container',
//       {
//         size: 'normal',
//         callback: (response) => {
//           onSignup(); // استدعاء التسجيل عند تحقق Recaptcha
//         },
//         'expired-callback': () => {
//           alert('انتهت صلاحية Recaptcha. يرجى المحاولة مرة أخرى.');
//         }
//       },
//       auth
//     );
//   }
// }



// ==================   Test   =====================

  function onCaptchaVerify() {
    if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new RecaptchaVerifier(
            auth,
            "recaptcha-container",
            {
                size: "normal",
                callback: (response) => {
                    onSignup();
                },
                "expired-callback": () => {
                  console.log('انتهت صلاحية الرمز. يرجى المحاولة مرة أخرى.');
                },
            }
        );
    }
  };




// التحقق إذا كان الرقم موجودًا مسبقًا
const checkPhoneExists = async (phoneNumber) => {
  // بدلاً من استخدام fetchSignInMethodsForEmail، تحقق في Firestore
  const usersRef = collection(db, 'users');
  const querySnapshot = await getDocs(usersRef);
  let exists = false;

  console.log(phoneNumber);
  //console.log(doc.data().phoneNumber);

  querySnapshot.forEach((doc) => {
    if (`+${doc.data().phoneNumber}` === phoneNumber) {

      exists = true;
    }
  });

  return exists;
};

// دالة التسجيل
const onSignup = async (e) => {
  //e.preventDefault();  // تأكد من منع إعادة تحميل الصفحة

  if(!ph || !checkbox) {
    //alert('Select All');
    setIsNoEmptyFiled(false);

  } else {

    try {
      setLoading(true);
      
      const formatPh = '+' + ph;  // تنسيق رقم الهاتف
      
      // التحقق من أن الرقم غير موجود مسبقاً
      const phoneExists = await checkPhoneExists(formatPh);
      if (phoneExists) {
        //alert('رقم الهاتف موجود مسبقاً!');

        setIsNoMobileError(false);

        setLoading(false);
        return;
      }
  
      onCaptchaVerify();  // التحقق من Recaptcha
      const appVerifier = window.recaptchaVerifier;
  
      await signInWithPhoneNumber(auth, formatPh, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          setLoading(false);
          setShowOTP(true);
          //alert('تم إرسال الرمز بنجاح!');

          // For Animation Massage
          setIsNoVerificationCode(false);

        })
        .catch((error) => {
          console.error('Error during OTP sending:', error);
          setLoading(false);
        });
  
    } catch (error) {
      console.error('Error during sign-up:', error);
      setLoading(false);
    }

  };

  
};




// دالة التحقق من الرمز OTP
const onOTPVerify = async () => {
  setLoading(true);
  try {
    const result = await window.confirmationResult.confirm(otp);
    setUser(result.user);
    setLoading(false);

    // تخزين بيانات المستخدم في Firestore
    await setDoc(doc(collection(db, 'users'), result.user.uid), {
      phoneNumber: ph,
      uid: result.user.uid
    });

    //alert('تم التسجيل بنجاح!');

    // For Animation Massage
    setCloseMassage(false);

  } catch (error) {
    console.error('Error during OTP verification:', error);
    setLoading(false);
    alert('الرمز غير صحيح أو انتهت صلاحيته.');
  }
};









// =============   Test 2 ===================
// function onCaptchaVerify() {
//   if (!window.recaptchaVerifier) {
//     window.recaptchaVerifier = new RecaptchaVerifier(
//       "recaptcha-container",
//       {
//         size: "normal",
//         callback: (response) => {
//           // يتم استدعاء هذه الدالة عند النجاح في التحقق من Recaptcha
//           console.log("Recaptcha verified successfully.");
//         },
//         "expired-callback": () => {
//           // يتم استدعاء هذه الدالة عند انتهاء صلاحية Recaptcha
//           console.log('انتهت صلاحية الرمز. يرجى المحاولة مرة أخرى.');
//           alert("صلاحية Recaptcha انتهت. يرجى المحاولة مرة أخرى.");
//           // إعادة تهيئة Recaptcha
//           onCaptchaVerify();
//         },
//       },
//       auth
//     );
//   } else {
//     window.recaptchaVerifier.render().then(function(widgetId) {
//       grecaptcha.reset(widgetId); // إعادة تعيين Recaptcha إذا كان موجودًا مسبقًا
//     });
//   }
// }





// const onSignup = async (e) => {
//   //e.preventDefault();  // تأكد من منع إعادة تحميل الصفحة
  
//   if (!ph || !checkbox) {
//     setIsNoEmptyFiled(false);
//   } else {
//     try {
//       setLoading(true);
      
//       const formatPh = '+' + ph;  // تنسيق رقم الهاتف
      
//       // التحقق من أن الرقم غير موجود مسبقاً
      
//       const phoneExists = await checkPhoneExists(formatPh);
//       if (phoneExists) {
//         setIsNoMobileError(false);
//         setLoading(false);
//         return;
//       }
  
//       onCaptchaVerify();  // التحقق من Recaptcha
//       const appVerifier = window.recaptchaVerifier;
  
//       await signInWithPhoneNumber(auth, formatPh, appVerifier)
//         .then((confirmationResult) => {
//           window.confirmationResult = confirmationResult;
//           setLoading(false);
//           setShowOTP(true);
//         })
//         .catch((error) => {
//           console.error('Error during OTP sending:', error);
//           setLoading(false);
//         });
  
//     } catch (error) {
//       console.error('Error during sign-up:', error);
//       setLoading(false);
//     }
//   }
// };







// const onOTPVerify = async () => {
//   setLoading(true);
//   try {
//     const result = await window.confirmationResult.confirm(otp);
//     setUser(result.user);
//     setLoading(false);

//     // تخزين بيانات المستخدم في Firestore
//     await setDoc(doc(collection(db, 'users'), result.user.uid), {
//       phoneNumber: ph,
//       uid: result.user.uid
//     });

//     setCloseMassage(false);

//   } catch (error) {
//     console.error('Error during OTP verification:', error);
//     setLoading(false);
//     alert('الرمز غير صحيح أو انتهت صلاحيته.');
//   }
// };












// Our SMS Compony
// function generateOtp() {
//   // توليد كود مكون من 6 أرقام
//   return Math.floor(100000 + Math.random() * 900000).toString();
// }

// function onCaptchaVerify() {
//   if (!window.recaptchaVerifier) {
//     window.recaptchaVerifier = new RecaptchaVerifier(
//       auth,
//       "recaptcha-container",
//       {
//         size: "normal",
//         callback: (response) => {
//           onSignup();  // عند النجاح في التحقق من الـ recaptcha يتم استدعاء دالة التسجيل
//         },
//         "expired-callback": () => {
//           alert('Recaptcha expired, please try again');
//         },
//       }
//     );
//   }
// }

// const onSignup = async (e) => {
//   e.preventDefault();
//   setLoading(true);

//   // التحقق من recaptcha
//   onCaptchaVerify();  

//   // توليد كود OTP
//   const otpCode = generateOtp();
//   const formatPh = '+' + ph;  // تنسيق رقم الهاتف بشكل صحيح

//   try {
//     // استدعاء API الخاص بـ Our SMS لإرسال الـ OTP
//     const response = await fetch('https://api.oursms.com/msgs/sms', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': 'Bearer 6c9IeolZrvtfNwVjTlip',  // ضع مفتاح الـ API هنا
//       },
//       body: JSON.stringify({
//         src: 'TOAT',  // اسم المرسل
//         body: `Your OTP code is: ${otpCode}. For your security and safety, please don't share the code. Happy time with TOAT :)`,  // الرسالة التي يتم إرسالها للمستخدم
//         dests: [formatPh]  // رقم الهاتف كقائمة
//       }),
//     });

//     const data = await response.json();

//     if (response.ok) {
//       setLoading(false);
//       setShowOTP(true);  // إظهار واجهة إدخال الـ OTP
//       alert('OTP sent successfully!');
//       window.otpCode = otpCode;  // حفظ كود الـ OTP الذي تم إرساله
//     } else {
//       throw new Error(data.message || 'Failed to send OTP');
//     }
//   } catch (error) {
//     console.error(error);
//     alert('Error sending OTP. Please try again.');
//     setLoading(false);
//   }
// };

// // وظيفة التحقق من OTP
// function onOTPVerify() {
//   setLoading(true);

//   // التحقق من صحة الـ OTP الذي أدخله المستخدم
//   if (otp === window.otpCode) {
//     // المستخدم أدخل الكود بشكل صحيح
//     setUser({
//       phoneNumber: ph,
//     });
//     setLoading(false);

//     // حفظ بيانات المستخدم في Firestore
//     setDoc(doc(collection(db, 'users'), user.uid), {
//       phoneNumber: ph,
//       uid: user.uid
//     });

//     // إعادة التوجيه إلى الصفحة الرئيسية
//     navigate('/');
//   } else {
//     alert('Incorrect OTP');
//     setLoading(false);
//   }
// }





  // For Amimation Massage:-
  const myAnimationMassage = (textMassage, animationIcon, myNavigate)=> {
    return(
      <>
        <div className='my-massage'>
                                          
            <h3>
            
              {animationIcon}

              <br />
              {t(textMassage)}

            </h3>

            <button onClick={()=> {
              setCloseMassage(true);
              setIsMassageError(true);
              setIsNoMobileError(true);
              setIsNoVerificationCode(true);
              setIsNoEmptyFiled(true);

              navigate(myNavigate);

            }}>{t('موافق')}</button>

        </div>
      </>
    );
  };




  return (
    <div className="signup-container">

          {/* Start Massages */}
          <div className='success-message' style={closeMassage === true ? {display: 'none'} : {display: 'flex'}}>
                                
                      {myAnimationMassage('"تم تسجيلك بنجاح"', checkAnimation(), '/')}
                  
          </div>


          {/* For Error Massage */}
          <div className='success-message' style={isMassageError === true ? {display: 'none'} : {display: 'flex'}}>
                                
                      {myAnimationMassage('"البريد الإلكتروني مستخدم بالفعل. يرجى استخدام بريد إلكتروني آخر"', checkAnimationError(), '/SignUp')}
                  
          </div>


          {/* For Mobile Error Massage */}
          <div className='success-message' style={isNoMobileError === true ? {display: 'none'} : {display: 'flex'}}>
                                
                      {myAnimationMassage('"رقم الهاتف موجود مسبقاً ، من فضلك استخدم رقم اخر"', checkAnimationError(), '/SignUp')}
                  
          </div>


          {/* For Virfication Code Massage */}
          <div className='success-message' style={isNoVerificationCode === true ? {display: 'none'} : {display: 'flex'}}>
                                
                      {myAnimationMassage('"تم إرسال الرمز بنجاح ، الرجاء استخدامة فى اقل من دقيقة"', checkAnimation(), null)}
                  
          </div>



          {/* For Empty Filed Massage */}
          <div className='success-message' style={isNoEmptyFiled === true ? {display: 'none'} : {display: 'flex'}}>
                                
                      {myAnimationMassage('"من فضلك ادخل رقم الهاتف بالكود الدولى ثم الموافقه على الشروط و الاحكام"', checkAnimationError(), null)}
                  
          </div>

          {/* End Massages */}
      
      <div className='my-navbar'>
        <Navbar />
      </div>

      <div className='container'>
            <div className='head'>
              <h1>إنشاء حساب جديد</h1>
              <div className='btn'>
                  <button className='email' onClick={()=> {setIsButtonColor(true)}} style={isButtonColor === true ? {backgroundColor: '#5A64A8'} : {backgroundColor: 'transparent'}}>
                      <i className="fa-brands fa-google"></i>
                      {t('بريد إلكتروني')}
                  </button>
                  <button className='mobile' onClick={()=> {setIsButtonColor(false)}} style={isButtonColor === false ? {backgroundColor: '#5A64A8'} : {backgroundColor: 'transparent'}}>
                      <i className="fa-solid fa-mobile-screen-button"></i>
                      {t('جوال')}
                  </button>
              </div>
            </div>

            {
              isButtonColor ? (
                <div className='my-option'>
                  <form className='form-email' onSubmit={handleEmailSignUp}>
                        <label htmlFor="">البريد الإلكتروني</label>
                        <input
                          type="email"
                          placeholder="البريد الإلكتروني"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />

                        <label htmlFor="">كلمة المرور</label>
                        <input
                          type="password"
                          placeholder="كلمة المرور"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />

                        <div className='options'>
                              <div className='my-checkbox'>
                                  <h5>اوافق على الشروط و الاحكام</h5>
                                  <input className='checkbox' type='checkbox' required/>
                              </div>

                              <h5 className='sign-now'>
                              هل لديك حساب ؟
                              <Link to='/Login'>سجل دخولك الان</Link>
                              </h5>
                        </div>

                        <button type="submit">إنشاء حساب جديد</button>
                </form>

                  <div className='google-and-twitter'>
                      <div className='my-head'>
                          <span></span>
                          <h1>{t('او عن طريق')}</h1>
                          <span></span>
                      </div>

                      <div className='img-platform'>
                          <img src={gmail_img} onClick={handleGoogleSignUp} />
                          <img src={x_img} onClick={handleTwitterSignUp} />
                      </div>
                  </div>
                </div>
              ) : (
                
                <div className='my-option'>

                    {/* <section className='my-phone-section'>
                          <div>
                            
                            
                            {user ? (
                              
                              <></>
                              
                            ) : (
                              <div className='telephon'>
                                
                                {showOTP ? (
                                  <>
                                    <div>
                                      
                                    </div>
                                    <label
                                      htmlFor="otp"
                                      
                                    >
                                      الرجاء إدخال الرمز
                                    </label>
                                    <OtpInput
                                      value={otp}
                                      onChange={setOtp}
                                      OTPLength={6}
                                      otpType="number"
                                      disabled={false}
                                      autoFocus
                                      className="opt-container"
                                    ></OtpInput>
                                    <button
                                      onClick={onOTPVerify}
                                      
                                    >
                                      {loading && (
                                        // <CgSpinner size={20} className="mt-1 animate-spin" />
                                        // <p>Hello From Spinner</p>
                                        <></>
                                      )}
                                      <span>تسجيل الدخول</span>
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <form onSubmit={onSignup}>
                                          <div>
                                            
                                          </div>

                                          <label htmlFor="">رقم الجوال</label>
                                          <input type='tel' placeholder='رقم الجوال' onChange={(e)=> {setPh(e.target.value)}} required/>

                                          <div className='rols'>
                                                <div className='my-checkbox'>
                                                    <h5>اوافق علي الشروط و الاحكام</h5>
                                                    <input className='checkbox' type='checkbox' required/>
                                                </div>

                                                <h5 className='sign-now'>
                                                هل لديك حساب ؟
                                                <Link to='/Login'>سجل دخولك الان</Link>
                                                </h5>
                                          </div>

                                          <div id="recaptcha-container"></div>

                                          <div className='PhoneInput'>
                                              
                                          </div>
                                          

                                          
                                          <button type='submit'
                                            //onClick={onSignup}
                                            
                                          >
                                            {loading && (
                                              // <CgSpinner size={20}  />
                                              // <p>Hello From Spinner</p>
                                              <></>
                                            )}
                                            <span>إرسال الرمز</span>
                                          </button>
                                    </form>
                                    
                                  </>
                                )}
                              </div>
                            )}
                          </div>
                    </section> */}

                  
                    {/* Test */}
                    <section className="my-phone-section">
                          <div>
                            {user ? (
                              <></> // إذا تم تسجيل المستخدم بنجاح
                            ) : (
                              <div className="telephon">
                                {showOTP ? (
                                  <>
                                    <div>
                                      <label htmlFor="otp">الرجاء إدخال الرمز</label>
                                      <OtpInput
                                        value={otp}
                                        onChange={setOtp}
                                        OTPLength={6}
                                        otpType="number"
                                        disabled={false}
                                        autoFocus
                                        className="opt-container"
                                      />
                                      <button onClick={onOTPVerify}>
                                        {loading ? <span>جارٍ التحقق...</span> : <span>تسجيل الدخول</span>}
                                      </button>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div>  {/* يتم تمرير الحدث هنا بشكل صحيح */}
                                        <label htmlFor="phone">رقم الجوال</label>
                                        <input
                                          type="tel"
                                          placeholder="رقم الجوال"
                                          value={ph}  // تأكد من وجود قيمة الهاتف هنا
                                          onChange={(e) => setPh(e.target.value)}  // التحقق من أن قيمة الهاتف يتم حفظها
                                          required
                                        />
                                        
                                        <div className="rols">
                                          <div className="my-checkbox">
                                            <h5>أوافق على الشروط والأحكام</h5>
                                            <input className="checkbox" type="checkbox" onChange={(e)=> {setCheckbox(e.target.checked)}} required />
                                          </div>

                                          <h5 className="sign-now">
                                            هل لديك حساب؟
                                            <Link to="/Login">سجل دخولك الآن</Link>
                                          </h5>
                                        </div>

                                        <div id="recaptcha-container"></div>  {/* تأكد من عمل Recaptcha بشكل صحيح */}

                                        <button onClick={onSignup}>
                                          {loading ? <span>جارٍ الإرسال...</span> : <span>إرسال الرمز</span>}
                                        </button>
                                    </div>

                                  </>
                                )}
                              </div>
                            )}
                          </div>
                    </section>






                  <div className='google-and-twitter'>
                      <div className='my-head'>
                          <span></span>
                          <h1>{t('او عن طريق')}</h1>
                          <span></span>
                      </div>

                      <div className='img-platform'>
                          <img src={gmail_img} onClick={handleGoogleSignUp} />
                          <img src={x_img} onClick={handleTwitterSignUp} />
                      </div>
                  </div>
                </div>
              )
            }

        </div>

      
    </div>
  );
};

export default SignUp;
