import './style_animation_massage.css';
import { useTranslation } from 'react-i18next';


function AnimationMassage({textMassage, animationIcon}) {
    return(
        <>
            <div className='my-animation-massage'>
                <div className='container'>
                    
                </div>
            </div>
        </>
    );
};


export default AnimationMassage;