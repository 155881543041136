import './style_more_hotel.css';
import helton_img from '../../images/Helton.png';
import maka_img from '../../images/Maka.png';
import dubi_img from '../../images/Dubi.png';
import istanbol_img from '../../images/Istanbol.png';
import athena_img from '../../images/Athena.png';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

function MoreHotel({isArabicLange}) {
    //console.log(isArabicLange);

    const hotelCardRef = useRef(null);

    const handelScrollRight = ()=> {
        hotelCardRef.current.style.scrollBehavior = "smooth";
        hotelCardRef.current.scrollLeft += 200; 
    };

    const handelScrollLeft = ()=> {
        hotelCardRef.current.style.scrollBehavior = "smooth";
        hotelCardRef.current.scrollLeft -= 200; 
    };

    const { t, i18n } = useTranslation();

    return(
        <>
            <div className='my-more-hotel'>
                <div className='container'>
                    <div className='head'>
                        <span></span>
                        <h1>{t('فنادق مختارة من اجل راحتك')}</h1>
                        <i className="fa-solid fa-hotel"></i>
                        <span></span>
                    </div>
 
                    <div className='hotel-card-container'>
                        {/* Start Card Hotel */}
                        <i className="fa-solid fa-circle-arrow-left" style={isArabicLange === true ? {rotate: '0deg'} : {rotate: '180deg'}} onClick={handelScrollLeft}></i>

                        <div className='hotel-card' ref={hotelCardRef}>
                            <div className='content'>
                                <img src={helton_img} alt="helton" />
                                <div className='card'>
                                    <h3>{t('مصر')}</h3>
                                    <p>{t('هيلتون القاهرة')}</p>
                                </div>
                            </div>

                            <div className='content'>
                                <img src={dubi_img} alt="dubi" />
                                <div className='card'>
                                    <h3>{t('دبي')}</h3>
                                    <p>{t('منتجع اتلانس ذا رويل')}</p>
                                </div>
                            </div>

                            <div className='content'>
                                <img src={maka_img} alt="maka" />
                                <div className='card'>
                                    <h3>{t('مكة المكرمة')}</h3>
                                    <p>{t('فندق فيرمونت برج الساعه')}</p>
                                </div>
                            </div>

                            <div className='content'>
                                <img src={istanbol_img} alt="istanbol" />
                                <div className='card'>
                                    <h3>{t('اسطنبول')}</h3>
                                    <p>{t('دبل تري باي هيلتون اسطنبول')}</p>
                                </div>
                            </div>

                            <div className='content'>
                                <img src={athena_img} alt="athena" />
                                <div className='card'>
                                    <h3>{t('اليونان')}</h3>
                                    <p>{t('منتجع أثينا ريفييرا')}</p>
                                </div>
                            </div>

                            {/* <i className="fa-solid fa-circle-arrow-right"></i> */}
                        </div>

                        <i className="fa-solid fa-circle-arrow-right" style={isArabicLange === true ? {rotate: '0deg'} : {rotate: '180deg'}} onClick={handelScrollRight}></i>
                        {/* End Card Hotel */}
                    </div>

                    <div className='explore-more'>
                        <button>
                            {t('اكتشف المزيد من الفنادق')}
                            <i className="fa-solid fa-arrow-right-long"></i>
                            
                        </button>
                    </div>

                    
                </div>
            </div>
        </>
    );
}; 


export default MoreHotel;