import './style_offers.css';
import Navbar from '../../Navbar/Navbar';
import baly_img from '../../images/baly_island.png';
import Footer from '../Footer';
import { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import Loading from '../../Loading/Loading';
import { db } from '../../../Firebase/firebase';
import { Link } from 'react-router-dom';

function Offers() {
    const [myData, setMyData] = useState([]);
    console.log(myData);

    const [isLoading, setIsLoading] = useState(true);

    // To sort data and show it:-
    const [sordData, setSortData] = useState('1');
    console.log(sordData);

    useEffect(()=> {

        const fetchData = async()=> {

            const getMyData = await getDocs(collection(db, 'myOffers'));
            const dataList = getMyData.docs.map((e)=> ({
                ...e.data(),
                id: e.id,
            }));

            
            if(sordData === '1') {
                 // 1. sort data from New To Old
                dataList.sort((a, b) => {
                    return b.TimeStamp.toDate() - a.TimeStamp.toDate();
                });

                setMyData(dataList);
                setIsLoading(false);

            } else if(sordData === '2') {
                // 2. sort data from Old To New
               dataList.sort((a, b) => {
                   return a.TimeStamp.toDate() - b.TimeStamp.toDate();
               });

               setMyData(dataList);
               setIsLoading(false);

            } else if (sordData === '3') {
                // 3. Sort data from Highest Rate to Lowest Rate
                dataList.sort((a, b) => b.rate - a.rate);

                setMyData(dataList);
                setIsLoading(false);

            } else if (sordData === '4') {
                // 4. Sort data from Lowest Rate to Highest Rate
                dataList.sort((a, b) => a.rate - b.rate);

                setMyData(dataList);
                setIsLoading(false);
            }
            
            
        };

        fetchData();
    }, [sordData]);



    // To Rate In Data (Show Stars)
    const dataRate = (rate)=> {
        if(rate === '5') {
            return(
                <>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                </>
            );

        } else if(rate === '4') {
            return(
                <>
                    <i className="fa-solid fa-star no-rate"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                </>
            );

        } else if(rate === '3') {
            return(
                <>
                    <i className="fa-solid fa-star no-rate"></i>
                    <i className="fa-solid fa-star no-rate"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                </>
            );

        } else if(rate === '2') {
            return(
                <>
                    <i className="fa-solid fa-star no-rate"></i>
                    <i className="fa-solid fa-star no-rate"></i>
                    <i className="fa-solid fa-star no-rate"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                </>
            );

        } else if(rate === '1') {
            return(
                <>
                    <i className="fa-solid fa-star no-rate"></i>
                    <i className="fa-solid fa-star no-rate"></i>
                    <i className="fa-solid fa-star no-rate"></i>
                    <i className="fa-solid fa-star no-rate"></i>
                    <i className="fa-solid fa-star"></i>
                </>
            );

        }
    };



    if(isLoading) {
        return(
            <Loading />
        )

    } else {
        return(
            <>
                <div className='my-offers'>
                    <div className='container'>
                        <Navbar />
    
                        <div className='hero'>
                            <h1>عروض على هواك</h1>
                            <hr />
                        </div>
        
                        <div className='content'>
                            <div className='head'>
                                <select onChange={(e)=> {setSortData(e.target.value)}}>
                                    <option value="1">الأحدث</option>
                                    <option value="2">الأقدم</option>
                                    <option value="3">الأعلى تقيم</option>
                                    <option value="4">الأقل تقيم</option>
                                </select>
    
                                <label htmlFor="">رتب حسب</label>
                            </div>
    
                            <div className='my-cards'>

                                {
                                    myData.map((e)=> {
                                        return(
                                            <div className='card' key={e.id}>
                                                
                                                <Link to={`/Offers-Details/${e.id}`}>
                                                    <img src={e.imageUrl} />
                                                </Link>
                
                                                <h2>{e.title}</h2>
                                                <p>{e.content}</p>
                
                                                <div className='rate'>
                                                    {dataRate(e.rate)}
                
                                                    <span>التقيم</span>
                                                </div>

                                                <button onClick={()=> {window.location.href = e.myLink}}>شاهد العرض</button>
                
                                                <Link to={`/Offers-Details/${e.id}`}>
                                                    <button>تفاصيل العرض</button>
                                                </Link>
                                            </div>
                                        )
                                    })
                                }
                                
                            </div>
                        </div>
    
                        <Footer />
                    </div>
                </div>
            </>
        );
    }

};


export default Offers;