import Navbar from '../Navbar/Navbar';
import './style_search_result_flight.css';
import plane_img from '../images/plane.png';
import line_img from '../images/line.png';
import Emirates_img from '../images/Emirates.png';
import sudia_img from '../images/sudia.png';
import { useState } from 'react';
import Footer from '../Footer/Footer';
import { Link } from 'react-router-dom';

function SearchResultFlight() {
    const [priceRange, setPriceRange] = useState();

    const [isFilterShow, setIsFilterShow] = useState(true);

    return(
        <>
            <div className='my-search-result-flight'>
                <div className='container'>
                    <Navbar />

                    <div className='this-search-flight'>
                        <h1>This Search Engine</h1>
                    </div>

                    <div className='head'>
                        <div className='top'>
                            <div className='left'>
                                <img src={plane_img} />
                            </div>

                            <div className='right'>
                                <h2>نتائج البحث : (10) رحلات </h2>
                                <p>تتوفر خيارات سفر مريحة و ملائمة لجميع احتياجاتك</p>
                            </div>
                        </div>

                        <div className='down'>
                            <div className='info'>
                                <p>المقصورة</p>
                                <h3>السياحية</h3>
                            </div>

                            <span></span>

                            <div className='info'>
                                <p>المسافرين</p>
                                <h3>4</h3>
                            </div>

                            <span></span>

                            <div className='info'>
                                <p>العودة</p>
                                <h3>7/9</h3>
                            </div>

                            <i className="fa-solid fa-arrows-left-right"></i>

                            <div className='info'>
                                <p>المغادرة</p>
                                <h3>1/9</h3>
                            </div>

                            <span></span>

                            <div className='info'>
                                <p>إلي</p>
                                <h3>دبي</h3>
                            </div>

                            <i className="fa-solid fa-arrows-left-right"></i>

                            <div className='info'>
                                <p>من</p>
                                <h3>القاهرة</h3>
                            </div>

                        </div>

                        {/* For search result flight */}
                        {/* <div className='title'>
                            <p>رحلة العودة</p>
                            <p className='circle'>3</p>
                            <hr />

                            <p>رحله الذهاب</p>
                            <p className='circle'>2</p>
                            <hr />

                            <p>اختر وجهتك</p>
                            <i className="fa-solid fa-circle-check"></i>
                        </div> */}

                        {/* For back tripe */}
                        <div className='title'>
                            <p>رحلة العودة</p>
                            <p className='circle'>3</p>
                            <hr />

                            <p>رحله الذهاب</p>
                            <i className="fa-solid fa-circle-check"></i>
                            <hr />

                            <p>اختر وجهتك</p>
                            <i className="fa-solid fa-circle-check"></i>
                        </div>

                        <div className='arrange'>
                            <button>الاسرع</button>
                            <button>الارخص</button>
                            <button>الافضل</button>

                            <h2>رتب حسب</h2>
                        </div>
                    </div>

                    <div className='content'>
                        <div className='left'>

                            <div className='show-hide-filter'>
                                <button onClick={()=> {setIsFilterShow(!isFilterShow)}}>{isFilterShow ? 'إخفاء الفلتر' : 'إظهار الفلتر'}</button>
                            </div>

                            <div className='my-filter' style={isFilterShow === false ? {display: 'none'} : null}>
                                {/* Time */}
                                <div className='rate-hotel'>
                                    <h2>
                                        الوقت   
                                        <i className="fa-regular fa-clock"></i>
                                    </h2>

                                    <div className='my-content'>
                                        <h4>وقت الرحيل من القاهرة</h4>
                                        <div className='my-clock'>
                                            <p>الصباح الباكر (00: 00 - 5:59)</p>
                                            <input type='checkbox' />
                                        </div>

                                        <div className='my-clock'>
                                            <p>الصباح (00: 06 - 11:59)</p>
                                            <input type='checkbox' />
                                        </div>

                                        <div className='my-clock'>
                                            <p>بعد الظهر (00: 12 - 17:59)</p>
                                            <input type='checkbox' />
                                        </div>

                                        <div className='my-clock'>
                                            <p>المساء (00: 18 - 23:59)</p>
                                            <input type='checkbox' />
                                        </div>
                                    </div>
                                    
                                    <div className='my-content'>
                                        <h4>وقت الوصول إلي دبي</h4>
                                        <div className='my-clock'>
                                            <p>الصباح الباكر (00: 00 - 5:59)</p>
                                            <input type='checkbox' />
                                        </div>

                                        <div className='my-clock'>
                                            <p>الصباح (00: 06 - 11:59)</p>
                                            <input type='checkbox' />
                                        </div>

                                        <div className='my-clock'>
                                            <p>بعد الظهر (00: 12 - 17:59)</p>
                                            <input type='checkbox' />
                                        </div>

                                        <div className='my-clock'>
                                            <p>المساء (00: 18 - 23:59)</p>
                                            <input type='checkbox' />
                                        </div>
                                    </div>
                                    
                                </div>

                                <hr />

                                {/* Price Range */}
                                <div className='price'>
                                    <h2>
                                        نطاق السعر   
                                        <i className="fa-solid fa-dollar-sign"></i>
                                    </h2>

                                    <div className='my-content'>
                                        <p>
                                            {priceRange}
                                            <br />
                                            ريال    
                                        </p>
                                        <input type='range' min={50} max={5000} onChange={(e)=> {setPriceRange(e.target.value)}}/>
                                        <p>5000 ريال</p>
                                    </div>
                                </div>

                                <hr />

                                {/* Stop */}
                                <div className='rate-hotel'>
                                    <h2>
                                        التوقفات   
                                        <i className="fa-solid fa-plane"></i>
                                    </h2>

                                    <div className='my-content-flight'>
                                        <div className='left'>
                                            <p>420</p>
                                        </div>

                                        <div className='right'>
                                            <p>رحلات مباشرة فقط</p>
                                            <input type='checkbox' />
                                        </div>
                                    </div>

                                    <div className='my-content-flight'>
                                        <div className='left'>
                                            <p>316</p>
                                        </div>

                                        <div className='right'>
                                            <p>1 نقطة توقف</p>
                                            <input type='checkbox' />
                                        </div>
                                    </div>

                                    
                                </div>

                                <hr />

                                {/* money back */}
                                <div className='rate-hotel'>
                                    <h2>
                                        قابلية الاسترداد  
                                        <i className="fa-solid fa-rotate"></i>
                                    </h2>

                                    <div className='my-content-flight'>
                                        <div className='left'>
                                            <p>420</p>
                                        </div>

                                        <div className='right'>
                                            <p>قابله للاسترداد</p>
                                            <input type='checkbox' />
                                        </div>
                                    </div>

                                    <div className='my-content-flight'>
                                        <div className='left'>
                                            <p>320</p>
                                        </div>

                                        <div className='right'>
                                            <p>غير قابله للاسترداد</p>
                                            <input type='checkbox' />
                                        </div>
                                    </div>

                                </div>

                                <hr />

                                {/* journy time */}
                                <div className='price'>
                                    <h2>
                                        مدة الرحلة  
                                        <i className="fa-solid fa-hourglass-half"></i>
                                    </h2>

                                    <div className='my-content'>
                                        <p>
                                            {/* {priceRange} */}
                                            <br />
                                            9 h 50m  
                                        </p>
                                        <input type='range' min={3.50} max={9}/>
                                        <p>3 h 50m</p>
                                    </div>
                                </div>

                                {/* Waiting Time */}
                                <div className='price'>
                                    <h2>
                                        مدة الانتظار  
                                        <i className="fa-solid fa-hourglass-half"></i>
                                    </h2>

                                    <div className='my-content'>
                                        <p>
                                            {/* {priceRange} */}
                                            <br />
                                            9 h 50m  
                                        </p>
                                        <input type='range' min={0} max={9}/>
                                        <p>3 h 50m</p>
                                    </div>
                                </div>

                                <hr />

                                {/* Air line */}
                                <div className='rate-hotel'>
                                    <h2>
                                        خطوط الطيران 
                                        <i className="fa-solid fa-plane-departure"></i>
                                    </h2>

                                    <div className='my-content-flight'>
                                        <div className='left'>
                                            <img src={Emirates_img} />
                                        </div>

                                        <div className='right'>
                                            <p>الخطوط الإماراتية</p>
                                            <input type='checkbox' />
                                        </div>
                                    </div>

                                    <div className='my-content-flight'>
                                        <div className='left'>
                                            <img src={sudia_img} />
                                        </div>

                                        <div className='right'>
                                            <p>الخطوط السعودية</p>
                                            <input type='checkbox' />
                                        </div>
                                    </div>

                                    <div className='show-all'>
                                        <i className="fa-solid fa-angle-down"></i>
                                        <a href="">عرض الكل</a>
                                    </div>

                                </div>

                                <hr />

                                {/* Airboart */}
                                <div className='rate-hotel'>
                                    <h2>
                                        المطارات   
                                        <i className="fa-solid fa-cart-flatbed-suitcase"></i>
                                    </h2>

                                    <div className='my-content'>
                                        <h4>مطار المغادرة من القاهرة</h4>

                                        <div className='my-clock'>
                                            <p>مطار القاهرة الدولي</p>
                                            <input type='checkbox' />
                                        </div>

                                        <div className='my-clock'>
                                            <p>مطار سفنكس الدولي</p>
                                            <input type='checkbox' />
                                        </div>
                                    </div>
                                    
                                    <div className='my-content'>
                                        <h4>مطار الوصول إلى دبي</h4>

                                        <div className='my-clock'>
                                            <p>مطار دبي الدولي</p>
                                            <input type='checkbox' />
                                        </div>

                                        <div className='my-clock'>
                                            <p>مطار دبي للباصات</p>
                                            <input type='checkbox' />
                                        </div>
                                    </div>
                                    
                                </div>

                                <hr />

                                {/* Waiting Airboart */}
                                <div className='rate-hotel'>
                                    <h2>
                                        مطارات الانتظار   
                                        <i className="fa-solid fa-spinner"></i>
                                    </h2>

                                    <div className='my-content'>

                                        <div className='my-clock'>
                                            <p>مطار الملك بعد العزيز الدولي</p>
                                            <input type='checkbox' />
                                        </div>

                                        <div className='my-clock'>
                                            <p>مطار الملك خالد الدولي</p>
                                            <input type='checkbox' />
                                        </div>

                                        <div className='show-all'>
                                            <i className="fa-solid fa-angle-down"></i>
                                            <a href="">عرض الكل</a>
                                        </div>
                                    </div>
                                    
                                    
                                    
                                </div>
                                
                            </div>
                        </div>

                        <div className='right'>

                            <div className='my-hotels'>
                                
                                <div className='my-content'>
                                    {/* Start For back tripe */}
                                    {/* <div className='go-tripe'>
                                        <div className='go-head'>
                                            <button>تغير</button>

                                            <div className='choose-tripe'>
                                                <p>2024/9/9 الجمعة</p>
                                                <span></span>
                                                <h3>رحلة الذهاب المختارة</h3>
                                            </div>
                                        </div>

                                        <div className='card-go-tripe'>
                                            <div className='top'>

                                                <div className='time'>
                                                    <h3>09:55</h3>
                                                    <p>السبت 9/10</p>
                                                    <h4>دبي</h4>
                                                    <span className='hide-avalable-sites'>4 مقاعد متاحة</span>
                                                </div>

                                                <div className='line-flight'>
                                                    <p>1 stop</p>
                                                    <img src={line_img} />
                                                    <p>9 h 50m</p>
                                                </div>

                                                <div className='time'>
                                                    <h3>09:55</h3>
                                                    <p>السبت 9/10</p>
                                                    <h4>القاهرة</h4>
                                                    <span>4 مقاعد متاحة</span>
                                                </div>

                                                <span className='v-line'></span>

                                                <div className='logo-flight'>
                                                    <img src={Emirates_img} />
                                                    <p>الخطوط الاماراتية</p>
                                                    <span>الدرجة الاقتصادية</span>
                                                </div>

                                            </div>

                                            <div className='details'>
                                                <i className="fa-solid fa-chevron-down"></i>
                                                <h4>تفاصيل الرحلة</h4>
                                            </div>

                                            <hr />

                                            <div className='down'>
                                                

                                                <div className='right'>
                                                    <h4>إجمالي المبلغ (للذهاب و العودة)</h4>
                                                    <h3>970 ريال</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* End For back tripe */}

                                    <div className='card'>
                                        <div className='top'>

                                            <div className='time'>
                                                <h3>09:55</h3>
                                                <p>السبت 9/10</p>
                                                <h4>دبي</h4>
                                                <span className='hide-avalable-sites'>4 مقاعد متاحة</span>
                                            </div>

                                            <div className='line-flight'>
                                                <p>1 stop</p>
                                                <img src={line_img} />
                                                <p>9 h 50m</p>
                                            </div>

                                            <div className='time'>
                                                <h3>09:55</h3>
                                                <p>السبت 9/10</p>
                                                <h4>القاهرة</h4>
                                                <span>4 مقاعد متاحة</span>
                                            </div>

                                            <span className='v-line'></span>

                                            <div className='logo-flight'>
                                                <img src={Emirates_img} />
                                                <p>الخطوط الاماراتية</p>
                                                <span>الدرجة الاقتصادية</span>
                                            </div>

                                        </div>

                                        <div className='details'>
                                            <i className="fa-solid fa-chevron-down"></i>
                                            <h4>تفاصيل الرحلة</h4>
                                        </div>

                                        <hr />

                                        <div className='down'>
                                            <div className='left'>
                                                <Link to='/Pakage-Flight'>
                                                    <button>اختر الرحلة</button>
                                                </Link>
                                            </div>

                                            <div className='right'>
                                                <h4>إجمالي المبلغ (للذهاب و العودة)</h4>
                                                <h3>970 ريال</h3>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='card'>
                                        <div className='top'>

                                            <div className='time'>
                                                <h3>09:55</h3>
                                                <p>السبت 9/10</p>
                                                <h4>دبي</h4>
                                                <span className='hide-avalable-sites'>4 مقاعد متاحة</span>
                                            </div>

                                            <div className='line-flight'>
                                                <p>1 stop</p>
                                                <img src={line_img} />
                                                <p>9 h 50m</p>
                                            </div>

                                            <div className='time'>
                                                <h3>09:55</h3>
                                                <p>السبت 9/10</p>
                                                <h4>القاهرة</h4>
                                                <span>4 مقاعد متاحة</span>
                                            </div>

                                            <span className='v-line'></span>

                                            <div className='logo-flight'>
                                                <img src={Emirates_img} />
                                                <p>الخطوط الاماراتية</p>
                                                <span>الدرجة الاقتصادية</span>
                                            </div>

                                        </div>

                                        <div className='details'>
                                            <i className="fa-solid fa-chevron-down"></i>
                                            <h4>تفاصيل الرحلة</h4>
                                        </div>

                                        <hr />

                                        <div className='down'>
                                            <div className='left'>
                                                <button>اختر الرحلة</button>
                                            </div>

                                            <div className='right'>
                                                <h4>إجمالي المبلغ (للذهاب و العودة)</h4>
                                                <h3>970 ريال</h3>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='card'>
                                        <div className='top'>

                                            <div className='time'>
                                                <h3>09:55</h3>
                                                <p>السبت 9/10</p>
                                                <h4>دبي</h4>
                                                <span className='hide-avalable-sites'>4 مقاعد متاحة</span>
                                            </div>

                                            <div className='line-flight'>
                                                <p>1 stop</p>
                                                <img src={line_img} />
                                                <p>9 h 50m</p>
                                            </div>

                                            <div className='time'>
                                                <h3>09:55</h3>
                                                <p>السبت 9/10</p>
                                                <h4>القاهرة</h4>
                                                <span>4 مقاعد متاحة</span>
                                            </div>

                                            <span className='v-line'></span>

                                            <div className='logo-flight'>
                                                <img src={Emirates_img} />
                                                <p>الخطوط الاماراتية</p>
                                                <span>الدرجة الاقتصادية</span>
                                            </div>

                                        </div>

                                        <div className='details'>
                                            <i className="fa-solid fa-chevron-down"></i>
                                            <h4>تفاصيل الرحلة</h4>
                                        </div>

                                        <hr />

                                        <div className='down'>
                                            <div className='left'>
                                                <button>اختر الرحلة</button>
                                            </div>

                                            <div className='right'>
                                                <h4>إجمالي المبلغ (للذهاب و العودة)</h4>
                                                <h3>970 ريال</h3>
                                            </div>
                                        </div>
                                    </div>                                    
                                    
                                </div>

                                
                            </div>
                        </div>
                    </div>

                </div>

                <Footer />
            </div>
        </>
    );
};


export default SearchResultFlight;