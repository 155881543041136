import './style_home_dashboard.css';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { useState } from "react";
import { auth, db } from "../../Firebase/firebase";
import { collection, doc, getDoc, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import login_img from '../images/login_dashboard.png';
import lock_img from '../images/lock.png';

function HomeDashboard() {
    const navigate = useNavigate();

    const [myEmail, setMyEmail] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('admin');

    const handelSubmit = async(e)=> {
        e.preventDefault();

        try {
            const userCridentail = await createUserWithEmailAndPassword(auth, myEmail, password);
            const user = userCridentail.user;

            await setDoc(doc(collection(db, 'admin'), user.uid), {
                myEmail,
                role,
                uid: user.uid
            });

            alert('Successful Sign Up');

            setMyEmail('');
            setPassword('');

        } catch(error) {
            console.log(error);
        }
    };

    const handelLogin = async(e)=> {
        e.preventDefault();

        try {

            const userCridentail = await signInWithEmailAndPassword(auth, myEmail, password);
            const user = userCridentail.user;

            // To Get Data From Firebase
            const userDoc = await getDoc(doc(db, 'admin', user.uid));
            const userData = userDoc.data();

            switch (userData.role) {
                case 'admin':
                    navigate('/Master-Dashboard');
                    break;
            
                default:
                    alert('Error With Role');
            }

        } catch(error) {
            alert(error);
        }
    };

    return(
        <>
            <div className="my-home-dashboard">
                <div className="container">
                    <Navbar />
                    
                    {/*---------- Very important for sign up a new admin --------------- */}
                    {/* <div className="admin-signUp">
                        <form onSubmit={handelSubmit}>
                            <input type='email' placeholder="Email" value={myEmail} onChange={(e)=> {setMyEmail(e.target.value)}}/>
                            <input type='password' placeholder="password" value={password} onChange={(e)=> {setPassword(e.target.value)}} />

                            <button type='submit'>Sign Up</button>
                        </form>
                    </div> */}

                    <div className="admin-login">
                        <div className="left">
                            <img src={login_img} />
                        </div>

                        <div className="right">
                            <form onSubmit={handelLogin}>
                                <div className='lock'>
                                    <img src={lock_img} />
                                </div>

                                <label htmlFor="">اسم المستخدم</label>
                                <input type='email' placeholder="اسم المستخدم" value={myEmail} onChange={(e)=> {setMyEmail(e.target.value)}} />
                                
                                <label htmlFor="">كلمة المرور</label>
                                <input type='password' placeholder="كلمة المرور" value={password} onChange={(e)=> {setPassword(e.target.value)}} />

                                <div className='btn'>
                                    <button type='submit'>تسجيل الدخول</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};


export default HomeDashboard;