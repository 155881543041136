import './style_profile_page.css';
import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { auth, db, storage } from '../../Firebase/firebase';
import Navbar from '../Navbar/Navbar';
import avatar_img from '../images/avatar_man.jpg';
import cover_img from '../images/cover.png';
import { onAuthStateChanged } from 'firebase/auth';
import LastHotel from './Last Hotel/LastHotel';
import LastFlight from './Last Flight/LastFlight';
import Footer from '../Footer/Footer';
import LastFavorite from './Last Favorite/LastFavorite';
import LastWallet from './Last Wallet/LastWallet';


function ProfilePage({ onSignOut }) {
  const navigate = useNavigate();

  const [profile, setProfile] = useState(null);
  console.log(profile);

  const [getName, setGetName] = useState();

  // To Upload Img
  const [error, setError] = useState('');
  const [newText, setNewText] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [isImageChange, setIsImageChange] = useState(false);


  const [isEditing, setIsEditing] = useState(false); // للتحكم في حالة التعديل
  const [newUsername, setNewUsername] = useState("");
  const [originalUsername, setOriginalUsername] = useState(""); // لتخزين الاسم الأصلي قبل التعديل
  const [loading, setLoading] = useState(true); // للتحقق من حالة التحميل


  // To show/hide Component
  const [isMyAccount, setIsMyAccount] = useState(true);
  const [isLastHotel, setIsLastHotel] = useState(false);
  const [isLastFligth, setIsLastFlight] = useState(false);
  const [isLastFavorite, setIsLastFavorite] = useState(false);
  const [isLastWallet, setIsLastWallet] = useState(false);
  const [accountActive, setAccountActive] = useState(0);


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const userData = docSnap.data();
          setProfile(userData);
          setNewUsername(userData.username);
          setOriginalUsername(userData.username); // حفظ الاسم الأصلي
        } else {
          console.error("User profile not found.");
        }
      } else {
        console.error("User is not logged in.");
      }
      setLoading(false); // انتهاء التحميل
    });

    return () => unsubscribe(); // إلغاء الاشتراك عند تنظيف الكومبوننت
  }, []);




  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const user = auth.currentUser;
        setGetName(user.displayName);
        console.log(user);

        if (!user) {
          navigate('/login');
          return;
        }

        const docRef = doc(db, 'users', user.uid);
        const docSnap = await getDoc(docRef);
        console.log(docSnap.data());

        // const docRefPhone = doc(db, 'users', user.phoneNumber);
        // const docSnapPhone = await getDoc(docRef);

        if (docSnap.exists()) {
          setProfile(docSnap.data());
        } else {
          //setProfile(docSnapPhone.data());
          setError('No such document!');
        }
      } catch (err) {
        setError(err.message);
      }
    };



    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchProfile();

        // To show name of user when login success
        //setIsLogin(true);

      } else {
        navigate('/login');
        //setIsLogin(false);
      }
    });

    return () => unsubscribe();
  }, [navigate]);




const handleEdit = () => {
  setIsEditing(true);
};

const handleCancel = () => {
  setNewUsername(originalUsername); // استرجاع الاسم الأصلي
  setIsEditing(false);
};

const handleSave = async () => {
  try {
    const user = auth.currentUser;
    if (user) {
      const docRef = doc(db, "users", user.uid);
      await updateDoc(docRef, { username: newUsername });
      setProfile((prevProfile) => ({
        ...prevProfile,
        username: newUsername,
      }));
      setIsEditing(false); // إنهاء وضع التعديل
      //alert("Username updated successfully!");
    } else {
      console.error("User ID not found.");
    }
  } catch (error) {
    console.error("Error updating username:", error);
  }
};





// إذا كانت حالة التحميل لا تزال قائمة، اعرض رسالة التحميل
if (loading) {
  return <div>جارٍ التحميل...</div>;
}











  

 



  // To Upload Image
  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  // To Upload Text
  const handleTextChange = (e) => {
    setNewText(e.target.value);
  };

  // To Upload
  const handleUpload = async () => {
    if (imageFile && profile) {
      setUploading(true);
      try {
        const user = auth.currentUser;
        const storageRef = ref(storage, `profileImages/${user.uid}`);
        await uploadBytes(storageRef, imageFile);
        const imageUrl = await getDownloadURL(storageRef);

        // تحديث بيانات المستخدم في Firestore
        await updateDoc(doc(db, 'users', user.uid), {
          imageUrl: imageUrl,
          text: newText
        });

        // تحديث الحالة المحلية
        setProfile((prevProfile) => ({
          ...prevProfile,
          imageUrl: imageUrl,
          text: newText
        }));

        setNewText('');
        setImageFile(null);

        window.location.reload();

      } catch (err) {
        setError('Error uploading file: ' + err.message);
      } finally {
        setUploading(false);
      }
    }
  };


    // To SignOut
    const handleSignOut = () => {
        onSignOut();
        navigate('/Login'); // Redirect to login page after sign out
    };

    return(
        <>
            
            <div className="my-profile-page">
              <div className='container'>
                  
                    <Navbar />
              
                    {error && <p>{error}</p>}

                    {profile ? (
                     
                      <div className='profile-content'>
                        <img src={cover_img} />
                        
                          <div className='head'>
                              
                              <div className='top'>
                                    {
                                      profile.imageUrl ? (
                                        <img src={profile.imageUrl} alt="Profile" />
                                      ) : (
                                        <img src={avatar_img} />
                                      )
                                    }

                                    <label htmlFor="file" onClick={()=> {setIsImageChange(true)}}>
                                        <i className="fa-solid fa-camera"></i>
                                    </label>
                                    <input id='file' type="file" onChange={handleImageChange} style={{display: 'none'}}/>

                                    <button className='upload' style={isImageChange === false ? {display: 'none'} : null} onClick={handleUpload} disabled={uploading}>
                                      {uploading ? 'Uploading...' : 'حفظ الصورة'}
                                    </button>

                                    {/* <h1>كريم سيد</h1> */}
                                    {/* <div className='my-name'>
                                        
                                        {isEditing ? (
                                          <input
                                            type="text"
                                            value={tempUsername}
                                            onChange={handleInputChange}
                                            placeholder='اكتب اسمك'
                                          />
                                        ) : (
                                          <h1>{profile.username}</h1>
                                        )}

                                        
                                        <button onClick={handleEditClick}>
                                          {isEditing ? "حفظ" : "اكتب اسمك"}
                                        </button>
                                  </div> */}

                                  <div className='my-name'>
                                        {profile ? (
                                          <div>
                                            <h1>
                                              {isEditing ? (
                                                <input
                                                  type="text"
                                                  value={newUsername}
                                                  onChange={(e) => setNewUsername(e.target.value)}
                                                />
                                              ) : (
                                                profile.username
                                              )}
                                            </h1>
                                            {isEditing ? (
                                              <>
                                                <button onClick={handleSave}>حفظ</button>
                                                <button onClick={handleCancel}>رجوع</button>
                                              </>
                                            ) : (
                                              <button onClick={handleEdit}>اكتب اسمك</button>
                                            )}
                                          </div>
                                        ) : (
                                          <p>User not found.</p>
                                        )}
                                  </div>

                                  
                              </div>

                              <div className='down'>
                                  <Link className={accountActive === 0 ? 'my-account' : ''} onClick={()=> {
                                    setAccountActive(0);
                                    setIsMyAccount(true);
                                    setIsLastHotel(false);
                                    setIsLastFlight(false);
                                    setIsLastFavorite(false);
                                    setIsLastWallet(false);
                                  }}>حسابي</Link>

                                  <Link className={accountActive === 1 ? 'my-account' : ''} onClick={()=> {
                                    setAccountActive(1);
                                    setIsLastHotel(true);
                                    setIsMyAccount(false);
                                    setIsLastFlight(false);
                                    setIsLastFavorite(false);
                                    setIsLastWallet(false);
                                  }}>حجوزاتي للفنادق</Link>

                                  <Link className={accountActive === 2 ? 'my-account' : ''} onClick={()=> {
                                    setAccountActive(2);
                                    setIsLastFlight(true);
                                    setIsMyAccount(false);
                                    setIsLastHotel(false);
                                    setIsLastFavorite(false);
                                    setIsLastWallet(false);
                                  }}>حجوزاتي للطيران</Link>

                                  <Link className={accountActive === 3 ? 'my-account' : ''} onClick={()=> {
                                    setAccountActive(3);
                                    setIsLastFavorite(true);
                                    setIsMyAccount(false);
                                    setIsLastHotel(false);
                                    setIsLastFlight(false);
                                    setIsLastWallet(false);
                                  }}>المفضلة</Link>

                                  <Link className={accountActive === 4 ? 'my-account' : ''} onClick={()=> {
                                    setAccountActive(4);
                                    setIsLastWallet(true);
                                    setIsLastHotel(false);
                                    setIsMyAccount(false);
                                    setIsLastFlight(false);
                                    setIsLastFavorite(false);
                                  }}>المحفظة</Link>

                                  <Link onClick={handleSignOut} style={{color: '#dc2626'}}>تسجيل خروج</Link>
                              </div>

                          </div>

                          

                          <div className='my-email' style={isMyAccount === false ? {display: 'none'} : null}>
                              {
                                profile.email ? (
                                    <div className='strong'>
                                      <strong>Email:</strong>
                                      <p>
                                        <i className="fa-solid fa-envelope-circle-check"></i> 
                                        {profile.email}
                                      </p>
                                    </div>
                                ) : (

                                  profile.phoneNumber ? (
                                    <div className='strong'>
                                      <strong>Phone Number:</strong>
                                      <p>
                                        <i className="fa-solid fa-phone"></i>
                                        {profile.phoneNumber}
                                      </p>
                                    </div>

                                  ) : (
                                    <div className='strong'>
                                      <strong>Your Account From X (Twitter)</strong>
                                      
                                    </div>
                                  )

                                  
                                )
                              }
                          </div>


                          <div className='last-hotel' style={isLastHotel === false ? {display: 'none'} : null}>
                            <LastHotel />
                          </div>

                          <div className='last-flight' style={isLastFligth === false ? {display: 'none'} : null}>
                              <LastFlight />
                          </div>

                          <div className='last-favorite' style={isLastFavorite === false ? {display: 'none'} : null}>
                              <LastFavorite />
                          </div>

                          <div className='last-wallet' style={isLastWallet === false ? {display: 'none'} : null}>
                              <LastWallet />
                          </div>

                          {/* <div className='footer'>
                              <Footer />
                          </div> */}

                              
                              
                      </div>
                    ) : (
                      // <p>Loading...</p>
                      null
                    )}

                    {/* <button className='sign-out' onClick={handleSignOut}>LogOut</button> */}
                    {/* <Link to='/'>
                        <button className='sign-out'>Home</button>
                    </Link> */}
                  </div>
                </div>


            
        </>
    );
};


export default ProfilePage;