import './style_roules.css';
import Navbar from '../../Navbar/Navbar';
import Footer from '../Footer';
import { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../../Firebase/firebase';
import Loading from '../../Loading/Loading';

function Roules() {
    const [myData, setMyData] = useState([]);
    //console.log(myData);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(()=> {

        const fetchData = async()=> {

            const getMyData = await getDocs(collection(db, 'myRouls'));
            const dataList = getMyData.docs.map((e)=> ({
                ...e.data(),
                id: e.id,
            }));

            console.log(getMyData);
            

            // ترتيب البيانات من الأقدم إلى الأحدث بناءً على Timestamp
            dataList.sort((a, b) => {
                return a.TimeStamp.toDate() - b.TimeStamp.toDate();
            });

            setMyData(dataList);

            setIsLoading(false);
        };

        fetchData();
    }, []);

    if(isLoading) {
        return(
            <Loading />
        )

    } else {
        return (
            <>
                <div className='my-Roules'>
                    <div className='container'>
                        <Navbar />
    
                        <div className='hero'>
                            <h1>الشروط و الأحكام</h1>
                            <hr />
                        </div>
    
                        <div className='content'>
                            <div className='head'>
                                <span></span>
                                <h1>
                                     يرجى قراءة الشروط و الاحكام بعناية تامة عند استخدام موقع توت
                                </h1>
                                <span></span>
                            </div>
                        </div>

                        {
                            myData.map((e)=> {
                                return(
                                    <div className='my-details' key={e.id}>
                                        <details>
                                            <summary>{e.title}</summary>
                                            {/* <p>
                                                {e.content}
                                            </p> */}

                                    {
                                        myData.length > 0 && (
                                            <div key={myData.id}>
                                                {
                                                    e.content.split('\n').map((line, index) => (
                                                        <p key={index} style={{ fontWeight: index % 2 === 0 ? 'normal' : 'normal' }}>
                                                            {line}
                                                        </p>
                                                    ))
                                                }
                                            </div>
                                        )
                                    }
                                        </details>
                                    </div>
                                )
                            })
                        }
    
                        <Footer />
                    </div>
                </div>
            </>
        );
    }

    
};


export default Roules;