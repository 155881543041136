import './style_subscribeUser.css';
import { useNavigate } from 'react-router-dom';
import SidebarDashboard from '../Side Bar Dashboard/SidebarDashboard';
import { useEffect, useState } from 'react';
import { collection, deleteDoc, doc, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../../Firebase/firebase';
import { getAuth } from 'firebase/auth';
import Loading from '../../Loading/Loading';


function SubscribeUser({onSignOutForAdmin}) {
    const navigate = useNavigate();

    // Active Btn For Search
    const [activeBtn, setActiveBtn] = useState(false);  // false ==> This mean Email is active

    // To get data
    const [users, setUsers] = useState([]);
    //console.log(users);

    const [isLoading, setIsLoading] = useState(true);

    const [searchValue, setSearchValue] = useState('');
    //console.log(searchValue);

    // Fetch all users
    useEffect(()=> {

        const fetchAllUsers = async () => {
            setIsLoading(true);
            const usersSnapshot = await getDocs(collection(db, 'users'));
            const usersList = usersSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
            setUsers(usersList);
            setIsLoading(false);
        };

        fetchAllUsers();

    }, []);

    
    const filteredUsers = users.filter((user) => {
        if (activeBtn) {
            // البحث برقم الهاتف
            return user.phoneNumber && user.phoneNumber.includes(searchValue);
        } else {
            // البحث بالبريد الإلكتروني
            return user.email && user.email.includes(searchValue);
        }
    });



    // To Delete User
    const handelDeleteUser = async(userId)=> {
        try{

            const deleteMassage = window.confirm('Are You Sure To Delete This User ?');

            if(deleteMassage) {
                // Delete student from Firestore
                await deleteDoc(doc(db, 'users', userId));
            };

            // Update state
            // هنا يمكنك تحديث حالة المستخدمين بعد الحذف (على سبيل المثال، إزالة المستخدم من الحالة)
            setUsers(users.filter(user => user.id !== userId));

        } catch(err) {
            alert(err);
        };
    };







    // To LogOut
    const handelLogOut = ()=> {
        onSignOutForAdmin();
        navigate('/My-Admin-Dashboard');
    };


    // Content Of Subscribe
    const subscribeUserDashboard = ()=> {
        return(
            <>
                <div className='head-content'>
                    <span></span>
                    <h2>إبحث عن مستخدم</h2>
                    <span></span>
                </div>

                <div className='search-user'>
                    <div className='btn'>
                        <button className={activeBtn === true ? 'mobile active-for-search-subscribe-user' : 'mobile'} onClick={()=> {setActiveBtn(true)}}>جوال</button>
                        <button className='email' style={activeBtn === true ? {backgroundColor: '#F1F1F1', color: '#5A64A8'} : null} onClick={()=> {setActiveBtn(false)}}>بريد إلكتروني</button>
                    </div>

                    <input type='search' placeholder={activeBtn ? 'ادخل رقم الجوال' : 'ادخل البريد الإكتروني'} onChange={(e)=> {setSearchValue(e.target.value)}} />
                    {/* <button className='btn-search'>بحث</button> */}
                </div>

                <div className='subscribe-content'>
                    
                    {/* To Search */}

                    {
                        searchValue ? (
                            filteredUsers.length > 0 ? (
                                <div className='master-search'>
                                    <h2>نتائج البحث ({filteredUsers.length})</h2>
        
                                    {filteredUsers.map((user) => (
                                        <div key={user.id} className='search-content'>

                                            <button onClick={()=> {handelDeleteUser(user.id)}}>حذف</button>
                                            <p>{activeBtn ? user.phoneNumber : user.email}</p>
                                            
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <p>لا يوجد</p>
                            )

                        ) : (
                            <div>
                                <h2>عدد المشتركين ({users.length})</h2>

                                    {
                                        users.map((e)=> {
                                            
                                            return(
                                                <div className='my-users' key={e.id}>
                                                    <div className='info-user'>
                                                        <button onClick={()=> {handelDeleteUser(e.id)}}>حذف</button>

                                                        <input type="text" value={e.email ? e.email : e.phoneNumber ? e.phoneNumber : 'Gmail Or X' } readOnly/>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                            </div>
                        )
                    }

                    
                </div>
            </>
        );
    };


    if(isLoading) {
        return(
            <Loading />
        );

    } else {

        return(
            <>
                <div className="my-subscibe-user">
                    <div className="container">
                        <SidebarDashboard titleName='المشتركين' content={subscribeUserDashboard()} toLogOut={handelLogOut}/>
                    </div>
                </div>
            </>
        );
    }

};


export default SubscribeUser;