import { useEffect, useState } from 'react';
import './style_search_bar_hotel.css';
import { useTranslation } from 'react-i18next';
import pyramids_img from '../images/Cairo-landmarks-2.png';
import italy_img from '../images/italy.png';
import marakish_img from '../images/marakish.png';
import { Link } from 'react-router-dom';

function SearchBarHotel({isArabicLange, topButton = true, hideTop = false}) {
    const [isFilterHotel, setIsFilterHotel] = useState(true);
    //console.log(isFilterHotel);

    // const [topButton, setTopButton] = useState(true);

    const [isOpenDestination, setIsOpenDestination] = useState(false);
    const [typeOfSelect, setTypeOfSelect] = useState('one');
    //console.log(typeOfSelect);

    const [showTypeOfSelect, setShowTypeOfSelect] = useState(false);

    const [counter, setCounter] = useState(1);
    
    const [defaultValueWhenChooseDestination, setDefaultValueWhenChooseDestination] = useState('');

    const [goOnly, setGoOnly] = useState(false);
    const [roundTripe, setRoundTripe] = useState(true);
    const [multiCities, setMultiCities] = useState(false);
    const [counterFlight, setCounterFlight] = useState(0);

    useEffect(()=> {

        if (typeOfSelect === 'other') {
            //console.log('this is other');
            setShowTypeOfSelect(true);
    
        } else {
            //console.log('No');
            setShowTypeOfSelect(false);
        }

    }, [typeOfSelect]);

    
 
    const { t, i18n } = useTranslation();


    const [customSearch, setCoustemSearch] = useState(true);

    useEffect(() => {
        // تعريف الدالة للتحقق من حجم الشاشة
        const checkScreenSize = (e) => {
          if (e.matches) {
            //console.log('Good'); // إذا كانت الشاشة أصغر من 992 بكسل
            if(!isArabicLange) {
                setCoustemSearch(true);
                //console.log('992px ==> En');

            } else {
                setCoustemSearch(false);
                //console.log('992px ==> Ar');
            }

            

          } else {
            //console.log('Bad');  // إذا كانت الشاشة أكبر من 992 بكسل
            setCoustemSearch(false);
          }
        };
      
        // استخدام matchMedia لمراقبة عرض الشاشة
        const mediaQuery = window.matchMedia('(max-width: 992px)');
      
        // التحقق الأولي عند تحميل الصفحة
        checkScreenSize(mediaQuery);
      
        // إضافة مستمع لتغيير حجم الشاشة
        mediaQuery.addEventListener('change', checkScreenSize);
      
        // تنظيف المستمع عند إزالة العنصر
        return () => mediaQuery.removeEventListener('change', checkScreenSize);
      }, [isArabicLange]);
      


    return(
        <>
            <div className='my-search-bar-hotel'>

                <div className='container'>

                    
                    {
                        !hideTop && topButton && (
                            <div className='top' style={{visibility: hideTop ? 'hidden' : 'visible'}}>
                                <button className='Flight' onClick={()=> {setIsFilterHotel(false)}} style={isFilterHotel === false ? {backgroundColor: '#5A64A8'} : null}>
                                    <i className="fa-solid fa-plane"></i>
                                    {t('طيران')}
                                </button>
                                <button className='Hotel' onClick={()=> {setIsFilterHotel(true)}} style={isFilterHotel === false ? {backgroundColor: 'transparent'} : null}>
                                    <i className="fa-solid fa-hotel"></i>
                                    {t('فنادق')}
                                </button>
                            </div>
                        )
                    }
                     



                    {
                        isFilterHotel ? (
                            <form className='hotel-form'>
                                <div className='content' style={isArabicLange === true ? {left: '30%'} : null}>
                                    
                                    <div className='search' style={customSearch ? {marginLeft: '-4em'} : {marginLeft: '2em'}}>
                                        {/* Number Of Guest */}
                                        <div className='location my-select'>
                                            <select style={isArabicLange === true ? {direction: 'rtl'} : {direction: 'ltr'}} onChange={(e)=> {setTypeOfSelect(e.target.value)}}>
                                                <option value="one">{t('1 غرفة ، 1 بالغ ، 0 اطفال')}</option>
                                                <option value="tow">{t('1 غرفة ، 2 بالغين ، 0 اطفال')}</option>
                                                <option value="other">{t('خيارات إضافية')}</option>
                                                
                                            </select>

                                            <i className="fa-solid fa-user"></i>
                                        </div>

                                        <div className="data-from">
                                            <input type="date" />
                                            <span>{t('إلي')}</span>
                                        </div>
                                        
                                        <div className="data-from">
                                            <input type="date" />
                                            <span>{t('من')}</span>
                                        </div>

                                        <div className='location'>
                                            <i className="fa-solid fa-caret-down" style={isOpenDestination === true ? {color: 'green'}: null} onClick={()=> {setIsOpenDestination(!isOpenDestination)}}></i>
                                            <input type="text" defaultValue={defaultValueWhenChooseDestination} placeholder={t('البحث عن فنادق او وجهات')} style={isArabicLange === true ? {direction: 'rtl'} : {direction: 'ltr'}} />
                                            <i className="fa-solid fa-location-dot"></i>
                                        </div>
                                        
                                    </div>

                                    <div className='search-icon'>
                                        <Link to='/Search-Result-Hotel'>
                                            <button type='submit'> 
                                                <i className="fa-solid fa-magnifying-glass-plus"></i>
                                            </button>
                                        </Link>
                                    </div>
                                
                                </div>

                                <div className='open-destinations' style={isOpenDestination === false ? {display: 'none'} : null}>
                                    <div className='my-content'>
                                        <div className='card' onClick={()=> {
                                            setDefaultValueWhenChooseDestination('القاهرة');
                                            setIsOpenDestination(false);
                                        }}>
                                            <div className='left'>
                                                <h4>{t('القاهرة')}</h4>
                                                <h5>{t('القاهرة - عاصمة مصر')}</h5>
                                                <p>{t('الاماكن المميزة مثل الاهرامات و المتحف المصري')}</p>
                                            </div>

                                            <div className='right'>
                                                <img src={pyramids_img} />
                                            </div>
                                        </div>

                                        <div className='card' onClick={()=> {
                                            setDefaultValueWhenChooseDestination('روما');
                                            setIsOpenDestination(false);
                                        }}>
                                            <div className='left'>
                                                <h4>{t('روما')}</h4>
                                                <h5>{t('روما - عاصمة إيطاليا')}</h5>
                                                <p>{t('الاماكن المميزة مثل برج بيزا المائل و الكولوسيوم')}</p>
                                            </div>

                                            <div className='right'>
                                                <img src={italy_img} />
                                            </div>
                                        </div>

                                        <div className='card' onClick={()=> {
                                            setDefaultValueWhenChooseDestination('ماراكش');
                                            setIsOpenDestination(false);
                                        }}>
                                            <div className='left'>
                                                <h4>{t('ماراكش')}</h4>
                                                <h5>{t('ماراكش - مدينة مغربية')}</h5>
                                                <p>{t('الاماكن المميزة مثل قصر الباهية و حدائق ماجوريل')}</p>
                                            </div>

                                            <div className='right'>
                                                <img src={marakish_img} />
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                {/* Start Type Of Select (other) */}

                                <div className='open-other-select' style={showTypeOfSelect === false ? {display: 'none'} : null}>
                                    <div className='my-content'>
                                        <div className='card'>
                                                <div className='first'>
                                                    <h5 className='test'>text for test</h5>
                                                    <h5>{t('البالغين (+12 عاماً)')}</h5>
                                                    <h5>{t('عمر الأطفال من (0- 11)')}</h5>
                                                </div>

                                                <div className='secound'>
                                                    <div className='room'>
                                                        <h4>{t('غرفة 1')}</h4>
                                                        <i className="fa-solid fa-bed"></i>
                                                    </div>

                                                    <div className='my-form'>
                                                        <div className='choose-room'>
                                                            <div className='adult'>
                                                                
                                                                <select>
                                                                    <option value="">{t('عدد البالغين')}</option>
                                                                    <option value="">{t('1')}</option>
                                                                    <option value="">{t('2')}</option>
                                                                    <option value="">{t('3')}</option>
                                                                    <option value="">{t('4')}</option>
                                                                    <option value="">{t('5')}</option>
                                                                    <option value="">{t('6')}</option>
                                                                    <option value="">{t('7')}</option>
                                                                    <option value="">{t('8')}</option>
                                                                </select>
                                                            </div>

                                                            <div className='children'>
                                                                <select>
                                                                    <option value="">{t('عدد الاطفال')}</option>
                                                                    <option value="">{t('1')}</option>
                                                                    <option value="">{t('2')}</option>
                                                                    <option value="">{t('3')}</option>
                                                                    <option value="">{t('4')}</option>
                                                                    <option value="">{t('5')}</option>
                                                                    <option value="">{t('6')}</option>
                                                                    <option value="">{t('7')}</option>
                                                                    <option value="">{t('8')}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    
                                                </div>




                                                <div className='secound' style={counter >= 2 ? {display: 'flex'} : {display: 'none'}}>
                                                    
                                                    <div className='room'>
                                                        <h4>{t('غرفة 2')}</h4>
                                                        <i className="fa-solid fa-bed"></i>
                                                    </div>

                                                    <div className='my-form'>
                                                        <div className='choose-room'>
                                                            <div className='adult'>
                                                                
                                                                <select>
                                                                    <option value="">{t('عدد البالغين')}</option>
                                                                    <option value="">{t('1')}</option>
                                                                    <option value="">{t('2')}</option>
                                                                    <option value="">{t('3')}</option>
                                                                    <option value="">{t('4')}</option>
                                                                    <option value="">{t('5')}</option>
                                                                    <option value="">{t('6')}</option>
                                                                    <option value="">{t('7')}</option>
                                                                    <option value="">{t('8')}</option>
                                                                </select>
                                                            </div>

                                                            <div className='children'>
                                                                <select>
                                                                    <option value="">{t('عدد الاطفال')}</option>
                                                                    <option value="">{t('1')}</option>
                                                                    <option value="">{t('2')}</option>
                                                                    <option value="">{t('3')}</option>
                                                                    <option value="">{t('4')}</option>
                                                                    <option value="">{t('5')}</option>
                                                                    <option value="">{t('6')}</option>
                                                                    <option value="">{t('7')}</option>
                                                                    <option value="">{t('8')}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <i className="fa-solid fa-circle-xmark" onClick={()=> {setCounter(counter - 1)}}></i>
                                                </div>





                                                <div className='secound' style={counter >= 3 ? {display: 'flex'} : {display: 'none'}}>
                                                    
                                                    <div className='room'>
                                                        <h4>{t('غرفة 3')}</h4>
                                                        <i className="fa-solid fa-bed"></i>
                                                    </div>

                                                    <div className='my-form'>
                                                        <div className='choose-room'>
                                                            <div className='adult'>
                                                                
                                                                <select>
                                                                    <option value="">{t('عدد البالغين')}</option>
                                                                    <option value="">{t('1')}</option>
                                                                    <option value="">{t('2')}</option>
                                                                    <option value="">{t('3')}</option>
                                                                    <option value="">{t('4')}</option>
                                                                    <option value="">{t('5')}</option>
                                                                    <option value="">{t('6')}</option>
                                                                    <option value="">{t('7')}</option>
                                                                    <option value="">{t('8')}</option>
                                                                </select>
                                                            </div>

                                                            <div className='children'>
                                                                <select>
                                                                    <option value="">{t('عدد الاطفال')}</option>
                                                                    <option value="">{t('1')}</option>
                                                                    <option value="">{t('2')}</option>
                                                                    <option value="">{t('3')}</option>
                                                                    <option value="">{t('4')}</option>
                                                                    <option value="">{t('5')}</option>
                                                                    <option value="">{t('6')}</option>
                                                                    <option value="">{t('7')}</option>
                                                                    <option value="">{t('8')}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <i className="fa-solid fa-circle-xmark" onClick={()=> {setCounter(counter - 1)}}></i>
                                                </div>





                                                <div className='secound' style={counter >= 4 ? {display: 'flex'} : {display: 'none'}}>
                                                    
                                                    <div className='room'>
                                                        <h4>{t('غرفة 4')}</h4>
                                                        <i className="fa-solid fa-bed"></i>
                                                    </div>

                                                    <div className='my-form'>
                                                        <div className='choose-room'>
                                                            <div className='adult'>
                                                                
                                                                <select>
                                                                    <option value="">{t('عدد البالغين')}</option>
                                                                    <option value="">{t('1')}</option>
                                                                    <option value="">{t('2')}</option>
                                                                    <option value="">{t('3')}</option>
                                                                    <option value="">{t('4')}</option>
                                                                    <option value="">{t('5')}</option>
                                                                    <option value="">{t('6')}</option>
                                                                    <option value="">{t('7')}</option>
                                                                    <option value="">{t('8')}</option>
                                                                </select>
                                                            </div>

                                                            <div className='children'>
                                                                <select>
                                                                    <option value="">{t('عدد الاطفال')}</option>
                                                                    <option value="">{t('1')}</option>
                                                                    <option value="">{t('2')}</option>
                                                                    <option value="">{t('3')}</option>
                                                                    <option value="">{t('4')}</option>
                                                                    <option value="">{t('5')}</option>
                                                                    <option value="">{t('6')}</option>
                                                                    <option value="">{t('7')}</option>
                                                                    <option value="">{t('8')}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <i className="fa-solid fa-circle-xmark" onClick={()=> {setCounter(counter - 1)}}></i>
                                                </div>





                                                <hr />

                                                <div className='save'>
                                                    <button>{t('حفظ')}</button>
                                                </div>

                                                

                                                <div className='add-room' style={counter === 4 ? {display: 'none'} : null}>
                                                    <i className="fa-solid fa-circle-plus" onClick={()=> {setCounter(counter + 1)}}></i>
                                                    <h5>{t('إضافة غرفة أخرى (4 كحد أقصى)')}</h5>
                                                </div>
                                                
                                        </div>

                                    </div>
                                </div>

                                {/* End Type Of Select (other) */}
                            </form>

                            
                        ) : (
                            <form className='flight-form' style={multiCities === true ? {backgroundColor: 'rgba(0, 0, 0, 0.7)', padding: '0.5em'} : null}>
                                <div className='head-flight'>
                                    <button className={multiCities === true ? 'active-for-flight-search-bar' : ''} onClick={(e)=> {
                                        e.preventDefault();
                                        setMultiCities(true);
                                        setGoOnly(false);
                                        setRoundTripe(false);
                                    }}>مدن متعددة</button>

                                    <button className={roundTripe === true ? 'active-for-flight-search-bar' : ''} onClick={(e)=> {
                                        e.preventDefault();
                                        setRoundTripe(true);
                                        setGoOnly(false);
                                        setMultiCities(false);
                                        
                                    }}>ذهاب و عودة</button>

                                    <button className={goOnly === true ? 'active-for-flight-search-bar' : ''} onClick={(e)=> {
                                        e.preventDefault();
                                        setGoOnly(true);
                                        setRoundTripe(false);
                                        setMultiCities(false);
                                        
                                    }}>ذهاب فقط</button>
                                </div>

                                <div className='content-flight' style={isArabicLange === true ? {left: '30%'} : null}>
                                    
                                    <div className='search'>

                                        {
                                            goOnly || multiCities ? (
                                                null
                                            ) : (
                                                <div className="data-from">
                                                    <input type="date" />
                                                    <span>{t('إلي')}</span>
                                                </div>
                                            )
                                        }

                                        
                                        
                                        <div className="data-from">
                                            <input type="date" />
                                            <span>{t('من')}</span>
                                        </div>

                                        <div className='location'>
                                            <i className="fa-solid fa-caret-down" style={isOpenDestination === true ? {color: 'green'}: null}></i>
                                            <input type="text" defaultValue={defaultValueWhenChooseDestination} placeholder={t('محطة الوصول')} style={isArabicLange === true ? {direction: 'rtl'} : {direction: 'ltr'}} />
                                            <i className="fa-solid fa-plane" style={{rotate: '180deg', marginBottom: '0.6em'}}></i>
                                        </div>

                                        <div className='location'>
                                            <i className="fa-solid fa-caret-down" style={isOpenDestination === true ? {color: 'green'}: null}></i>
                                            <input type="text" defaultValue={defaultValueWhenChooseDestination} placeholder={t('محطة المغادرة')} style={isArabicLange === true ? {direction: 'rtl'} : {direction: 'ltr'}} />
                                            <i className="fa-solid fa-plane"></i>
                                        </div>
                                        
                                    </div>
                                
                                </div>

                                {
                                    multiCities ? (
                                        <div className='this-multi-city'>

                                            <div className='new-tripe' style={counterFlight >= 1 ? {display:'flex', flexDirection: 'column'} : {display: 'none'}}>
                                                <div className='head-multi-city'>
                                                    <div className='left'>
                                                        <h3>
                                                            الرحلة 2
                                                            <i className="fa-solid fa-plane-departure"></i>
                                                        </h3>
                                                    </div>

                                                    {/* <hr /> */}

                                                    <div className='right'>
                                                        <i className="fa-solid fa-circle-xmark" onClick={()=> {setCounterFlight(counterFlight - 1)}}></i>
                                                    </div>
                                                </div>

                                                <div className='search'>
                                                        <div className="data-from">
                                                            <input type="date" />
                                                            <span>{t('من')}</span>
                                                        </div>

                                                        <div className='location'>
                                                            <i className="fa-solid fa-caret-down" style={isOpenDestination === true ? {color: 'green'}: null}></i>
                                                            <input type="text" defaultValue={defaultValueWhenChooseDestination} placeholder={t('محطة الوصول')} style={isArabicLange === true ? {direction: 'rtl'} : {direction: 'ltr'}} />
                                                            <i className="fa-solid fa-plane" style={{rotate: '180deg', marginBottom: '0.6em'}}></i>
                                                        </div>

                                                        <div className='location'>
                                                            <i className="fa-solid fa-caret-down" style={isOpenDestination === true ? {color: 'green'}: null}></i>
                                                            <input type="text" defaultValue={defaultValueWhenChooseDestination} placeholder={t('محطة المغادرة')} style={isArabicLange === true ? {direction: 'rtl'} : {direction: 'ltr'}} />
                                                            <i className="fa-solid fa-plane"></i>
                                                        </div>
                                                </div>
                                            </div>

                                            
                                            <div className='new-tripe' style={counterFlight >= 2 ? {display:'flex', flexDirection: 'column'} : {display: 'none'}}>
                                                <div className='head-multi-city'>
                                                    <div className='left'>
                                                        <h3>
                                                            الرحلة 3
                                                            <i className="fa-solid fa-plane-departure"></i>
                                                        </h3>
                                                    </div>

                                                    {/* <hr /> */}

                                                    <div className='right'>
                                                        <i className="fa-solid fa-circle-xmark" onClick={()=> {setCounterFlight(counterFlight - 1)}}></i>
                                                    </div>
                                                </div>

                                                <div className='search'>
                                                        <div className="data-from">
                                                            <input type="date" />
                                                            <span>{t('من')}</span>
                                                        </div>

                                                        <div className='location'>
                                                            <i className="fa-solid fa-caret-down" style={isOpenDestination === true ? {color: 'green'}: null}></i>
                                                            <input type="text" defaultValue={defaultValueWhenChooseDestination} placeholder={t('محطة الوصول')} style={isArabicLange === true ? {direction: 'rtl'} : {direction: 'ltr'}} />
                                                            <i className="fa-solid fa-plane" style={{rotate: '180deg', marginBottom: '0.6em'}}></i>
                                                        </div>

                                                        <div className='location'>
                                                            <i className="fa-solid fa-caret-down" style={isOpenDestination === true ? {color: 'green'}: null}></i>
                                                            <input type="text" defaultValue={defaultValueWhenChooseDestination} placeholder={t('محطة المغادرة')} style={isArabicLange === true ? {direction: 'rtl'} : {direction: 'ltr'}} />
                                                            <i className="fa-solid fa-plane"></i>
                                                        </div>
                                                </div>
                                            </div>

                                            <div className='new-tripe' style={counterFlight >= 3 ? {display:'flex', flexDirection: 'column'} : {display: 'none'}}>
                                                <div className='head-multi-city'>
                                                    <div className='left'>
                                                        <h3>
                                                            الرحلة 4
                                                            <i className="fa-solid fa-plane-departure"></i>
                                                        </h3>
                                                    </div>

                                                    {/* <hr /> */}

                                                    <div className='right'>
                                                        <i className="fa-solid fa-circle-xmark" onClick={()=> {setCounterFlight(counterFlight - 1)}}></i>
                                                    </div>
                                                </div>

                                                <div className='search'>
                                                        <div className="data-from">
                                                            <input type="date" />
                                                            <span>{t('من')}</span>
                                                        </div>

                                                        <div className='location'>
                                                            <i className="fa-solid fa-caret-down" style={isOpenDestination === true ? {color: 'green'}: null}></i>
                                                            <input type="text" defaultValue={defaultValueWhenChooseDestination} placeholder={t('محطة الوصول')} style={isArabicLange === true ? {direction: 'rtl'} : {direction: 'ltr'}} />
                                                            <i className="fa-solid fa-plane" style={{rotate: '180deg', marginBottom: '0.6em'}}></i>
                                                        </div>

                                                        <div className='location'>
                                                            <i className="fa-solid fa-caret-down" style={isOpenDestination === true ? {color: 'green'}: null}></i>
                                                            <input type="text" defaultValue={defaultValueWhenChooseDestination} placeholder={t('محطة المغادرة')} style={isArabicLange === true ? {direction: 'rtl'} : {direction: 'ltr'}} />
                                                            <i className="fa-solid fa-plane"></i>
                                                        </div>
                                                </div>
                                            </div>

                                            <div className='new-tripe' style={counterFlight >= 4 ? {display:'flex', flexDirection: 'column'} : {display: 'none'}}>
                                                <div className='head-multi-city'>
                                                    <div className='left'>
                                                        <h3>
                                                            الرحلة 5
                                                            <i className="fa-solid fa-plane-departure"></i>
                                                        </h3>
                                                    </div>

                                                    {/* <hr /> */}

                                                    <div className='right'>
                                                        <i className="fa-solid fa-circle-xmark" onClick={()=> {setCounterFlight(counterFlight - 1)}}></i>
                                                    </div>
                                                </div>

                                                <div className='search'>
                                                        <div className="data-from">
                                                            <input type="date" />
                                                            <span>{t('من')}</span>
                                                        </div>

                                                        <div className='location'>
                                                            <i className="fa-solid fa-caret-down" style={isOpenDestination === true ? {color: 'green'}: null}></i>
                                                            <input type="text" defaultValue={defaultValueWhenChooseDestination} placeholder={t('محطة الوصول')} style={isArabicLange === true ? {direction: 'rtl'} : {direction: 'ltr'}} />
                                                            <i className="fa-solid fa-plane" style={{rotate: '180deg', marginBottom: '0.6em'}}></i>
                                                        </div>

                                                        <div className='location'>
                                                            <i className="fa-solid fa-caret-down" style={isOpenDestination === true ? {color: 'green'}: null}></i>
                                                            <input type="text" defaultValue={defaultValueWhenChooseDestination} placeholder={t('محطة المغادرة')} style={isArabicLange === true ? {direction: 'rtl'} : {direction: 'ltr'}} />
                                                            <i className="fa-solid fa-plane"></i>
                                                        </div>
                                                </div>
                                            </div>

                                            <div className='new-tripe' style={counterFlight >= 5 ? {display:'flex', flexDirection: 'column'} : {display: 'none'}}>
                                                <div className='head-multi-city'>
                                                    <div className='left'>
                                                        <h3>
                                                            الرحلة 6
                                                            <i className="fa-solid fa-plane-departure"></i>
                                                        </h3>
                                                    </div>

                                                    {/* <hr /> */}

                                                    <div className='right'>
                                                        <i className="fa-solid fa-circle-xmark" onClick={()=> {setCounterFlight(counterFlight - 1)}}></i>
                                                    </div>
                                                </div>

                                                <div className='search'>
                                                        <div className="data-from">
                                                            <input type="date" />
                                                            <span>{t('من')}</span>
                                                        </div>

                                                        <div className='location'>
                                                            <i className="fa-solid fa-caret-down" style={isOpenDestination === true ? {color: 'green'}: null}></i>
                                                            <input type="text" defaultValue={defaultValueWhenChooseDestination} placeholder={t('محطة الوصول')} style={isArabicLange === true ? {direction: 'rtl'} : {direction: 'ltr'}} />
                                                            <i className="fa-solid fa-plane" style={{rotate: '180deg', marginBottom: '0.6em'}}></i>
                                                        </div>

                                                        <div className='location'>
                                                            <i className="fa-solid fa-caret-down" style={isOpenDestination === true ? {color: 'green'}: null}></i>
                                                            <input type="text" defaultValue={defaultValueWhenChooseDestination} placeholder={t('محطة المغادرة')} style={isArabicLange === true ? {direction: 'rtl'} : {direction: 'ltr'}} />
                                                            <i className="fa-solid fa-plane"></i>
                                                        </div>
                                                </div>
                                            </div>

                                            

                                            <div className='add-tripe' style={counterFlight === 5 ? {display: 'none'} : null}>
                                                <h5>{t('إضافة 6 رحلات كحد أقصى')}</h5>
                                                <i className="fa-solid fa-circle-plus" onClick={()=> {setCounterFlight(counterFlight + 1)}}></i>
                                                
                                            </div>
                                        </div>
                                    ) : (null)
                                }

                                <div className='down-flight'>
                                    <Link to='/Search-Result-Flight'>
                                        <button type='submit'>ابحث عن رحلات</button>
                                    </Link>

                                    <select>
                                        <option value="">درجة المقصورة</option>
                                        <option value="">السياحية</option>
                                        <option value="">السياحية المتميزة</option>
                                        <option value="">رجال الاعمال</option>
                                        <option value="">الاولي</option>
                                    </select>

                                    <select>
                                        <option value="">عدد المسافرين</option>
                                        <option value="">1</option>
                                        <option value="">2</option>
                                        <option value="">3</option>
                                        <option value="">4</option>
                                        <option value="">5</option>
                                        <option value="">6</option>
                                        <option value="">7</option>
                                        <option value="">8</option>
                                    </select>
                                </div>
                            </form>
                        )
                    }

                    
                </div>
            </div>
        </>
    );
};


export default SearchBarHotel;