import './style_master_dashboard.css';
import { useNavigate } from 'react-router-dom';
import SidebarDashboard from '../Side Bar Dashboard/SidebarDashboard';
import avatar_img from '../../images/Avatar_dashboard.png';
import { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../../Firebase/firebase';
import Loading from '../../Loading/Loading';


function MasterDashboard({onSignOutForAdmin}) {
    const navigate = useNavigate();


     // To get data
     const [users, setUsers] = useState([]);
     //console.log(users);
 
     const [isLoading, setIsLoading] = useState(true);

     // Fetch all users
     useEffect(()=> {

        const fetchAllUsers = async () => {
         
            const usersSnapshot = await getDocs(collection(db, 'users'));
            const usersList = usersSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
            setUsers(usersList);
            setIsLoading(false);
        };

        fetchAllUsers();

    }, []);


    // To LogOut
    const handelLogOut = ()=> {
        onSignOutForAdmin();
        navigate('/My-Admin-Dashboard');
    };

    const masterDashboardContent = ()=> {
        return(
            <>
                <div className='master'>
                    <div className='head'>
                        <img src={avatar_img} />

                        <div className='title'>
                            <p>مرحباً بك</p>

                            <div className='main-title'>
                                <h2>المساحة المخصصه لادارة مهامك</h2>
                                <p>قم بإدارة مهامك من مكان واحد فقط بكل سهولة وسلاسه و التحكم الكامل في كل ملفاتك</p>
                            </div>
                        </div>
                    </div>

                    <div className='head-content'>
                        <span></span>
                        <h2>اخر 24 ساعة</h2>
                        <span></span>
                    </div>

                    <div className='my-content'>
                        <div className='card'>
                            <h1>0</h1>
                            <p>
                            عدد حجوزرات الطيران
                            <i className="fa-solid fa-plane-departure"></i>
                            </p>
                        </div>

                        <div className='card' style={{backgroundColor: '#96C9D1'}}>
                            <h1>0</h1>
                            <p>
                            عدد حجوزرات الفنادق
                            <i className="fa-solid fa-hotel"></i>
                            </p>
                        </div>
                    </div>

                    <div className='head-content'>
                        <span></span>
                        <h2>الإجمالي</h2>
                        <span></span>
                    </div>

                    <div className='my-content'>
                        <div className='card' style={{backgroundColor: '#33A850'}}>
                            <span>ريال</span>
                            <h1>0</h1>
                            <p>
                            الارباح
                            <i className="fa-solid fa-dollar-sign"></i>
                            </p>
                        </div>

                        <div className='card'>
                            <h1>0</h1>
                            <p>
                            عدد حجوزرات الطيران
                            <i className="fa-solid fa-plane-departure"></i>
                            </p>
                        </div>

                        <div className='card' style={{backgroundColor: '#96C9D1'}}>
                            <h1>0</h1>
                            <p>
                            عدد حجوزرات الفنادق
                            <i className="fa-solid fa-hotel"></i>
                            </p>
                        </div>

                        <div className='card' style={{backgroundColor: '#8384F8'}}>
                            {/* <h1>0</h1> */}

                            <h1>{users.length}</h1>

                            <p>
                            عدد المشتركين
                            <i className="fa-solid fa-people-group"></i>
                            </p>
                        </div>
                    </div>

                </div>
            </>
        );
    };


    if(isLoading) {
        return(
            <Loading />
        );

    } else {

        return(
            <>
                <div className='my-master-dashboard'>
                    <div className='container'>
                        <SidebarDashboard titleName='لوحه التحكم' content={masterDashboardContent()} toLogOut={handelLogOut}/>
                    </div>
                </div>
            </>
        );
    };
    
};


export default MasterDashboard;