import './style_help.css';
import { useTranslation } from 'react-i18next';
import help_img from '../../images/Help.png';

function Help() {
    const { t, i18n } = useTranslation();

    return(
        <>
            <div className='my-help'>
                <div className='container'>
                    <div className='left'>
                        <img src={help_img} />
                    </div>

                    <div className='right'>
                        <h2>{t('هل تحتاج مساعدة ؟')}</h2>
                        <p>{t('فريقنا متواجد لخدمتكم على مدار الساعة 24/7')}</p>

                        <div className='phone'>
                            <h3>0539992111</h3>
                            <i className="fa-brands fa-whatsapp"></i>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};


export default Help;