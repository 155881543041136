import './style_login.css';
import login_img from '../images/login_and_signup.png';
import Navbar from "../Navbar/Navbar";
import { useTranslation } from 'react-i18next';
import gmail_img from '../images/Gmail.png';
import x_img from '../images/x.png';

// Login.js
import React, { useEffect, useState } from 'react';
//import { auth, db } from './firebase';
import { signInWithEmailAndPassword, RecaptchaVerifier, signInWithPhoneNumber, signInWithPopup } from 'firebase/auth';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import { auth, db, provider, twitterProvider } from '../../Firebase/firebase';
import { Link, useNavigate } from 'react-router-dom';
import PhoneInput from "react-phone-input-2";
import OtpInput from "otp-input-react";
import { Player } from '@lottiefiles/react-lottie-player';
import Error_animation from '../Animation Icon/lottieflow-search-06-000000-easey.json';

const Login = () => {
  const [isButtonColor, setIsButtonColor] = useState(true);
  const { t, i18n } = useTranslation();

  const [isPhoneLogin, setIsPhoneLogin] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [phoneInput, setPhoneInput] = useState('');
  const [otpInput, setOtpInput] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);

  const [ph, setPh] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [user, setUser] = useState(null);

  const navigate = useNavigate();



    //Animation Massages:-
    const [isMassageError, setIsMassageError] = useState(true);
    const [isNoMobileError, setIsNoMobileError] = useState(true);
    const [isNoVerificationCode, setIsNoVerificationCode] = useState(true);

  // For Error Massage
  const checkAnimationError = () => {
    return (
      <div>
        <Player
          autoplay
          loop
          src={Error_animation}
          style={{ height: '300px', width: '300px' }} // يمكنك ضبط الأبعاد حسب الحاجة
        />
      </div>
    );
  };



  const handleEmailLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password)

      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      //alert('Logged in successfully with email and password!');

      

      navigate('/profile');

    } catch (error) {

      // التحقق من نوع الخطأ إذا كان البريد الإلكتروني مستخدم بالفعل
      if (error.code === 'auth/invalid-credential') {
        //alert('خطأ فى البريد الإلكترونى او كلمة المرور');

        setIsMassageError(false);

    } else {
        console.error('Error signing up with email:', error);
        alert('حدث خطأ أثناء محاولة تسجيل الحساب.');
    }

      };
  };


// const handleSubmit = (e) => {
//     e.preventDefault();
//     handleLogin(phoneNumber, password);
// };

// const handleLogin = async (phoneNumber, password) => {
//     try {
//         // تأكد من تنسيق رقم الهاتف بشكل صحيح
//         //const formattedPhoneNumber = phoneNumber.trim();
//         //console.log(formattedPhoneNumber);

//         // البحث عن المستخدم في Firestore
//         const userDoc = doc(db, 'users', 'ukCKiM19XvW8AszNUEWiHXKf7Hy1'); // تأكد من أن 'users' هو اسم المجموعة الصحيح
//         const userSnapshot = await getDoc(userDoc);
//         console.log('will login');
//         console.log(userSnapshot);


//         if (userSnapshot.exists()) {
//             //console.log('Hello');
//             const userData = userSnapshot.data();
//             //console.log(userData);

//             // تحقق من كلمة المرور
//             if (userData.password === password && userData.phoneNumber === phoneNumber) {
//                 // تسجيل الدخول باستخدام Firebase Authentication
//                 //console.log('iam here');
//                 //await signInWithEmailAndPassword(auth, 'ukCKiM19XvW8AszNUEWiHXKf7Hy1', password);

//                 alert('Login successful');
//                 // إعادة توجيه المستخدم إلى الصفحة الرئيسية أو أي صفحة أخرى
//             } else {
//                 alert('Invalid Data');
//             }
//         } else {
//             alert('User not found');
//         }
//     } catch (error) {
//         console.error('Error logging in:', error);
//         alert('Login failed');
//     }
// };




// const handleLogin = async (phoneNumber, password) => {
//   try {
//       // تأكد من تنسيق رقم الهاتف بشكل صحيح
//       const formattedPhoneNumber = phoneNumber.trim();

//       // البحث عن المستخدم في Firestore باستخدام رقم الهاتف كمُعرّف الوثيقة
//       const userDoc = doc(db, 'users', formattedPhoneNumber); // هنا نستخدم رقم الهاتف كمعرف للوثيقة
//       const userSnapshot = await getDoc(userDoc);

//       if (userSnapshot.exists()) {
//           const userData = userSnapshot.data();

//           // تحقق من كلمة المرور
//           if (userData.password === password && userData.phoneNumber === formattedPhoneNumber) {
//               alert('Login successful');
//               // إعادة توجيه المستخدم إلى الصفحة الرئيسية أو أي صفحة أخرى

//               //await signInWithEmailAndPassword(auth, formattedPhoneNumber, password);

//               localStorage.setItem('userPhoneNumber', formattedPhoneNumber);
//               navigate('/profile');


//           } else {
//               alert('Invalid Data');
//           }
//       } else {
//           alert('User not found');
//       }
//   } catch (error) {
//       console.error('Error logging in:', error);
//       alert('Login failed');
//   }
// };


// const handleLogin = async (phoneNumber, password) => {
//   try {
//       // تأكد من تنسيق رقم الهاتف بشكل صحيح
//       const formattedPhoneNumber = phoneNumber.trim();

//       // البحث عن المستخدم في Firestore باستخدام رقم الهاتف كمُعرّف الوثيقة
//       const userDoc = doc(db, 'users', formattedPhoneNumber); // هنا نستخدم رقم الهاتف كمعرف للوثيقة
//       const userSnapshot = await getDoc(userDoc);

//       if (userSnapshot.exists()) {
//           const userData = userSnapshot.data();

//           // تحقق من كلمة المرور
//           if (userData.password === password && userData.phoneNumber === formattedPhoneNumber) {
//               alert('Login successful');
              
//               // هنا لا نحتاج إلى استخدام Firebase Auth لأننا نتعامل مع الجلسة محليًا
//               // بدلاً من ذلك، يمكنك توجيه المستخدم مباشرة إلى الصفحة الرئيسية أو الصفحة المطلوبة
//               localStorage.setItem('userPhoneNumber', formattedPhoneNumber);
//               navigate('/profile');

//           } else {
//               alert('Invalid Data');
//           }
//       } else {
//           alert('User not found');
//       }
//   } catch (error) {
//       console.error('Error logging in:', error);
//       alert('Login failed');
//   }
// };


// const handleSendOtp = async (e) => {
//   e.preventDefault();

//   try {
//       const phoneNumber = "+20" + phoneInput.trim(); // تأكد من استخدام رمز البلد المناسب

//       const recaptcha = new RecaptchaVerifier('recaptcha-container', {
//           'size': 'invisible',
//           'callback': (response) => {
//               console.log('Recaptcha solved');
//           }
//       }, auth);

//       const confirmation = await signInWithPhoneNumber(auth, phoneNumber, recaptcha);
//       alert('OTP sent');
//       setConfirmationResult(confirmation);
//       setIsOtpSent(true);
//   } catch (error) {
//       console.error('Error during sending OTP:', error);
//       alert('Failed to send OTP');
//   }
// };


// const handleSendOtp = async (e) => {
//   e.preventDefault();

//   try {
//     const recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
//       'size': 'invisible', // يمكن تغييرها إلى 'normal' إذا كنت تحتاج إلى عنصر مرئي
//       'callback': (response) => {
//         // reCAPTCHA solved
//         console.log('Recaptcha solved');
//       }
//     }, auth);

//     const confirmation = await signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier);
//     setConfirmationResult(confirmation);
//     alert('OTP sent');
//   } catch (error) {
//     console.error('Error during sending OTP:', error);
//     alert('Failed to send OTP');
//   }
// };




// const handleVerifyOtp = async (e) => {
//   e.preventDefault();

//   const otp = otpInput.trim();
//   try {
//       const result = await confirmationResult.confirm(otp);
//       const user = result.user;

//       alert('Login successful');
//       localStorage.setItem('user', JSON.stringify({ phoneNumber: user.phoneNumber }));
//       navigate('/profile');
//   } catch (error) {
//       console.error('Error during OTP verification:', error);
//       alert('Failed to verify OTP');
//   }
// };






const handleGoogleSignIn = async () => {
    try {
        const result = await signInWithPopup(auth, provider);
        const user = result.user;
        console.log('User Info:', user);
        // يمكنك إعادة توجيه المستخدم إلى الصفحة الرئيسية أو أي صفحة أخرى بعد تسجيل الدخول بنجاح
        //alert('Login successful');
        // هنا يمكنك إضافة الكود لإعادة التوجيه إلى الصفحة الرئيسية


        navigate('/profile');


    } catch (error) {
        console.error('Error during Google sign-in:', error);
        alert('Login failed');
    }
};


const handleTwitterSignIn = async () => {
  try {
      const result = await signInWithPopup(auth, twitterProvider);
      const user = result.user;
      console.log('User Info:', user);
      // يمكنك إعادة توجيه المستخدم إلى الصفحة الرئيسية أو أي صفحة أخرى بعد تسجيل الدخول بنجاح
      //alert('Login successful');
      // هنا يمكنك إضافة الكود لإعادة التوجيه إلى الصفحة الرئيسية


      navigate('/profile');


  } catch (error) {
      console.error('Error during Google sign-in:', error);
      alert('Login failed');
  }
};



// OTP Work Very Well
function onCaptchaVerify() {
  if (!window.recaptchaVerifier) {
    window.recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "recaptcha-container", // تأكد من أن العنصر موجود في DOM
      {
        size: "normal",
        callback: (response) => {
          onLogin(); // استدعاء دالة تسجيل الدخول عند النجاح
        },
        "expired-callback": () => {
          alert("reCAPTCHA expired, please try again.");
        }
      }
    );
  }
}




// New
// التحقق إذا كان الرقم موجودًا مسبقًا
const checkPhoneExists = async (phoneNumber) => {
  // بدلاً من استخدام fetchSignInMethodsForEmail، تحقق في Firestore
  const usersRef = collection(db, 'users');
  const querySnapshot = await getDocs(usersRef);
  let exists = false;

  console.log(phoneNumber);
  //console.log(doc.data().phoneNumber);

  querySnapshot.forEach((doc) => {
    if (`+${doc.data().phoneNumber}` === phoneNumber) {

      exists = true;
    }
  });

  return exists;
};




const onLogin = () => {
  setLoading(true);
  onCaptchaVerify(); // التحقق من reCAPTCHA

  const appVerifier = window.recaptchaVerifier;

  // تنسيق الرقم ليكون بصيغة دولية
  const formatPh = '+' + ph; // مثال: +201148063426

  signInWithPhoneNumber(auth, formatPh, appVerifier)
    .then((confirmationResult) => {
      window.confirmationResult = confirmationResult;
      setLoading(false);
      setShowOTP(true); // عرض الحقل الخاص بإدخال OTP
      alert('OTP sent successfully!');
    })
    .catch((error) => {
      console.log(error);
      alert('Failed to send OTP. Please try again.');
      setLoading(false);
    });
};




function onOTPVerify() {
  setLoading(true);
  
  if (!window.confirmationResult) {
    alert("Please request OTP first.");
    setLoading(false);
    return;
  }

  window.confirmationResult
    .confirm(otp) // استخدام OTP للتحقق
    .then(async (res) => {
      console.log(res);
      setUser(res.user); // المستخدم الذي تم تسجيل دخوله
      setLoading(false);

      // الانتقال إلى الصفحة الرئيسية بعد تسجيل الدخول بنجاح
      navigate('/');
    })
    .catch((err) => {
      console.error('Error during OTP verification:', err);
      alert('Failed to verify OTP. Please try again.');
      setLoading(false);
    });
}






// For Amimation Massage:-
const myAnimationMassage = (textMassage, animationIcon, myNavigate)=> {
  return(
    <>
      <div className='my-massage'>
                                        
          <h3>
          
            {animationIcon}

            <br />
            {t(textMassage)}

          </h3>

          <button onClick={()=> {
            setIsMassageError(true);
            
            navigate(myNavigate);

          }}>{t('موافق')}</button>

      </div>
    </>
  );
};


  return (
    <div className="login-container">

      {/* Start Massages */}
      <div className='success-message' style={isMassageError === true ? {display: 'none'} : {display: 'flex'}}>
                                  
                  {myAnimationMassage('"خطأ فى البريد الإلكترونى او كلمة المرور"', checkAnimationError(), null)}
              
      </div>
      {/* End Massages */}

      
      <div className='my-navbar'>
        <Navbar />
      </div>

      <div className='container'>
          <div className='head'>
            <h1>تسجيل الدخول</h1>
            <div className='btn'>
                <button className='email' onClick={()=> {setIsButtonColor(true)}} style={isButtonColor === true ? {backgroundColor: '#5A64A8'} : {backgroundColor: 'transparent'}}>
                    <i className="fa-brands fa-google"></i>
                    {t('بريد إلكتروني')}
                </button>
                <button className='mobile' onClick={()=> {setIsButtonColor(false)}} style={isButtonColor === false ? {backgroundColor: '#5A64A8'} : {backgroundColor: 'transparent'}}>
                    <i className="fa-solid fa-mobile-screen-button"></i>
                    {t('جوال')}
                </button>
            </div>
          </div>

          {
            isButtonColor ? (
              <div className='my-option'>
                <form className='form-email' onSubmit={handleEmailLogin}>
                      <label htmlFor="">البريد الإلكتروني</label>
                      <input
                        type="email"
                        placeholder="البريد الإلكتروني"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />

                      <label htmlFor="">كلمة المرور</label>
                      <input
                        type="password"
                        placeholder="كلمة المرور"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />

                      <div className='options'>
                        <Link to='/ResetPassword'>
                          <h5 className='forget'>نسيت كلمة المرور ؟</h5>
                        </Link>
                        
                        <h5 className='sign-now'>
                          ليس لديك حساب ؟
                          <Link to='/SignUp'>سجل الان</Link>
                        </h5>
                      </div>

                      <button type="submit">تسجيل الدخول</button>
              </form>

                <div className='google-and-twitter'>
                    <div className='my-head'>
                        <span></span>
                        <h1>{t('او عن طريق')}</h1>
                        <span></span>
                    </div>

                    <div className='img-platform'>
                        <img src={gmail_img} onClick={handleGoogleSignIn} />
                        <img src={x_img} onClick={handleTwitterSignIn} />
                    </div>
                </div>

              </div>
            ) : (
              
              <div className='my-option'>

                  <section className='my-phone-section'>
                        <div>
                          
                          
                          {user ? (
                            // <h2>
                            //   👍Login Success
                            // </h2>

                            <></>
                            
                          ) : (
                            <div className='telephon'>
                              {/* <label htmlFor="">رقم الجوال</label> */}
                              {showOTP ? (
                                <>
                                  <div>
                                    {/* <BsFillShieldLockFill size={30} /> */}
                                  </div>
                                  <label
                                    htmlFor="otp"
                                    
                                  >
                                    الرجاء إدخال الرمز
                                  </label>
                                  <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    OTPLength={6}
                                    otpType="number"
                                    disabled={false}
                                    autoFocus
                                    className="opt-container"
                                  ></OtpInput>
                                  <button
                                    onClick={onOTPVerify}
                                    
                                  >
                                    {loading && (
                                      // <CgSpinner size={20} className="mt-1 animate-spin" />
                                      // <p>Hello From Spinner</p>
                                      <></>
                                    )}
                                    <span>تسجيل الدخول</span>
                                  </button>
                                </>
                              ) : (
                                <>
                                  <div>
                                    {/* <BsTelephoneFill size={30} /> */}
                                  </div>

                                  <label htmlFor="">رقم الجوال</label>
                                  <input type='tel' placeholder='رقم الجوال' onChange={(e)=> {setPh(e.target.value)}}/>

                                  <div id="recaptcha-container"></div>

                                  <div className='PhoneInput'>
                                      {/* <PhoneInput country={"in"} value={ph} onChange={setPh} /> */}
                                  </div>
                                  

                                  
                                  <button
                                    onClick={onLogin}
                                    
                                  >
                                    {loading && (
                                      // <CgSpinner size={20}  />
                                      // <p>Hello From Spinner</p>
                                      <></>
                                    )}
                                    <span>إرسال الرمز</span>
                                  </button>
                                </>
                              )}
                            </div>
                          )}
                        </div>
                  </section>

                



                <div className='google-and-twitter'>
                    <div className='my-head'>
                        <span></span>
                        <h1>{t('او عن طريق')}</h1>
                        <span></span>
                    </div>

                    <div className='img-platform'>
                        <img src={gmail_img} onClick={handleGoogleSignIn} />
                        <img src={x_img} onClick={handleTwitterSignIn} />
                    </div>
                </div>
              </div>
            )
          }

      </div>

      
    </div>
  );
};

export default Login;
