import './style_offers_details.css';
import Navbar from '../../../Navbar/Navbar';
import baly_img from '../../../images/baly-big.png';
import Footer from '../../Footer';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import { db } from '../../../../Firebase/firebase';

function OffersDetails() {
    const params = useParams();

    const [myData, setMyData] = useState([]);
    console.log(myData);

    const [isLoading, setIsLoading] = useState(true);

    // Show/Hide Check
    const [isCopy, setIsCopy] = useState(false);


    useEffect(() => {

        const fetchData = async () => {
            try {
                const docRef = doc(db, 'myOffers', params.Id); // استخدام doc لجلب مستند محدد
                const getMyData = await getDoc(docRef); // استخدام getDoc لجلب المستند
    
                if (getMyData.exists()) {
                    const data = {
                        ...getMyData.data(),
                        id: getMyData.id,
                    };
    
                    setMyData([data]); // قم بتخزين البيانات في state
                } else {
                    console.log("No such document!");
                }
            } catch (error) {
                console.log("Error fetching document:", error);
            }
    
            setIsLoading(false); // إيقاف التحميل بعد جلب البيانات
        };
    
        fetchData();
    
    }, [params.Id]); // تأكد من إضافة params.Id في قائمة التبعية
    


    return(
        <>
            <div className='my-offers-details'>
                <div className='container'>
                    <Navbar />

                    <div className='head-content'>
                        <span></span>
                        <h2>تفاصيل العرض</h2>
                        <span></span>
                    </div>

                    {/* <h1>this page is {params.Id}</h1> */}


                    {
                        myData.map((e)=> {
                            return(
                                <div className='content' key={e.id}>
                                    <div className='left'>
                                        <img src={e.imageUrl} />
                                    </div>

                                    <div className='right'>
                                        <h2>{e.title}</h2>
                                        <p>{e.content}</p>

                                        

                                        {
                                            e.discountCode && (
                                                <>
                                                    <h3>
                                                    احجز الان واستمتع بكود الخصم
                                                    </h3>

                                                    <div className='code'>

                                                        {isCopy && <i className="fa-solid fa-circle-check" style={{color: '#33A850'}}></i>}

                                                        <button onClick={()=> {
                                                            navigator.clipboard.writeText(e.discountCode);
                                                            setIsCopy(true);
                                                        }}>
                                                            انسخ الكود
                                                            <i className="fa-regular fa-clone"></i>
                                                        </button>

                                                        <input type="text" defaultValue={e.discountCode} />
                                                    </div>
                                                </>
                                            )
                                        }

                                        

                                        <button className='watch' onClick={()=> {window.location.href = e.myLink}}>شاهد العرض</button>
                                    </div>
                                </div>
                            )
                        })
                    }

                    <Footer />

                </div>
            </div>
        </>
    );
};


export default OffersDetails;