import './style_footer.css';
import toot_logo_img from '../images/Toot_Logo.png';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function Footer({myMargin=4}) {
    const { t, i18n } = useTranslation();

    return(
        <>
            <div className='my-footer'>
                <div className='container' style={{marginTop: `${myMargin}em`}}>
                    <div className='left'>
                        <img src={toot_logo_img} alt="logo" />
                    </div>

                    <div className='right'>
                        <Link to='/Who-We-Are'>
                            <h4>{t('من نحن')}</h4>
                        </Link>
                        
                        <Link to='/Privicy'>
                            <h4>{t('الخصوصية')}</h4>
                        </Link>
                        
                        <Link to='/Rules'>
                            <h4>{t('الشروط و الأحكام')}</h4>
                        </Link>

                        <Link to='/Offers'>
                            <h4>{t('العروض')}</h4>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
};


export default Footer;