// import logo from './logo.svg';
// import './App.css';

import Home from "./Components/Home/Home";
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from "./Components/Login/Login";
import SignUp from "./Components/Sign Up/SignUp";
import { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { auth, db } from "./Firebase/firebase";
import { doc, getDoc } from "firebase/firestore";
import ProfilePage from "./Components/ProfilePage/ProfilePage";
import ResetPassword from "./Components/Reset Password/ResetPassword";
import Navbar from "./Components/Navbar/Navbar";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
import SearchResultHotel from "./Components/Fot Hotels/SearchResultHotel";
import RoomAvaliable from "./Components/Fot Hotels/Room Avaliable/RoomAvaliable";
import PersonalInformation from "./Components/Fot Hotels/Personal Information/PersonalInformation";
import SearchResultFlight from "./Components/For Flight/SearchResultFlight";
import PakageFlight from "./Components/For Flight/Pakage flight/PakageFlight";
import InformationForFlight from "./Components/For Flight/Information For Flight/InformationForFlight";
import WhoWeAre from "./Components/Footer/Who We Are/WhoWeAre";
import Privicy from "./Components/Footer/Privicy/Privicy";
import Roules from "./Components/Footer/Roules/Rouels";
import Loading from "./Components/Loading/Loading";
import WhoWeAreDashoard from "./Components/Dashboard/WhoWeAreDashoard/WhoWeAreDashoard";
import HomeDashboard from "./Components/Dashboard/HomeDashboard";
import SidebarDashboard from "./Components/Dashboard/Side Bar Dashboard/SidebarDashboard";
import MasterDashboard from "./Components/Dashboard/Master Dashboard/MasterDashboard";
import RoulesDashboard from "./Components/Dashboard/Roules Dashboard/RoulesDashboard";
import PrivicyDashboard from "./Components/Dashboard/Privicy Dashboard/PrivicyDashboard";
import DiscoundDashboard from "./Components/Dashboard/Discound Dashboard/DiscoundDashboard";
import Offers from "./Components/Footer/Offers/Offers";
import OffersDetails from "./Components/Footer/Offers/Offers Details/OffersDetails";
import OffersDashboard from "./Components/Dashboard/Offers Dashboard/OffersDashboard";
import SubscribeUser from "./Components/Dashboard/Subscribe User/SubscribeUser";

function App() {
  //const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const [isLogin, setIsLogin] = useState(false);

  //const [user, setUser] = useState(null);
  const auth = getAuth();

  

  // to stay you login entel you refresh the page 
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user'))); //parse ==> to convert string to object / localStorage use object only

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      //setIsLogin(true);

      localStorage.setItem('user', JSON.stringify(currentUser));
    });

    return () => unsubscribe();
  }, [isLogin]);



  // useEffect(() => {
  //   const unsubscribe = onAuthStateChanged(auth, async (user) => {
  //     if (user) {
  //       try {
  //         const userDoc = await getDoc(doc(db, 'users', user.uid));
  //         if (userDoc.exists()) {
  //           setUser({ ...user });
  //         } else {
  //           setUser(null); // Handle case where user data is not found
  //         }
  //       } catch (error) {
  //         console.error("Error fetching user data: ", error);
  //         setUser(null);
  //       }
  //     } else {
  //       setUser(null);
  //     }
  //     setLoading(false);
  //   });

  //   return () => unsubscribe();
  // }, []);


  //if (loading) return <div>Loading...</div>;


  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setUser(null); // Clear user state
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };




  // For Admin
  // const [userAdmin, setUserAdmin] = useState(JSON.parse(localStorage.getItem('myAdmin')));

  // useEffect(()=> {

  //   const unsubscribe = onAuthStateChanged(auth, async(user)=> {

  //     if(user) {
  //       try {
  //         const userDoc = await getDoc(doc(db, 'admin', user.uid));
  //         if(userDoc.exists()) {
  //           setUserAdmin({...userAdmin, role: userDoc.data().role});

  //           localStorage.setItem('myAdmin', JSON.stringify(user));

  //         } else {
  //           setUserAdmin(null);
  //         }

  //       } catch(error) {
  //         console.log(error);
  //         setUserAdmin(null);
  //       }

  //     } else {
  //       setUserAdmin(null);
  //     }

  //   });

  //   return ()=> unsubscribe();

  // }, []);


  const [userAdmin, setUserAdmin] = useState(() => {
    const storedAdmin = localStorage.getItem('myAdmin');
    return storedAdmin ? JSON.parse(storedAdmin) : null;
  });
  
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, 'admin', user.uid));
          if (userDoc.exists()) {
            const userData = { ...user, role: userDoc.data().role };
  
            // تخزين بيانات المستخدم مع الدور في localStorage
            localStorage.setItem('myAdmin', JSON.stringify(userData));
            setUserAdmin(userData);
          } else {
            setUserAdmin(null);
            localStorage.removeItem('myAdmin'); // إزالة من localStorage إذا لم يوجد المستخدم
          }
        } catch (error) {
          console.error(error);
          setUserAdmin(null);
          localStorage.removeItem('myAdmin'); // إزالة من localStorage في حالة الخطأ
        }
      } else {
        setUserAdmin(null);
        localStorage.removeItem('myAdmin'); // إزالة من localStorage إذا كان المستخدم غير موجود
      }
    });
  
    return () => unsubscribe();
  }, []);
  


  const handelSignOutForAdmin = async()=> {
    try {
      await signOut(auth);
      setUserAdmin(null); // Clear user state

    } catch(error) {
      console.log(error);
    }
  };

  return (
    <>
      {/* <div style={{display: 'none'}}>
          <Navbar isLogin={isLogin} />
      </div> */}
      
      <ScrollToTop />

      <Routes>
        <Route path='/' element={<Home />}></Route>
        
        <Route path='/login' element={<Login />}></Route>
        <Route path='/SignUp' element={<SignUp />}></Route>
        <Route path="/ResetPassword" element={<ResetPassword />}></Route>

        <Route path="/Search-Result-Hotel" element={<SearchResultHotel />}></Route>
        <Route path="/Room-Avaliable" element={<RoomAvaliable />}></Route>
        <Route path="/Personal-Information" element={<PersonalInformation />}></Route>

        <Route path="/Search-Result-Flight" element={<SearchResultFlight />}></Route>
        <Route path="/Pakage-Flight" element={<PakageFlight />}></Route>
        <Route path="/Information-For-Flight" element={<InformationForFlight />}></Route>

        <Route path="/Who-We-Are" element={<WhoWeAre />}></Route>
        <Route path="/Privicy" element={<Privicy />}></Route>
        <Route path="/Rules" element={<Roules />}></Route>
        <Route path="/Offers" element={<Offers />}></Route>
        <Route path="/Offers-Details/:Id" element={<OffersDetails />}></Route>

        <Route path="/My-Admin-Dashboard" element={<HomeDashboard />}></Route>
        <Route path="/Master-Dashboard" element={userAdmin?.role === 'admin' ? <MasterDashboard onSignOutForAdmin={handelSignOutForAdmin} /> : <Navigate to='/My-Admin-Dashboard' />}></Route>
        <Route path="/Who-We-Are-Dashboard" element={userAdmin?.role === 'admin' ? <WhoWeAreDashoard onSignOutForAdmin={handelSignOutForAdmin} /> : <Navigate to='/My-Admin-Dashboard' />}></Route>
        <Route path="/Roules-Dashboard" element={userAdmin?.role === 'admin' ? <RoulesDashboard onSignOutForAdmin={handelSignOutForAdmin} /> : <Navigate to='/My-Admin-Dashboard' />}></Route>
        <Route path="/Privicy-Dashboard" element={userAdmin?.role === 'admin' ? <PrivicyDashboard onSignOutForAdmin={handelSignOutForAdmin} /> : <Navigate to='/My-Admin-Dashboard' />}></Route>
        <Route path="/Offers-Dashboard" element={userAdmin?.role === 'admin' ? <OffersDashboard onSignOutForAdmin={handelSignOutForAdmin} /> : <Navigate to='/My-Admin-Dashboard' />}></Route>
        <Route path="/Discound-Dashboard" element={userAdmin?.role === 'admin' ? <DiscoundDashboard onSignOutForAdmin={handelSignOutForAdmin} /> : <Navigate to='/My-Admin-Dashboard' />}></Route>
        <Route path="/Subscribe-Dashboard" element={userAdmin?.role === 'admin' ? <SubscribeUser onSignOutForAdmin={handelSignOutForAdmin} /> : <Navigate to='/My-Admin-Dashboard' />}></Route>

        <Route path="/Loading" element={<Loading />}></Route>

        <Route path="/profile" element={user? <ProfilePage onSignOut={handleSignOut} /> : <Navigate to="/Login" />} />
      </Routes>
    </>
  );
}

export default App;
