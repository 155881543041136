import './style_extra_services.css';
import { useTranslation } from 'react-i18next';
import plane_img from '../../images/plane_services.png';
import success_img from '../../images/success.png';
import { useState } from 'react';

function ExtraServices() {
    const [closeMassage, setCloseMassage] = useState(true);
    //console.log(closeMassage);
    const [isDropMenu, setIsDropMenu] = useState(false);

    const handleWhatsAppClick = () => {
        const phoneNumber = "+9660539992111"; 
        const message = "مرحبًا، أرغب في التواصل معكم."; // الرسالة الافتراضية
        const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    
        window.open(whatsappUrl, "_blank");
      };

      const handelOpenMassage = ()=> {
        setCloseMassage(false);
      };

    const { t, i18n } = useTranslation();

    return(
        <>
            <div className='my-extra-services'>
                {/* Start Massages */}
                    <div className='success-message' style={closeMassage === true ? {display: 'none'} : {display: 'flex'}}>
                            
                            <div className='my-massage'>
                                
                                <img src={success_img} />
                                
                                <h3>
                                <i className="fa-brands fa-whatsapp"></i>
                                <br />
                                {t('"تواصل معنا عبر الواتساب"')}
                                </h3>
                                <button onClick={handleWhatsAppClick}>{t('اضغط هنا للمراسله')}</button>

                                <i className="fa-solid fa-circle-xmark" onClick={()=> {setCloseMassage(true)}}></i>
                            </div>
                        
                    </div>
                {/* End Massages */}

                <div className='container'>
                    <div className='head'>
                            <span></span>
                            <h1>{t('المزيد من الخدمات لاجلك')}</h1>
                            <i className="fa-solid fa-bell-concierge"></i>
                            <span></span>
                    </div>

                    <div className='content'>
                        <button onClick={()=> {setCloseMassage(false)}}>
                            {t('رخصة قيادة دولية')}
                            <i className="fa-solid fa-arrow-right-long"></i>
                        </button>

                        <div className='my-select' onClick={()=> {setIsDropMenu(true)}}>

                            <select style={isDropMenu === true ? {color: '#5A64A8', backgroundColor: '#F1F1F1'} : null} onChange={handelOpenMassage}>
                                    <option value="">{t('تأشيرات شنقن')}</option>
                                    <option value=''>{t('تاشيرة بريطانيا')}</option>
                                    <option value=''>{t('تاشيرة المانيا')}</option>
                                    <option value=''>{t('تاشيرة فرنسا')}</option>
                                    <option value=''>{t('تاشيرة ايطاليا')}</option>
                                    <option value=''>{t('تاشيرة النمسا')}</option>
                                    <option value=''>{t('تاشيرة اسبانيا')}</option>
                                    <option value=''>{t('تاشيرة اذربيجان')}</option>
                                    <option value=''>{t('تاشيرة روسيا')}</option>
                                    <option value=''>{t('تاشيرة مصر')}</option>
                                    <option value=''>{t('تاشيرة اثيوبيا')}</option>
                                    <option value=''>{t('تاشيرة كينيا')}</option>
                                    <option value=''>{t('تاشيرة تنزانيا')}</option>
                                    <option value=''>{t('تاشيرة فيتنام')}</option>
                                    <option value=''>{t('تاشيرة سريلانكا')}</option>
                                    <option value=''>{t('تاشيرة الهند')}</option>
                                    <option value=''>{t('تاشيرة ارمينيا')}</option>
                                    <option value=''>{t('تاشيرة اوغندا')}</option>
                            </select>
                            <i className="fa-solid fa-arrow-right-long"></i>
                        </div>

                        {/* <button>
                            {t('تأشيرات شنقن')}
                            
                            <i className="fa-solid fa-arrow-right-long"></i>
                        </button> */}
                    </div>

                    <div className='plane'>
                        <img src={plane_img} />
                    </div>
                </div>
            </div>
        </>
    );
};


export default ExtraServices;