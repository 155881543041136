import './style_last_hotel.css';
import no_data_img from '../../images/NoData.png';

function LastHotel() {
    return(
        <>
            <div className="my-last-hotel">
                <div className="container">
                    <div className='no-data'>
                        <img src={no_data_img} />
                        <h1>لا يوجد حجوزات فنادق سابقه</h1>
                    </div>
                </div>
            </div>
        </>
    );
};


export default LastHotel;