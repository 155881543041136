import './style_roules_dashboard.css';
import { useNavigate } from 'react-router-dom';
import SidebarDashboard from '../Side Bar Dashboard/SidebarDashboard';
import { db } from '../../../Firebase/firebase';
import { addDoc, collection, deleteDoc, doc, getDocs, serverTimestamp, updateDoc } from 'firebase/firestore';
import Loading from '../../Loading/Loading';
import { useEffect, useState } from 'react';


function RoulesDashboard({onSignOutForAdmin}) {
    const navigate = useNavigate();

    // To Loading Page
    const [isLoading, setIsLoading] = useState(true);

    // To Add Data
    const [myData, setMyData] = useState([]);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');

    const handelAddData = async()=> {
        try {

            const addData = await addDoc(collection(db, 'myRouls'), {
                title,
                content,
                TimeStamp: serverTimestamp(),
            });

            setMyData([...myData, {id: addData.id, title: title, content: content}])
            setTitle('');
            setContent('');

            alert('Data Is Submit Successful');
            window.location.reload();

        } catch(error) {
            console.log(error);
        }

    };  



    // To Fetch Data
    const [fetchData, setFetchData] = useState([]);
    console.log(fetchData);

    useEffect(()=> {

        const getData = async()=> {

            try {

                const getMyData = await getDocs(collection(db, 'myRouls'));
                const dataList = getMyData.docs.map((e)=> ({id: e.id, ...e.data()}));

                // ترتيب البيانات من الأقدم إلى الأحدث بناءً على Timestamp
                dataList.sort((a, b) => {
                    return a.TimeStamp.toDate() - b.TimeStamp.toDate();
                });

                setFetchData(dataList);
                setIsLoading(false);

            } catch(error) {
                console.log(error);
            }

        };

        getData();

    }, []);




    // To Edit Data
    const [editData, setEditData] = useState(null);

    const handelEdit = async()=> {
        try {

            await updateDoc(doc(db, 'myRouls', editData.id), {  // id from ==> setEditData
                title,
                content,
            });
            setEditData(null);
            setTitle('');
            setContent('');

            alert('Content Is Updated Successfuley');
            window.location.reload();

        } catch(error) {
            console.log(error);
        }
    };



    // To Delete Data
    const handelDelete = async(dataId)=> {
        try {
            const confirmMassage = window.confirm('You Will Delete This Data, Are You Sure ?');

            if(confirmMassage) {
                await deleteDoc(doc(db, 'myRouls', dataId));
                setFetchData(fetchData.filter(e => e.id !== dataId)); // To return all data without data i delete it  
            } 

           
        } catch(error) {
            console.log(error);
        }
    };

    // To LogOut
    const handelLogOut = ()=> {
        onSignOutForAdmin();
        navigate('/My-Admin-Dashboard');
    };

    // show/hide options:-
    const [myId, setMyId] = useState('');



    // To Scroll To Top Page
    const scrollToTopPage = ()=> {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };


    const roulesDashboardContent = ()=> {
        return(
            <>
                <div className="add">
                    <div className="add-title">
                        <label htmlFor="">العنوان</label>
                        <input type="text" value={title} placeholder="اكتب العنوان المناسب" onChange={(e)=> {setTitle(e.target.value)}}/>
                    </div>

                    <div className="add-title">
                        <label htmlFor="">المحتوي</label>
                        <textarea value={content} placeholder="اكتب المحتوي المناسب" onChange={(e)=> {setContent(e.target.value)}}></textarea>
                    </div>

                    <button onClick={editData ? handelEdit : handelAddData}>
                        {editData ? 'تعديل المحتوي' : 'إضافة المحتوي'}
                    </button>
                </div>

                <div className='head-content'>
                    <span></span>
                    <h2>محتوي الصفحة</h2>
                    <span></span>
                </div>

                {
                    fetchData.map((e)=> {
                        return(
                            <div className='all-content' key={e.id}>
                                <div className='my-details'>
                                    <details>
                                        <summary>{e.title}</summary>
                                        {/* <p>
                                            {e.content}
                                        </p> */}

                                    {
                                        e.content.split('\n').map((line, index) => (
                                            <p key={index} style={{ fontWeight: index % 2 === 0 ? 'normal' : 'normal' }}>
                                                {line}
                                            </p>
                                        ))
                                    }
                                    </details>

                                    {
                                        myId === e.id ? (
                                            <i onClick={()=> setMyId(e.id)} className="fa-solid fa-ellipsis" style={{backgroundColor: '#33A850'}}></i>
                                        ) : (
                                            <i onClick={()=> setMyId(e.id)} className="fa-solid fa-ellipsis"></i>
                                        )
                                    }
                                </div>

                                {
                                    myId === e.id && (
                                        <div className='option' key={e.id}>
                                            <button className='edit' onClick={()=> {
                                                scrollToTopPage();

                                                setEditData(e); // to get id and all content
                                                setTitle(e.title);
                                                setContent(e.content);
                                            }}>
                                                تعديل
                                            </button>

                                            <button className='delete' onClick={()=> handelDelete(e.id)}>
                                                حذف
                                            </button>
                                        </div> 
                                    )
                                }
                            </div>
                        )
                    })
                }
            </>
        );
    };

    if(isLoading) {
        return(
            <Loading />
        )

    } else {
        return(
            <>
                <div className='my-roules-dashboard'>
                    <div className='container'>
                        <SidebarDashboard titleName='الشروط و الأحكام' content={roulesDashboardContent()} toLogOut={handelLogOut} />
                    </div>
                </div>
            </>
        );
    }

    
};


export default RoulesDashboard;